import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  success: false,
  isLoading: false,
  message: {},
};

export const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMessage: (state, { payload: { type, text, show = true } }) => {
      state.message = { type, text, show };
    },
    setLoginFlag: (state, action) => {
      state.formFlag = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setLoading,
  setMessage,
  success,
  setLoginFlag,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
