import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import { Visibility } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {
  GetAllUser,
  GetTotalUser,
  GetUser,
  setEditUserdata,
} from "../../store/Reducers/UserReducer";
import { ToastContainer, toast } from "react-toastify";
import LoaderJson from "../../assets/loading.json";
import Lottie from "react-lottie";

// const datatableData = [
//   ["#1", "	10", "ASHIL", "9825486956", "ashilshah19@gnu.in", "Active", "Assign"],
//   ["#2", "	20", "Test123", "9638601537", "test19@gmail.com", "Active", "Assign"],
// ];

export default function TotalUsers(props) {
  const dispatch = useDispatch();
  const { allUsers } = useSelector(({ UserSlice }) => UserSlice);
  const { userData } = useSelector(({ AuthSlice }) => AuthSlice);
  const history = useHistory();

  const getEditData = (item) => {
    dispatch(setEditUserdata(item));
    history.push({
      pathname: "edituser",
      state: {
        userData: item,
      },
    });
  };

  const viewUser = (item) => {
    dispatch(setEditUserdata(item));
    history.push({
      pathname: "userview",
      state: {
        userData: item,
      },
    });
  };

  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    toggleLoading(true);
    dispatch(GetTotalUser()).then((res) => toggleLoading(false));
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title="Total Users"
        // button={
        //   typeof userData?.NoOfLicence == "number" &&
        //   userData?.NoOfLicence > getUserData?.length && (
        //     <Link to="adduser" className="addlink">
        //       <Button variant="contained" size="medium" className="newBtn">
        //         <AddIcon />
        //         Assign Licenses
        //       </Button>
        //     </Link>
        //   )
        // }
      />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="User List"
              data={allUsers}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    download: false,
                    sort: true,
                    display: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                      return <div>{tableMeta?.rowIndex + 1}</div>;
                    },
                  },
                },
                {
                  name: "Name",
                  label: "Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = allUsers[tableMeta.rowIndex];
                      const name = tname?.Name;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "PhoneNumber",
                  label: "Phone Number",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = allUsers[tableMeta.rowIndex];
                      const name = tname?.PhoneNumber;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Email",
                  label: "Email",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = allUsers[tableMeta.rowIndex];
                      const name = tname?.Email;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span style={{ wordBreak: "break-word" }}>
                            {value ?? "-"}
                          </span>
                        </>
                      );
                    },
                  },
                },

                {
                  name: "Status",
                  label: "Status",
                  options: {
                    download: false,
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span style={{ color: value ? "green" : "red" }}>
                            {value === true ? "Active" : "InActive"}
                          </span>
                          {/* <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  (console.log("checked:", status === "visible"),
                                  status === "visible")
                                }
                                sx={{ m: 1 }}
                              />
                            }
                          /> */}
                        </>
                      );
                    },
                  },
                },
                {
                  name: "uniqueId",
                  label: "Unique Id",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                      return value ?? <>-</>;
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const item = allUsers.find(
                        (x) => x._id === tableMeta.rowData[0],
                      );
                      // const item = allUsers[tableMeta.rowIndex];
                      // const itemid = getLanguageData[tableMeta.rowIndex]._id;
                      return (
                        <>
                          <div className="edit-delete-btn">
                            <EditIcon
                              className="action-icons editBtn"
                              style={{ marginRight: "10px" }}
                              onClick={() => getEditData(item)}
                            />
                            <Visibility
                              style={{ marginRight: "10px" }}
                              className="action-icons viewBtn"
                              onClick={() => viewUser(item)}
                            />
                          </div>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "createdAt",
                  label: "Date.",

                  options: {
                    sort: false,
                    display: false,
                    download: true,
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: true,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
