import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Input,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
// styles
import useStyles from "./styles";

// logo
import logo from "../../../src/images/logo-isea.png";

// context
import { LoginAPIData } from "../../store/Reducers/AuthSlice";
import { ADMIN_NAME, ORG_NAME, base_url, img_url } from "../../utils/constant";
import axios from "axios";
import { LoginSchema } from "../../utils/Schema";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function Login() {
  var classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  let isAdmin = history.location.pathname.includes("admin/login")
    ? true
    : false;

  const user = isAdmin ? ADMIN_NAME : ORG_NAME;

  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (value) => {
      setLoading(true);
      dispatch(LoginAPIData(value)).then((response) => {
        if (response?.payload?.code === 200) {
          history.push("dashboard");
        }
        setLoading(false);
      });
    },
  });

  let [data, setData] = useState({});

  const getImage = async () => {
    let res = await axios
      .post(base_url + "admin/getAllImage")
      .then((result) => {
        if (result && result.data && result.data.code !== 200) {
          // console.log(result.data.message);
        } else {
          setData(result.data.data);
          return result.data;
        }
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    getImage();
  }, []);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer} id="logo-container">
        <img
          src={data.logo ? `${img_url}${data.logo}` : logo}
          alt="logo"
          className={classes.logotypeImage}
        />
        <Typography
          className={classes.logotypeText}
          style={{ textAlign: "center" }}
        >
          {user}
        </Typography>
      </div>
      <div className={classes.formContainer} id="login-form-container">
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              {isAdmin ? "Admin" : "Organisation"} Login
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                name="Email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                onChange={formik.handleChange}
                value={formik.values.Email}
                onBlur={formik.handleBlur}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              {formik.touched.Email && formik.errors.Email ? (
                <div style={{ color: "#D71E28" }}>{formik.errors.Email}</div>
              ) : null}
              <TextField
                id="password"
                name="Password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginBottom: "10px" }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
                value={formik.values.Password}
                onBlur={formik.handleBlur}
                margin="normal"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
              />
              {formik.touched.Password && formik.errors.Password ? (
                <div style={{ color: "#D71E28" }}>{formik.errors.Password}</div>
              ) : null}
              <br />
              <br />
              <Button
                className={classes.loginBtn}
                disabled={
                  formik.values.Email.length === 0 ||
                  formik.values.Password.length === 0 ||
                  loading
                }
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                {loading ? (
                  <CircularProgress
                    color="primary"
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "blue",
                    }}
                  />
                ) : (
                  <>Login</>
                )}
              </Button>
            </form>
          </React.Fragment>
        </div>
        <Typography className={classes.copyright} id="login-footer-txt">
          © 2023{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="#"
            rel="noopener noreferrer"
            target="_blank"
          >
            {user}
          </a>
          . All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
