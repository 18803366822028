import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  Category,
  Settings,
  History,
  AccountCircle,
  Business,
  People,
  NotificationImportant,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { useHistory, withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useSelector } from "react-redux";

// let loginPath = !!pathname.includes("/admin") ? "/admin" : "/organisation";
// let role = !!pathname.includes("/admin");

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  const { role } = useSelector(({ AuthSlice }) => AuthSlice);

  let loginPath = role == "Admin" ? "/admin" : "/organisation";
  // console.log(loginPath);
  const history = useHistory();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // const { profileData } = useSelector(({ ProfileSlice }) => ProfileSlice);

  // local
  var [isPermanent, setPermanent] = useState(true);

  // const [isDashboard, toggleDashboard] = useState(false);

  // useEffect(() => {
  //   if (profileData?.IsDashboard) {
  //     toggleDashboard(true);
  //   }
  // }, [profileData]);

  // const orgStructure = isDashboard ? orgWithDashboard : orgWithoutDashboard;

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const adminStructure = [
    {
      id: 0,
      label: "Dashboard",
      link: `/admin/app/dashboard`,
      icon: <HomeIcon />,
    },
    {
      id: 1,
      label: "Master",
      link: `/admin/app/master`,
      icon: <Category />,
      children: [
        { label: "Language", link: `/admin/app/master/language` },
        { label: "Topic", link: `/admin/app/master/topic` },
        { label: "Screen", link: `/admin/app/master/screen` },
        { label: "CMS", link: `/admin/app/master/cms` },
      ],
    },

    {
      id: 5,
      label: "Organisation",
      link: `/admin/app/organisation`,
      icon: <Business />,
    },

    {
      id: 7,
      label: "History",
      link: `/admin/app/payment-history`,
      icon: <History />,
    },
    {
      id: 8,
      label: "User logs",
      link: `/admin/app/logs`,
      icon: <AccountCircle />,
    },
    {
      id: 9,
      label: "Notifications",
      link: `/admin/app/notifications`,
      icon: <NotificationImportant />,
    },
    {
      id: 10,
      label: "Settings",
      link: `/admin/app/settings`,
      icon: <Settings />,
      children: [
        { label: "Edit Profile", link: `/admin/app/settings/profile` },
        {
          label: "Change Password",
          link: `/admin/app/settings/change-password`,
        },
        // { label: "Logout", link: "/app/logout" },
      ],
    },
  ];

  const orgWithDashboard = [
    {
      id: 0,
      label: "Dashboard",
      link: `/organisation/app/dashboard`,
      icon: <HomeIcon />,
    },
    {
      id: 6,
      label: "Users",
      link: `/organisation/app/users`,
      icon: <People />,
    },
    {
      id: 9,
      label: "Settings",
      link: `/organisation/app/settings`,
      icon: <Settings />,
      children: [
        { label: "Edit Profile", link: `/organisation/app/settings/profile` },
        {
          label: "Change Password",
          link: `/organisation/app/settings/change-password`,
        },
        // { label: "Logout", link: "/app/logout" },
      ],
    },
  ];

  // const orgWithoutDashboard = [
  //   {
  //     id: 6,
  //     label: "Users",
  //     link: `users`,
  //     icon: <People />,
  //   },
  //   {
  //     id: 9,
  //     label: "Settings",
  //     link: `settings`,
  //     icon: <Settings />,
  //     children: [
  //       { label: "Edit Profile", link: `profile` },
  //       { label: "Change Password", link: `change-password` },
  //       // { label: "Logout", link: "/app/logout" },
  //     ],
  //   },
  // ];

  const paths = role == "Admin" ? adminStructure : orgWithDashboard;

  useEffect(() => {
    if (history.location.pathname == `${loginPath}/app/settings`) {
      history.push(`${loginPath}/app/settings/profile`);
    } else if (history.location.pathname == `${loginPath}/app/master`) {
      history.push(`${loginPath}/app/master/language`);
    }
  }, [history.location.pathname]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      id="sidebar-drawer"
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton
          onClick={() => toggleSidebar(layoutDispatch)}
          style={{ color: "white" }}
        >
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {paths.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
