import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { useFormik, FieldArray, Formik, Form } from "formik";
import { ScreenSchema, ScreenSchema1 } from "../../utils/Schema";
import { useDispatch, useSelector } from "react-redux";
// components
import PageTitle from "../../components/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GetLanguage } from "../../store/Reducers/LanguageReducer";
import "./styles.css";

import {
  AddScreenData,
  EditScreen,
  UploadScreenImage,
} from "../../store/Reducers/ScreenReducer";
import {
  GetCategoriesById,
  GetCategory,
  GetUniqueCategory,
} from "../../store/Reducers/CategoryReducer";
import { base_url, img_url } from "../../utils/constant";
import { isEmpty } from "../../utils/Functions";
import TextEditor from "../../components/TextEditor/TextEditor";
import Editors from "../../utils/Editor";
import { GetOrganization } from "../../store/Reducers/OrganizationReducer";
import defaultImage from "../../images/defaultTopic.png";

export default function AddScreen(props) {
  let heading = "Screen";
  let button = "Submit";
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let isEdit = history.location.pathname.includes("editscreen") ? true : false;

  const toggleCheck = (event) => {
    setChecked(event.target.checked);
  };
  let { getCategoryData, uniqueCategories } = useSelector(
    ({ CategorySlice }) => CategorySlice,
  );

  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  var { editScreenData } = useSelector(({ ScreenSlice }) => ScreenSlice);
  if (!editScreenData) {
    editScreenData = location?.state?.data;
  }

  const { getLanguageData } = useSelector(({ LanguageSlice }) => LanguageSlice);

  const [checked, setChecked] = React.useState(editScreenData?.IslastScreen);

  const [inputFields, setInputFields] = useState([
    { title: "", Description: "" },
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ title: "", Description: "" });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "title") {
      values[index].title = event.target.value;
    } else {
      values[index].Description = event.target.value;
    }

    setInputFields(values);
  };

  const [categoryName, setCategoryName] = useState("");

  const formik = useFormik({
    initialValues: {
      // languageId: {},
      title: "",
      Description: "",
      image: "",
      categoryId: "",
    },
    validationSchema: ScreenSchema,
    onSubmit: async (value) => {
      let data = new FormData();

      // data.append("languageId", value.languageId._id);
      data.append("title", value.title);
      data.append("Description", value.Description);
      data.append("image", value.mainImage);
      // data.append("Sequence", value.Sequence);
      data.append("status", checked ? 1 : 0);

      if (editScreenData) {
        dispatch(EditScreen(data)).then((response) => {
          if (response?.payload?.code === 200) {
            // history.push("screen");
            history.goBack();
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
        });
      } else {
        dispatch(AddScreenData(data)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();
            // history.push("screen");
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
        });
      }
    },
  });

  const [category, setCategory] = useState("");

  const [initialValues, setInitialValues] = useState({
    screen: [
      {
        title: "",
        Description: "",
        image: "",
        Sequence: "",
        TopicColour: "",
      },
    ],
    languageId: "",
    categoryId: "",
    maintitle: "",
    mainDescription: "",
    mainImage: "",
    video: "",
    IslastScreen: false,
    TopicColour: "",
    organisationId: "",
  });

  useEffect(() => {
    dispatch(GetLanguage());
    // dispatch(GetCategory());
    dispatch(GetOrganization());

    if (isEdit && editScreenData) {
      dispatch(
        GetUniqueCategory({
          categoryId: editScreenData.categoryId,
        }),
      );
    } else {
      dispatch(GetUniqueCategory());
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      if (editScreenData.IslastScreen) {
        setInitialValues(() => ({
          _id: editScreenData?._id,
          languageId: editScreenData?.languageId,
          organisationId: editScreenData?.organisationId,
          categoryId: editScreenData?.categoryId,
          screen: editScreenData?.IsScreen,
          IslastScreen: editScreenData?.IslastScreen,
          TopicColour: editScreenData?.TopicColour,
          // Sequence: editScreenData?.Sequence,

          maintitle: editScreenData?.title,
          mainDescription: editScreenData?.Description,
          mainImage: editScreenData?.image,
          video: editScreenData?.video,
        }));
      } else {
        setInitialValues(() => ({
          _id: editScreenData?._id,
          languageId: editScreenData?.languageId,
          organisationId: editScreenData?.organisationId,
          categoryId: editScreenData?.categoryId,
          screen: editScreenData?.IsScreen,
          IslastScreen: editScreenData?.IslastScreen,
          TopicColour: editScreenData?.TopicColour,
          // Sequence: editScreenData?.Sequence,
        }));
      }
    }
  }, [dispatch, editScreenData]);

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const [loading, setLoading] = useState(false);
  // const [language, setLanguage] = useState("");

  const fetchCategories = (orgId) => {
    dispatch(
      GetUniqueCategory({
        organisationId: orgId,
      }),
    );
  };

  return (
    <>
      <PageTitle title={heading} />
      <Grid container spacing={4}>
        {/* <TextEditor /> */}
        <Grid item xs={12}>
          {/* <PageTitle title={heading} /> */}
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">
                    {isEdit ? "Edit" : "Create"} Screen
                  </h3>
                </div>
                <br />

                <Formik
                  initialValues={initialValues}
                  validationSchema={checked ? ScreenSchema1 : ScreenSchema}
                  onSubmit={async (values) => {
                    setLoading(true);
                    let data = new FormData();
                    data.append("organisationId", values.organisationId);
                    data.append("categoryId", values.categoryId);
                    // if (values.IslastScreen) {
                    data.append("title", values.maintitle ?? "");
                    data.append("Description", values.mainDescription ?? "");
                    data.append("image", values.mainImage ?? "");
                    data.append("video", values.video ?? "");
                    // }
                    data.append("status", values.IslastScreen);
                    data.append("IslastScreen", values.IslastScreen);
                    data.append("IsScreen", JSON.stringify(values?.screen));

                    if (isEdit) {
                      data.append("_id", values._id);
                      dispatch(EditScreen(data)).then((response) => {
                        if (response?.payload?.code === 200) {
                          // history.push("screen");
                          history.goBack();
                          toast.success(response?.payload?.message);
                        } else {
                          toast.error(response?.payload?.message);
                        }
                        setLoading(false);
                      });
                    } else {
                      dispatch(AddScreenData(data)).then((response) => {
                        if (response?.payload?.code === 200) {
                          history.goBack();
                          // history.push("screen");
                          toast.success(response?.payload?.message);
                        } else {
                          toast.error(response?.payload?.message);
                        }
                        setLoading(false);
                      });
                    }
                  }}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    handleChange,
                    setFieldValue,
                    touched,
                    errors,
                    handleBlur,
                  }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item md={3} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Organisation
                            </InputLabel>
                            <FormControl variant="outlined">
                              <Select
                                onBlur={formik.handleBlur}
                                id="categoryId"
                                name="organisationId"
                                placeholder="Organisation"
                                value={values.organisationId}
                                disabled={isEdit ? true : false}
                                onChange={(e) => {
                                  // if (e.target.value == "") {
                                  setFieldValue("categoryId", "");
                                  // }

                                  fetchCategories(e.target.value);
                                  handleChange(e);
                                }}
                                displayEmpty
                              >
                                <MenuItem value="">Select From Below</MenuItem>
                                {getorganization &&
                                  getorganization?.map((value) => {
                                    return (
                                      <MenuItem value={value?._id}>
                                        {value.Name.split(" ")
                                          .map(
                                            (s) =>
                                              s.charAt(0).toUpperCase() +
                                              s.substring(1),
                                          )
                                          .join(" ")}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                          {touched?.organisationId && errors?.organisationId ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {errors?.organisationId}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Topic
                            </InputLabel>
                            <FormControl variant="outlined">
                              <Select
                                onBlur={formik.handleBlur}
                                id="categoryId"
                                name="categoryId"
                                placeholder="Category"
                                value={values.categoryId}
                                disabled={
                                  isEdit || !values.organisationId
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                displayEmpty
                              >
                                <MenuItem value="">Select From Below</MenuItem>
                                {uniqueCategories &&
                                  uniqueCategories?.map((value) => {
                                    return (
                                      <MenuItem value={value?._id}>
                                        {value?.categoryName
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (s) =>
                                              s.charAt(0).toUpperCase() +
                                              s.substring(1),
                                          )
                                          .join(" ") +
                                          ` - (${value?.languageName})`}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                          {touched?.categoryId && errors?.categoryId ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {errors?.categoryId}
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <br />

                      <FieldArray name="screen">
                        {({ insert, remove, push }) => (
                          <div className="fieldArray-set">
                            {values?.screen?.length < 2 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  // padding: "0px 0px 30px 0px",
                                }}
                              >
                                <button
                                  className="btn btn-link add-btn"
                                  type="button"
                                  onClick={() =>
                                    push({
                                      title: "",
                                      Description: "",
                                      Sequence: "",
                                      image: "",
                                    })
                                  }
                                >
                                  +
                                </button>
                              </div>
                            )}
                            {values?.screen?.length > 0 &&
                              values?.screen?.map((data, index) => (
                                <Grid
                                  container
                                  key={index}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item md={11} xs={11}>
                                    <FormGroup style={{ marginTop: "20px" }}>
                                      <InputLabel className="card-input-label">
                                        Title
                                      </InputLabel>
                                      <div className="title-editor">
                                        <div style={{ width: "100%" }}>
                                          <Editors
                                            data={data?.title}
                                            changeHandler={(data) => {
                                              setFieldValue(
                                                `screen.${index}.title`,
                                                data,
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* {errors?.screen?.length > 0 &&
                                      touched?.screen?.length > 0 &&
                                      touched?.screen[index]?.title &&
                                      errors?.screen[index]?.title ? (
                                        <div
                                          style={{ color: "#D71E28" }}
                                          className="validation-error"
                                        >
                                          {errors?.screen[index]?.title}
                                        </div>
                                      ) : null} */}
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: "20px" }}>
                                      <InputLabel className="card-input-label">
                                        Description
                                      </InputLabel>

                                      <div style={{ width: "100%" }}>
                                        <Editors
                                          data={data?.Description}
                                          changeHandler={(data) => {
                                            setFieldValue(
                                              `screen.${index}.Description`,
                                              data,
                                            );
                                          }}
                                        />
                                      </div>
                                    </FormGroup>
                                    {errors?.screen?.length > 0 &&
                                    touched?.screen?.length > 0 &&
                                    touched?.screen[index]?.Description &&
                                    errors?.screen[index]?.Description ? (
                                      <div
                                        style={{ color: "#D71E28" }}
                                        className="validation-error"
                                      >
                                        {errors?.screen[index]?.Description}
                                      </div>
                                    ) : null}

                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ marginTop: "20px" }}
                                    >
                                      <Grid item md={3} xs={12}>
                                        <FormGroup>
                                          <InputLabel className="card-input-label">
                                            Sequence
                                          </InputLabel>
                                          <FormControl variant="outlined">
                                            <Select
                                              onBlur={formik.handleBlur}
                                              id="categoryId"
                                              name={`screen.${index}.Sequence`}
                                              placeholder="Category"
                                              onChange={handleChange}
                                              value={data?.Sequence}
                                              displayEmpty
                                            >
                                              <MenuItem value="">
                                                Select From Below
                                              </MenuItem>
                                              {values.screen.map((data1, i) => (
                                                <MenuItem value={i + 1}>
                                                  {i + 1}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </FormGroup>
                                        {errors?.screen?.length > 0 &&
                                        touched?.screen?.length > 0 &&
                                        touched?.screen[index]?.Sequence &&
                                        errors?.screen[index]?.Sequence ? (
                                          <div
                                            style={{ color: "#D71E28" }}
                                            className="validation-error"
                                          >
                                            {errors?.screen[index]?.Sequence}
                                          </div>
                                        ) : null}
                                      </Grid>

                                      <Grid item md={3} xs={12}>
                                        <div className="inputField">
                                          <InputLabel className="card-input-label">
                                            Color
                                          </InputLabel>
                                          <TextField
                                            onBlur={handleBlur}
                                            id="TopicColour"
                                            name={`screen.${index}.TopicColour`}
                                            type="color"
                                            variant="outlined"
                                            className="input-div"
                                            onChange={handleChange}
                                            value={data.TopicColour}
                                            placeholder="Color"
                                          />
                                        </div>
                                        {errors?.screen?.length > 0 &&
                                        touched?.screen?.length > 0 &&
                                        touched?.screen[index]?.TopicColour &&
                                        errors?.screen[index]?.TopicColour ? (
                                          <div
                                            style={{ color: "#D71E28" }}
                                            className="validation-error"
                                          >
                                            {errors?.screen[index]?.TopicColour}
                                          </div>
                                        ) : null}
                                        <br />
                                      </Grid>
                                      <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className="inputField"
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item md={12} xs={12}>
                                            <InputLabel className="card-input-label">
                                              Screen Image{" "}
                                              <span className="helperText ">
                                                *Note: Required image size is
                                                400px X 220px.
                                              </span>
                                            </InputLabel>
                                            <input
                                              type="file"
                                              name="image"
                                              id="image"
                                              accept="image/png, image/jpeg, image/jpg, image/webp"
                                              className="hide_file"
                                              onChange={(event) => {
                                                let data = new FormData();
                                                data.append(
                                                  "IsImage",
                                                  event.target.files[0],
                                                );

                                                var requestOptions = {
                                                  method: "POST",
                                                  body: data,
                                                  redirect: "follow",
                                                };

                                                fetch(
                                                  `${base_url}screen/screenImage`,
                                                  requestOptions,
                                                )
                                                  .then((response) =>
                                                    response.json(),
                                                  )
                                                  .then((result) => {
                                                    setFieldValue(
                                                      `screen.${index}.image`,
                                                      result.data.IsImage,
                                                    );
                                                  })
                                                  .catch((error) =>
                                                    console.log("error", error),
                                                  );
                                              }}
                                            />
                                            {errors?.screen?.length > 0 &&
                                            touched?.screen?.length > 0 &&
                                            touched?.screen[index]?.image &&
                                            errors?.screen[index]?.image ? (
                                              <div
                                                style={{ color: "#D71E28" }}
                                                className="validation-error"
                                              >
                                                {errors?.screen[index]?.image}
                                              </div>
                                            ) : null}
                                          </Grid>
                                          <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            hidden={isEmpty(data.image)}
                                          >
                                            <img
                                              src={
                                                data.image
                                                  ? `${img_url}${data.image}`
                                                  : defaultImage
                                              }
                                              height={150}
                                              width={200}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    md={1}
                                    xs={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    {index !== 0 && (
                                      <div className="fieldArrayBtns">
                                        <button
                                          className="btn btn-link remove-btn"
                                          type="button"
                                          disabled={index === 0}
                                          onClick={() => remove(index)}
                                        >
                                          -
                                        </button>
                                        {index ==
                                          values?.screen?.length - 1 && (
                                          <button
                                            className="btn btn-link add-btn"
                                            type="button"
                                            onClick={() =>
                                              push({
                                                title: "",
                                                Description: "",
                                                Sequence: "",
                                                image: "",
                                              })
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </Grid>
                                  {values.screen.length !== index + 1 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                  )}
                                </Grid>
                              ))}
                          </div>
                        )}
                      </FieldArray>

                      <div className="lastscreen" style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <FormControlLabel
                            style={{ width: "max-content" }}
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) => {
                                  setFieldValue(
                                    "IslastScreen",
                                    e.target.checked,
                                  );
                                  setChecked(e.target.checked);
                                }}
                                name="IslastScreen"
                                checked={values.IslastScreen}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Last Screen"
                          />
                        </FormGroup>

                        <br />
                        {values.IslastScreen && (
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <FormGroup>
                                <InputLabel className="card-input-label">
                                  Title
                                </InputLabel>

                                <div className="title-editor">
                                  <div style={{ width: "100%" }}>
                                    <Editors
                                      data={values?.maintitle}
                                      changeHandler={(data) => {
                                        setFieldValue(`maintitle`, data);
                                      }}
                                      // handleBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                              {touched?.maintitle || errors?.maintitle ? (
                                <div
                                  style={{ color: "#D71E28" }}
                                  className="validation-error"
                                >
                                  {errors?.maintitle}
                                </div>
                              ) : null}
                              <br />
                              <FormGroup>
                                <InputLabel className="card-input-label">
                                  Description
                                </InputLabel>

                                <div style={{ width: "100%" }}>
                                  <Editors
                                    data={values?.mainDescription}
                                    changeHandler={(data) => {
                                      setFieldValue("mainDescription", data);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                              {touched?.mainDescription ||
                              errors?.mainDescription ? (
                                <div
                                  style={{ color: "#D71E28" }}
                                  className="validation-error"
                                >
                                  {errors?.mainDescription}
                                </div>
                              ) : null}
                              <br />
                              <div style={{ marginTop: "14px" }}>
                                <InputLabel className="card-input-label">
                                  Last Screen Video Link (Youtube)
                                </InputLabel>
                                <TextField
                                  onBlur={handleBlur}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  type="text"
                                  // label="Title"
                                  id="video"
                                  name="video"
                                  value={values?.video}
                                  onChange={handleChange}
                                />
                              </div>
                              {/* {touched?.video || errors?.video ? (
                                <div
                                  style={{ color: "#D71E28" }}
                                  className="validation-error"
                                >
                                  {errors?.video}
                                </div>
                              ) : null} */}
                            </Grid>
                            <Grid item md={7} xs={12}>
                              <div className="inputField">
                                <InputLabel className="card-input-label">
                                  Last Screen Image{" "}
                                  <span className="helperText ">
                                    *Note: Required image size is 400px X 220px.
                                  </span>
                                </InputLabel>
                                <input
                                  type="file"
                                  name="mainImage"
                                  id="mainImage"
                                  accept="image/*"
                                  className="hide_file"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "mainImage",
                                      event.target.files[0],
                                    );
                                    setSelectedFile(event.target.files[0]);
                                  }}
                                />
                                {touched?.mainImage || errors?.mainImage ? (
                                  <div
                                    style={{ color: "#D71E28" }}
                                    className="validation-error"
                                  >
                                    {errors?.mainImage}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{ margin: "10px 0px" }}
                                hidden={
                                  isEmpty(values.mainImage)
                                    ? true
                                    : typeof values.mainImage == "string" &&
                                      false
                                }
                              >
                                <img
                                  src={
                                    values.mainImage &&
                                    typeof values.mainImage == "string"
                                      ? img_url + values.mainImage
                                      : preview
                                      ? preview
                                      : defaultImage
                                  }
                                  height={150}
                                  width={200}
                                />
                              </div>
                              <br />
                              <br />
                            </Grid>
                          </Grid>
                        )}
                      </div>
                      <br />
                      <div container>
                        {Object.keys(errors).length > 0 && (
                          <div
                            style={{ color: "#D71E28" }}
                            className="validation-error"
                          >
                            * Please Fill All Required Fields.
                          </div>
                        )}
                        <div className="card-footer">
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>

                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  color="primary"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "blue",
                                  }}
                                />
                              ) : (
                                button
                              )}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Box>
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
