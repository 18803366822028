import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetAllAssign } from "../../store/Reducers/OrganizationReducer";
import moment from "moment";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";

export default function HistoryData() {
  const dispatch = useDispatch();

  const { getAllAssign } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );
  const [loading, toggleLoading] = useState(true);
  useEffect(() => {
    toggleLoading(true);
    dispatch(GetAllAssign()).then((res) => toggleLoading(false));
  }, [dispatch]);

  let newData = [];

  getAllAssign?.map((item, index) => {
    newData?.push({ SNo: index + 1, ...item });
  });

  return (
    <>
      <PageTitle title="Licenses Assigned Histories" />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Licenses Assigned Histories"
              data={newData}
              columns={[
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    sort: false,
                  },
                },
                {
                  name: "Name",
                  label: "Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getAllAssign[tableMeta.rowIndex];
                      const name = tname?.Name;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Email",
                  label: "Email",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getAllAssign[tableMeta.rowIndex];
                      const name = tname?.Email;

                      return (
                        <>
                          <span style={{ wordBreak: "break-word" }}>
                            {value}
                          </span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Price",
                  label: "Amount",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getAllAssign[tableMeta.rowIndex];
                      const name = tname?.Price;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },

                {
                  name: "NoOfLicence",
                  label: "No.of Licenses",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getAllAssign[tableMeta.rowIndex];
                      const name = tname?.NoOfLicence;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "createdAt",
                  label: "Assigned Dates",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span>
                            {moment(value).format("YYYY-MM-DD HH:MM:SS")}
                          </span>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
