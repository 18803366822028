import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

// components
import PageTitle from "../../components/PageTitle";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  GetScreen,
  DeleteScreen,
  setEditScreendata,
  GetScreenByOrgLang,
} from "../../store/Reducers/ScreenReducer";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import { isEmpty } from "../../utils/Functions";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";
import {
  GetOrganization,
  setSelectedOrg,
} from "../../store/Reducers/OrganizationReducer";
import {
  GetLangByOrg,
  GetLanguage,
  setSelectedLanguage,
} from "../../store/Reducers/LanguageReducer";
import {
  GetCatByOrgLang,
  GetCategory,
} from "../../store/Reducers/CategoryReducer";

export default function Screen(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getScreenData } = useSelector(({ ScreenSlice }) => ScreenSlice);

  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );
  const { selectedLanguage, getLanguageData } = useSelector(
    ({ LanguageSlice }) => LanguageSlice,
  );

  const { getCategoryData } = useSelector(({ CategorySlice }) => CategorySlice);

  const history = useHistory();

  const getEditData = (item) => {
    history.push({
      pathname: "screen/editscreen",
      state: {
        data: item,
      },
    });
    dispatch(setEditScreendata(item));
  };
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    dispatch(GetOrganization());
    dispatch(GetLanguage());
    dispatch(GetCategory());

    return () => {
      dispatch(setSelectedLanguage("All"));
      dispatch(setSelectedOrg("All"));
    };
  }, []);

  const TableAction = ({ tableMeta }) => {
    const item = getScreenData.find((x) => x._id === tableMeta.rowData[0]);
    const itemid = tableMeta.rowData[0];

    // const item = getScreenData[tableMeta.rowIndex];
    // const itemid = getScreenData[tableMeta.rowIndex]._id;

    const [dialog, toggleDialog] = useState(false);

    const openDialog = () => {
      toggleDialog(true);
    };

    const closeDialog = () => {
      toggleDialog(false);
    };

    const handleDelete = (id) => {
      dispatch(DeleteScreen(id)).then((res) => {
        if (res.payload.code === 200) {
          setOrgLang({
            organisationId: "All",
            languageId: "All",
          });
          dispatch(GetScreen());
          toast.error(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      });

      closeDialog();
    };

    return (
      <>
        <div className="edit-delete-btn">
          <EditIcon
            className="action-icons editBtn"
            style={{ marginRight: "10px" }}
            onClick={() => getEditData(item)}
          />
          <DeleteIcon className="action-icons deleteBtn" onClick={openDialog} />

          <DeleteDialog
            open={dialog}
            handleClose={closeDialog}
            yesClick={() => handleDelete(itemid)}
            text={"Are you sure you want to delete this screen?"}
          />
        </div>
      </>
    );
  };

  const [orgLang, setOrgLang] = useState({
    organisationId: "All",
    languageId: "All",
    categoryId: "All",
  });

  const changeLanguage = (e) => {
    let { name, value } = e.target;
    // dispatch(setSelectedLanguage(value));
    setOrgLang((prev) => ({
      ...prev,
      [name]: value,
      categoryId: "All",
    }));

    let finalObj = {};
    if (orgLang.organisationId !== "All") {
      finalObj.organisationId = orgLang.organisationId;
    }
    if (value !== "All") {
      finalObj.languageId = value;
    }

    dispatch(GetCatByOrgLang(finalObj));
  };

  const changeOrg = (e) => {
    let { value, name } = e.target;
    // dispatch(setSelectedOrg(value));
    setOrgLang((prev) => ({
      ...prev,
      [name]: value,
      languageId: "All",
      categoryId: "All",
    }));

    if (value == "All") {
      //   setOrgLang((prev) => ({
      //     ...prev,
      //     languageId: "All",
      //   }));
      // dispatch(setSelectedLanguage("All"));
      dispatch(GetLanguage());
    } else {
      dispatch(
        GetLangByOrg({
          organisationId: value,
        }),
      );
    }
  };

  const changeCategory = (e) => {
    let { name, value } = e.target;
    setOrgLang((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchScreen = () => {
    toggleLoading(true);
    let finalObj = {};

    // if (selectedOrg !== "All") {
    //   finalObj.organisationId = selectedOrg;
    // }
    // if (selectedLanguage !== "All") {
    //   finalObj.languageId = selectedLanguage;
    // }

    if (orgLang?.organisationId !== "All") {
      finalObj.organisationId = orgLang.organisationId;
    }

    if (orgLang?.languageId !== "All") {
      finalObj.languageId = orgLang.languageId;
    }

    if (orgLang?.categoryId !== "All") {
      finalObj.categoryId = orgLang.categoryId;
    }

    dispatch(GetScreenByOrgLang(finalObj)).then((res) => toggleLoading(false));
  };

  useEffect(() => {
    fetchScreen();
  }, [orgLang]);

  return (
    <>
      <PageTitle
        title="Screen"
        button={
          <div className="manage-dropdown">
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="organisationId"
                    // value={selectedOrg}
                    value={orgLang.organisationId}
                    onChange={changeOrg}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <MenuItem value={"All"}>Select Organisation</MenuItem>
                    {getorganization.map((data) => (
                      <MenuItem value={data._id}>{data?.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="languageId"
                    // value={selectedLanguage}
                    value={orgLang.languageId}
                    onChange={changeLanguage}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <MenuItem value={"All"}>Select Language</MenuItem>
                    {getLanguageData.map((data) => (
                      <MenuItem value={data._id}>{data?.languageName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="categoryId"
                    // value={selectedLanguage}
                    value={orgLang.categoryId}
                    onChange={changeCategory}
                    placeholder="Status"
                    disabled={loading}
                  >
                    <MenuItem value={"All"}>Select Topic</MenuItem>
                    {getCategoryData.map((data) => (
                      <MenuItem value={data._id}>{data?.categoryName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <Link to="screen/addscreen" className="addlink">
              <Button variant="contained" size="medium" className="newBtn">
                <AddIcon />
                New
              </Button>
            </Link>
          </div>
        }
      />
      <Grid container spacing={4} className="content-grid screen-table">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Screen List"
              data={getScreenData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",
                  options: {
                    sort: true,
                    display: false,
                    download: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },

                {
                  name: "categoryName",
                  label: "Topic Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getScreenData[tableMeta.rowIndex];
                      const name = tname?.categoryName;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },

                {
                  name: "title",
                  label: "Title",
                  options: {
                    filter: true,
                    sort: false,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const item = getScreenData.find(
                        (x) => x._id === tableMeta.rowData[0],
                      );

                      const tname = item.IsScreen;
                      const name = isEmpty(tname[0]?.title)
                        ? "<div><strong>-</strong></div>"
                        : tname[0]?.title;
                      return (
                        <span>
                          <div dangerouslySetInnerHTML={{ __html: name }} />
                        </span>
                      );
                    },
                  },
                },

                {
                  name: "languageName",
                  label: "Language",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                  },
                },
                {
                  name: "Name",
                  label: "Organisation",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                  },
                },
                // {
                //   name: "title",
                //   label: "Screen Title",
                //   options: {
                //     filter: true,
                //     sort: true,
                //     sortFilterList: true,
                //     customBodyRender: (value, tableMeta, update) => {
                //       const tname = getScreenData[tableMeta.rowIndex];
                //       const name = tname?.title;
                //       // ?.toLowerCase()
                //       // .split(" ")
                //       // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                //       // .join(" ");
                //       return (
                //         <>
                //           <span>{name}</span>
                //         </>
                //       );
                //     },
                //   },
                // },
                // {
                //   name: "Sequence",
                //   label: "Sequence",
                // },
                {
                  name: "Action",

                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                      <TableAction tableMeta={tableMeta} />
                    ),
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
