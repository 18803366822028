import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AddCmsAPI,
  GetCmsAPI,
  EditCmsAPI,
  DeleteCmsAPI,
  GetCMSbyOrgAPI,
} from "../../utils/Services";

const initialState = {
  getCmsData: [],
  editCmsData: "",
};

export const AddCmsData = createAsyncThunk(
  "AddCmsData",
  async (values, { dispatch }) => {
    try {
      const result = await AddCmsAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetCms = createAsyncThunk(
  "GetCms",
  async (values, { dispatch }) => {
    try {
      const result = await GetCmsAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditCms = createAsyncThunk(
  "EditCms",
  async (values, { dispatch }) => {
    try {
      const result = await EditCmsAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteCms = createAsyncThunk(
  "DeleteCms",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteCmsAPI({ _id: values });
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      // if (!result?.hasError) {
      //   return result;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      return error;
    }
  },
);

export const GetCMSbyOrg = createAsyncThunk(
  "GetCMSbyOrg",
  async (values, { dispatch }) => {
    try {
      const result = await GetCMSbyOrgAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const CmsSlice = createSlice({
  name: "CmsSlice",
  initialState,
  reducers: {
    setEditCmsdata(state, action) {
      state.editCmsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCms.fulfilled, (state, action) => {
      state.getCmsData = action?.payload?.data;
    });

    builder.addCase(GetCMSbyOrg.fulfilled, (state, action) => {
      state.getCmsData = action?.payload?.data;
    });
  },
});

export const { setEditCmsdata } = CmsSlice.actions;

export default CmsSlice.reducer;
