import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const Editors = ({ data, changeHandler }) => {
  return (
    <div className="Editor">
      <CKEditor
        style={{ width: "max-content" }}
        editor={Editor}
        data={data}
        onReady={(editor) => {
          // console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          changeHandler(data);
        }}
        onBlur={(event, editor) => {
          // console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          // console.log("Focus.", editor);
        }}
        // config={{
        //   plugins: [WordCount],
        //   wordCount: {
        //     onUpdate: (stats) => {
        //       console.log(
        //         `Characters: ${stats.characters}\nWords: ${stats.words}`,
        //       );
        //     },
        //   },
        // }}
      />
    </div>
  );
};
export default Editors;
