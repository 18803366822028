// import * as React from "react";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
// import "./styles.css";

// const shortcutsItems = [
//   {
//     label: "This Week",
//     getValue: () => {
//       const today = dayjs();
//       return [today.startOf("week"), today.endOf("week")];
//     },
//   },
//   {
//     label: "Last Week",
//     getValue: () => {
//       const today = dayjs();
//       const prevWeek = today.subtract(7, "day");
//       return [prevWeek.startOf("week"), prevWeek.endOf("week")];
//     },
//   },
//   {
//     label: "Last 7 Days",
//     getValue: () => {
//       const today = dayjs();
//       return [today.subtract(7, "day"), today];
//     },
//   },
//   {
//     label: "Current Month",
//     getValue: () => {
//       const today = dayjs();
//       return [today.startOf("month"), today.endOf("month")];
//     },
//   },
//   {
//     label: "Next Month",
//     getValue: () => {
//       const today = dayjs();
//       const startOfNextMonth = today.endOf("month").add(1, "day");
//       return [startOfNextMonth, startOfNextMonth.endOf("month")];
//     },
//   },
//   { label: "Reset", getValue: () => [null, null] },
// ];

// export default function DateRangePicker() {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <StaticDateRangePicker
//         slotProps={{
//           shortcuts: {
//             items: shortcutsItems,
//           },
//           actionBar: { actions: [] },
//         }}
//         calendars={2}
//       />
//     </LocalizationProvider>
//   );
// }

// import * as React from "react";
// import dayjs from "dayjs";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

// export default function DateRangePickerCustom() {
//   const [value, setValue] = React.useState([
//     dayjs("2022-04-17"),
//     dayjs("2022-04-21"),
//   ]);

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
//         <DemoItem label="Pick Date Range" component="DateRangePicker">
//           <DateRangePicker
//             value={value}
//             onChange={(newValue) => setValue(newValue)}
//           />
//         </DemoItem>
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }

import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

const DateRangePickerComponent = () => {
  const [range, setRange] = useState({});
  const selectionRange = {
    startDate: range?.startDate,
    endDate: range?.endDate,
    key: "selection",
  };

  const handleChangeData = (ranges) => {
    setRange((prev) => ({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }));
  };
  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={handleChangeData}
      style={{ scale: 0.9 }}
    />
  );
};

export default DateRangePickerComponent;
