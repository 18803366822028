import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AddUserAPI,
  ManualUserAPI,
  EditUserAPI,
  GetUserAPI,
  EditUserManualAPI,
  GetAllUserAPI,
  GetActiveUsersAPI,
  GetTotalUserAPI,
} from "../../utils/Services";

const initialState = {
  getUserData: [],
  editUserData: "",
  allUsers: [],
  activeUsers: [],
};

export const AddUserData = createAsyncThunk(
  "AddUserData",
  async (values, { dispatch }) => {
    try {
      const result = await AddUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetUser = createAsyncThunk(
  "GetUser",
  async (values, { dispatch }) => {
    try {
      const result = await GetUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetAllUser = createAsyncThunk(
  "GetAllUser",
  async (values, { dispatch }) => {
    try {
      const result = await GetAllUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetTotalUser = createAsyncThunk(
  "GetTotalUser",
  async (values, { dispatch }) => {
    try {
      const result = await GetTotalUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else if (result.status == 404) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditUser = createAsyncThunk(
  "EditUser",
  async (values, { dispatch }) => {
    try {
      const result = await EditUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else if (result.status == 404) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditManualUser = createAsyncThunk(
  "EditManualUser",
  async (values, { dispatch }) => {
    try {
      const result = await EditUserManualAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const ManualUser = createAsyncThunk(
  "ManualUser",
  async (values, { dispatch }) => {
    try {
      const result = await ManualUserAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetActiveUsers = createAsyncThunk(
  "GetActiveUsers",
  async (values, { dispatch }) => {
    try {
      const result = await GetActiveUsersAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setEditUserdata(state, action) {
      state.editUserData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetUser.fulfilled, (state, action) => {
      state.getUserData = action?.payload?.data;
    });

    builder.addCase(GetAllUser.fulfilled, (state, action) => {
      state.allUsers = action?.payload?.data;
    });

    builder.addCase(GetTotalUser.fulfilled, (state, action) => {
      state.allUsers = action?.payload?.data;
    });
    builder.addCase(GetActiveUsers.fulfilled, (state, action) => {
      state.activeUsers = action?.payload?.data;
    });
    // builder.addCase(EditCategory.fulfilled, (state, action) => {
    //   state.editCategoryData = action?.payload?.data;
    // });
  },
});
export const { setEditUserdata } = UserSlice.actions;

export default UserSlice.reducer;
