import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Login from "../pages/login";

// context
// import { useUserState } from "../context/UserContext";
import { useSelector } from "react-redux";

export default function App() {
  // global
  const location = useLocation();
  var isAuthenticated = !!useSelector(({ AuthSlice }) => AuthSlice.token);
  const { role } = useSelector(({ AuthSlice }) => AuthSlice);

  let isAdmin = location.pathname.includes("/admin")
    ? true
    : role && role == "Admin"
    ? true
    : false;

  let adminPath = isAdmin ? "/admin/app" : "/organisation/app";
  let loginPath = isAdmin ? "/admin" : "/organisation";

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={`${adminPath}/dashboard`} />}
      />
      <Route
        exact
        path={adminPath}
        render={() => <Redirect to={`${adminPath}/dashboard`} />}
      />
      <PrivateRoute path={adminPath} component={Layout} />
      <PublicRoute
        path={`${loginPath}/login`}
        component={Login}
        adminPath={adminPath}
      />

      {/* <Route path="*" component={Error} /> */}
      <Route
        path="*"
        render={() => <Redirect to={`${loginPath}/app/dashboard`} />}
      />
      <Route
        path="*"
        render={() => <Redirect to={`${loginPath}/app/dashboard`} />}
      />
    </Switch>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: `${loginPath}/login`,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: adminPath,
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
