import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { Formik } from "formik";

// components
import PageTitle from "../../components/PageTitle";

const initialValue = {
  SubCategoryName: "",
  mainCategoryId: "",
};

export default function SubCategory(props) {
  let button = "Submit";
  const [data, setData] = useState(initialValue);
  return (
    <>
      <PageTitle title="SubCategory" />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Formik initialValues={data} enableReinitialize={true}>
            {(props) => {
              return (
                <>
                  {/* <PageTitle title={heading} /> */}
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Box className="Card_style">
                        <form onSubmit={props.handleSubmit} noValidate>
                          <FormGroup>
                            <FormControl variant="outlined">
                              <InputLabel id="demo-select-smalls">
                                Select Main Category
                              </InputLabel>
                              <Select
                                // className="input-bottom-space"
                                labelId="demo-select-smalls"
                                id="mainCategoryId"
                                name="mainCategoryId"
                                value={props.values.mainCategoryId}
                                label="Select Category"
                                onChange={props.handleChange}
                                placeholder="Category"
                              >
                                <MenuItem value={0}>Category 1</MenuItem>
                                <MenuItem value={1}>Category 2</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                          <br />
                          <FormGroup>
                            <TextField
                              id="SubCategoryName"
                              name="SubCategoryName"
                              label="SubCategory Name"
                              variant="outlined"
                              value={props.values.SubCategoryName}
                              onChange={props.handleChange}
                            />
                          </FormGroup>
                          <br />
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                            >
                              {button}
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}
