import axios from "axios";
import { TOKEN } from "./Enums";
import { JSONToFormData } from "./Functions";
// import { useDispatch } from "react-redux";
import { LogoutAPI } from "../store/Reducers/AuthSlice";
import { toast } from "react-toastify";
// const USER = process.env.REACT_APP_VENDOR || process.env.REACT_APP_MANAGER;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

let token;
export async function POST(url, data) {
  token = localStorage.getItem(TOKEN);
  try {
    let config = {
      headers: {
        "x-access-token": token,
      },
    };

    return await axios
      .post(url, data, config)
      .then((result) => {
        if (result && result.data && result.data.code !== 200) {
          // eslint-disable-next-line
          return result.data;
        } else {
          return result.data;
          // console.log(result);
        }
      })
      .catch((error) => {
    
        if (error.response.data.code == 401) {
          localStorage.removeItem("ROLE");
          localStorage.removeItem("USER");
          localStorage.removeItem("token");
          toast.error(error.response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 500);
          // window.location.reload();
        }
        if (error) return error.response;
      });
  } catch (error) {
    return error;
  }
}

export async function UPLOAD(url, data, onUploadProgress) {
  try {
    let formData = [];
    if (data) {
      formData = await JSONToFormData(data);
    }

    return await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress,
      })
      .then((result) => {
        if (result && result.data && result.data.code !== 200) {
          // eslint-disable-next-line
          throw { hasError: true, message: result?.data?.message };
        } else {
          return result.data;
        }
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
}
export async function _POST(url, data) {
  try {
    var config = {
      method: "post",
      url: "https://api.peerboard.com/v1/members",
      headers: {
        Authorization: "584b7a426a00684563e4d6055a1012f9",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((result) => {
        if (result && result.data && result.data.status_code !== 200) {
          // eslint-disable-next-line
          throw { hasError: true, message: result.data.message };
        } else {
          return result.data;
        }
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
}
export async function GET(url, params) {
  try {
    var config = {
      method: "get",
      url: "https://api.peerboard.com/v1/communities",
      headers: {
        Authorization: "584b7a426a00684563e4d6055a1012f9",
      },
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  } catch (error) {
    return error;
  }
}
export async function GETREPOFAV(url, params) {
  try {
    var config = {
      method: "get",
      url: "https://api.peerboard.com/v1/communities",
      headers: {
        Authorization: "584b7a426a00684563e4d6055a1012f9",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
      });
  } catch (error) {
    return error;
  }
}

// var config = {
//   method: "get",
//   url: "https://api.peerboard.com/v1/communities",
//   headers: {
//     Authorization: "584b7a426a00684563e4d6055a1012f9",
//   },
// };

// return await axios(config)
//   .then((result) => {
//     if (result && result.data && result.data.status === "error") {
//       // eslint-disable-next-line
//       throw { hasError: true, message: result?.error?.message };
//     } else {
//       return result.data;
//     }
//   })
//   .catch((error) => {
//     return error;
//   });
