import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  GetCms,
  DeleteCms,
  setEditCmsdata,
  GetCMSbyOrg,
} from "../../store/Reducers/CmsReducer";
import { toast } from "react-toastify";
import "./styles.css";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";
import { GetOrganization } from "../../store/Reducers/OrganizationReducer";

export default function Cms() {
  const dispatch = useDispatch();
  const { getCmsData } = useSelector(({ CmsSlice }) => CmsSlice);
  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  const history = useHistory();

  const getEditData = (item) => {
    history.push({
      pathname: "cms/editcms",
      state: {
        data: item,
      },
    });
    dispatch(setEditCmsdata(item));
  };
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    dispatch(GetOrganization());
  }, []);

  const TableAction = ({ tableMeta }) => {
    const item = getCmsData.find((x) => x._id === tableMeta.rowData[0]);
    const itemid = tableMeta.rowData[0];

    const [dialog, toggleDialog] = useState(false);

    const openDialog = () => {
      toggleDialog(true);
    };

    const closeDialog = () => {
      toggleDialog(false);
    };

    const handleDelete = (id) => {
      dispatch(DeleteCms(id)).then((res) => {
        if (res.payload.code === 200) {
          dispatch(GetCms());
          toast.error(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
        closeDialog();
      });
    };

    return (
      <>
        <div className="edit-delete-btn">
          <EditIcon
            className="action-icons editBtn"
            style={{ marginRight: "10px" }}
            onClick={() => getEditData(item)}
          />
          <DeleteIcon className="action-icons deleteBtn" onClick={openDialog} />

          <DeleteDialog
            open={dialog}
            handleClose={closeDialog}
            yesClick={() => handleDelete(itemid)}
            text={"Are you sure you want to delete this cms?"}
          />
        </div>
      </>
    );
  };

  const [org, setOrg] = useState("All");

  const changeOrganisation = (e) => {
    setOrg(e.target.value);
  };

  useEffect(() => {
    toggleLoading(true);
    if (org == "All") {
      dispatch(GetCms()).then((res) => toggleLoading(false));
    } else {
      dispatch(
        GetCMSbyOrg({
          organisationId: org,
        }),
      ).then((res) => toggleLoading(false));
    }
  }, [org]);

  return (
    <>
      <div className="window-class">
        <PageTitle
          title="CMS - Content Management System"
          button={
            <div className="manage-dropdown">
              <div className="language-dropdown forweb">
                <FormGroup className="main-dropdown">
                  <FormControl variant="outlined">
                    <Select
                      // onBlur={formik.handleBlur}
                      labelId="demo-select-smalls"
                      id="language"
                      name="organisationId"
                      value={org}
                      onChange={changeOrganisation}
                      placeholder="Status"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {getorganization.map((data) => (
                        <MenuItem value={data._id}>{data?.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </div>
              <Link to="cms/addcms" className="addlink">
                <Button variant="contained" size="medium" className="newBtn">
                  <AddIcon />
                  New
                </Button>
              </Link>
            </div>
          }
        />
      </div>
      <div className="mobile-class">
        <PageTitle
          title="CMS"
          button={
            <Link to="cms/addcms" className="addlink">
              <Button variant="contained" size="medium" className="newBtn">
                <AddIcon />
                New
              </Button>
            </Link>
          }
        />
      </div>

      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="CMS List"
              data={getCmsData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    sort: true,
                    display: false,
                    download: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },
                {
                  name: "title",
                  label: "Title",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      // const tname = getCmsData[tableMeta.rowIndex];
                      // const name = tname?.title;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "languageName",
                  label: "Language",
                },
                {
                  name: "Sequence",
                  label: "Sequence",
                },

                {
                  name: "Action",

                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                      <TableAction tableMeta={tableMeta} />
                    ),
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
