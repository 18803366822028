import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AdminEditProfileAPI,
  AdminProfileDataAPI,
  ChangePasswordAPI,
  GetAllHistoryAPI,
} from "../../utils/Services";

const initialState = {
  editProfileData: "",
  profileData: {},
  historyData: [],
};

export const adminEditProfile = createAsyncThunk(
  "adminEditProfile",
  async (values, { dispatch }) => {
    try {
      const result = await AdminEditProfileAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const adminProfileData = createAsyncThunk(
  "adminProfileData",
  async (values, { dispatch }) => {
    try {
      const result = await AdminProfileDataAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const ChangeUserPassword = createAsyncThunk(
  "ChangeUserPassword",
  async (values, { dispatch }) => {
    try {
      const result = await ChangePasswordAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetAllHistory = createAsyncThunk(
  "ChangeUserPassword",
  async (values, { dispatch }) => {
    try {
      const result = await GetAllHistoryAPI(values);

      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {
    setEditProfiledata(state, action) {
      state.editCategoryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminEditProfile.fulfilled, (state, action) => {
      state.editProfileData = action?.payload?.data;
    });
    builder.addCase(adminProfileData.fulfilled, (state, action) => {
      state.profileData = action?.payload?.data;
    });
    builder.addCase(GetAllHistory.fulfilled, (state, action) => {
      state.historyData = action?.payload?.data;
    });
  },
});
export const { setEditProfiledata } = ProfileSlice.actions;

export default ProfileSlice.reducer;
