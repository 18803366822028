import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { AlertEnum, ROLE, SESSION, TOKEN } from "../../utils/Enums";
import { setLoading, setMessage } from "./LayoutSlice";
import { LoginAPI, Logout, OrgLoginAPI } from "../../utils/Services";
// import { GetUserProfile } from "./ProfileSlice";

const initialState = {
  token: `${localStorage.getItem(TOKEN) || ""}`,
  role: JSON.parse(localStorage.getItem(ROLE)) || undefined,
  userData: JSON.parse(localStorage.getItem(SESSION)) || {},
  session: JSON.parse(localStorage.getItem(SESSION)) || "",
};

export const LoginAPIData = createAsyncThunk(
  "LoginAPIData",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await LoginAPI(values);
      // console.log(result.code == 200);
      if (result.code == 200) {
        toast.success(result?.message);
        dispatch(setLoading(false));
        // console.log(result);
        dispatch(setSession(result));
      } else {
        toast.error(result.message);
        throw result;
      }
    } catch (error) {
      toast.error(error);
      dispatch(setLoading(false));
      dispatch(
        setMessage({
          text: error?.message,
          type: AlertEnum.Error,
        }),
      );
      dispatch(setLoading(false));
      return error;
    }
  },
);

export const LogoutAPI = createAsyncThunk(
  "LogoutAPI",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await Logout(values);
      // console.log(result.code == 200);
      if (result.code == 200) {
        toast.success(result?.message);
        dispatch(setLoading(false));
        dispatch(removeSession());
      } else {
        toast.error(result.message);
        dispatch(removeSession());
        throw result;
      }
    } catch (error) {
      toast.error(error);
      dispatch(setLoading(false));
      dispatch(
        setMessage({
          text: error?.message,
          type: AlertEnum.Error,
        }),
      );
      dispatch(setLoading(false));
      dispatch(removeSession());
      return error;
    }
  },
);

export const OrgLoginAPIData = createAsyncThunk(
  "OrgLoginAPIData",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await LoginAPI(values);

      if (result.code == 200) {
        toast.success(result?.message);
        dispatch(setLoading(false));
        // console.log(result);
        dispatch(setSession(result));
      } else {
        toast.error(result.message);
        throw result;
      }
    } catch (error) {
      toast.error(error);
      dispatch(setLoading(false));
      dispatch(
        setMessage({
          text: error?.message,
          type: AlertEnum.Error,
        }),
      );
      dispatch(setLoading(false));
      return error;
    }
  },
);

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setSession: (state, action) => {
      // console.log(action.payload);
      // console.log(state.token);
      state.session = action?.payload;
      state.token = action?.payload?.token;
      state.userData = action?.payload?.data;
      state.role = action?.payload?.data?.Role;
      localStorage.setItem(ROLE, JSON.stringify(action?.payload?.data?.Role));
      localStorage.setItem(SESSION, JSON.stringify(action?.payload?.data));
      localStorage.setItem(TOKEN, action?.payload?.token);
    },
    removeSession: (state) => {
      localStorage.removeItem(SESSION);
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(ROLE);
      state.session = "";
      state.token = "";
      state.userData = {};
      state.role = undefined;
    },
    updateUserData: (state, action) => {
      localStorage.setItem(SESSION, JSON.stringify(action?.payload));
      state.userData = action?.payload;
    },
  },
});

export const { setSession, removeSession, updateUserData } = AuthSlice.actions;

export default AuthSlice.reducer;
