import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControlLabel,
  Tab,
  Tabs,
  Checkbox,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  ManualHeaderSchema,
  ManualSchema,
  OrganisationHeaderSchema,
  OrganisationSchema,
} from "../../utils/Schema";
import { toast } from "react-toastify";
import styles from "./styles.css";
import {
  AddOrganizationData,
  ManualMailData,
  EditOrganization,
  EditManualMail,
} from "../../store/Reducers/OrganizationReducer";
// components
import PageTitle from "../../components/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { img_url } from "../../utils/constant";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import defaultImage from "../../images/defaultTopic.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddOrganisation(props) {
  let heading = "Organisation";
  let button = "Submit";

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let isEdit = history.location.pathname.includes("editorganisation")
    ? true
    : false;

  const token = useSelector(({ AuthSlice }) => AuthSlice.token);

  var editOrganizationData = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice.editOrganizationData,
  );

  if (!editOrganizationData) {
    editOrganizationData = location?.state?.data;
  }

  const [value, setValue] = useState(
    isEdit ? (editOrganizationData?.IsSendMail ? 0 : 1) : 0,
  );

  const [isManual, setIsManual] = useState(
    isEdit ? (!editOrganizationData?.IsSendMail ? true : false) : false,
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let manualData = {
    Name: "",
    Email: "",
    Role: "Organisation",
    PhoneNumber: "",
    Reference: "",
    Status: true,
    NoOfLicence: "",
    State: "",
    City: "",
    ContactPersonName: "",
    IsHeader: false,
  };

  let editData = isEdit && {
    Name: editOrganizationData.Name,
    Email: editOrganizationData.Email,

    PhoneNumber: editOrganizationData.PhoneNumber,
    Reference: editOrganizationData.Reference,
    Status: editOrganizationData.Status,
    NoOfLicence: editOrganizationData.NoOfLicence,
    State: editOrganizationData.State,
    City: editOrganizationData.City,
    ContactPersonName: editOrganizationData.ContactPersonName,
    HeaderText: editOrganizationData.HeaderText,
    HeaderLogo1: editOrganizationData.HeaderLogo1,
    HeaderLogo2: editOrganizationData.HeaderLogo2,
    HeaderLogo3: editOrganizationData.HeaderLogo3,
    ColourCode: editOrganizationData.ColourCode,
    _id: editOrganizationData._id,
    IsSendMail: editOrganizationData.IsSendMail,
    IsHeader: editOrganizationData.IsHeader,
  };
  let intialData = !isManual
    ? manualData
    : Object.assign(manualData, { Password: "", ConfirmPassword: "" });

  const [headerChecked, ToggleHeader] = useState(
    isEdit ? editOrganizationData.IsHeader : false,
  );

  const formik = useFormik({
    initialValues: isEdit ? editData : intialData,
    validationSchema:
      isManual && !isEdit
        ? headerChecked
          ? ManualHeaderSchema
          : ManualSchema
        : headerChecked
        ? OrganisationHeaderSchema
        : OrganisationSchema,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setLoading(true);

      let data = new FormData();
      data.append("Name", value?.Name);
      data.append("PhoneNumber", value?.PhoneNumber);
      data.append("Reference", value?.Reference);
      data.append("NoOfLicence", value?.NoOfLicence);
      data.append("Status", value?.Status);
      data.append("State", value?.State);
      data.append("City", value?.City);
      data.append("ContactPersonName", value?.ContactPersonName);
      data.append("IsHeader", value?.IsHeader);

      if (value?.IsHeader) {
        data.append("HeaderText", value?.HeaderText);
        data.append("HeaderLogo1", files[1]?.data ?? value?.HeaderLogo1);
        data.append("HeaderLogo2", files[2]?.data ?? value?.HeaderLogo2);
        data.append("HeaderLogo3", files[3]?.data ?? value?.HeaderLogo3);
        data.append("ColourCode", value?.ColourCode);
      } else {
        data.append("HeaderText", "");
        data.append("HeaderLogo1", "");
        data.append("HeaderLogo2", "");
        data.append("HeaderLogo3", "");
        data.append("ColourCode", "");
      }

      if (isEdit) {
        data.append("_id", value?._id);
        // data.append("IsSendMail", value?.IsSendMail);
      } else {
        data.append("IsSendMail", isManual ? false : true);
        data.append("Email", value?.Email);
        if (isManual) {
          data.append("Password", value?.Password);
          data.append("ConfirmPassword", value?.ConfirmPassword);
        }
      }

      const header = {
        headers: {
          "x-access-token": token,
        },
      };

      if (isManual) {
        if (isEdit) {
          dispatch(EditManualMail(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();

              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        } else {
          dispatch(ManualMailData(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();

              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        }
      } else {
        if (isEdit) {
          dispatch(EditOrganization(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();

              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        } else {
          dispatch(AddOrganizationData(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();

              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        }
      }
      // }
    },
  });

  const [files, setFiles] = useState({});
  function onFileUpload(event) {
    event.preventDefault();
    formik.setFieldValue([event.target.name], event.target.files[0]);
    let id = event.target.id;
    let file_reader = new FileReader();
    let file = event.target.files[0];

    file_reader.onload = () => {
      setFiles((prev) => ({
        ...prev,
        [id]: {
          file_url: file_reader.result,
          data: file,
        },
      }));
    };
    file_reader.readAsDataURL(file);
  }
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <PageTitle title={heading} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={12}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">
                    {isEdit ? "Edit" : "Create"} Organisation
                  </h3>
                </div>

                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="organization-tab"
                    >
                      <Tab
                        disabled={isEdit && !formik.values.IsSendMail}
                        label="Send Email"
                        onClick={() => {
                          setIsManual(false);
                        }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        disabled={isEdit && formik.values.IsSendMail}
                        label="Manual"
                        onClick={() => {
                          setIsManual(true);
                        }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} className="form-padding">
                    {!isEdit && (
                      <p className="note-color">
                        Note :- If you choose send email then you will get
                        password on email address.
                      </p>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Organisation Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Name"
                              name="Name"
                              variant="outlined"
                              value={formik.values.Name}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.Name && formik.errors.Name ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Name}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Contact Person Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="ContactPersonName"
                              name="ContactPersonName"
                              variant="outlined"
                              value={formik.values.ContactPersonName}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.ContactPersonName &&
                          formik.errors.ContactPersonName ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.ContactPersonName}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Email
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Email"
                              name="Email"
                              variant="outlined"
                              value={formik.values?.Email}
                              onChange={formik.handleChange}
                              disabled={isEdit ? true : false}
                            />
                          </FormGroup>
                          {formik.touched.Email && formik.errors.Email ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Email}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            Phone Number
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="phoneNumber"
                              name="PhoneNumber"
                              variant="outlined"
                              value={formik.values?.PhoneNumber}
                              onChange={formik.handleChange}
                              type="number"
                            />
                          </FormGroup>
                          {formik.touched.PhoneNumber &&
                          formik.errors.PhoneNumber ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.PhoneNumber}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            City
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="City"
                              name="City"
                              variant="outlined"
                              value={formik.values?.City}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.City && formik.errors.City ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.City}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              State
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="State"
                              name="State"
                              variant="outlined"
                              value={formik.values?.State}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.State && formik.errors.State ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.State}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Reference
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Reference"
                              name="Reference"
                              variant="outlined"
                              value={formik.values?.Reference}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.Reference &&
                          formik.errors.Reference ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Reference}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Status
                            </InputLabel>
                            <FormControl variant="outlined">
                              <Select
                                onBlur={formik.handleBlur}
                                labelId="demo-select-smalls"
                                id="status"
                                name="Status"
                                value={formik.values?.Status}
                                onChange={formik.handleChange}
                                placeholder="Status"
                              >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>InActive</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                          {formik.touched.Status && formik.errors.Status ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Status}
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <div>
                        <div className="card-header checkbox-fix">
                          <FormGroup style={{ width: "min-content" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "IsHeader",
                                      e.target.checked,
                                    );
                                    ToggleHeader(e.target.checked);
                                  }}
                                  checked={formik.values?.IsHeader}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          </FormGroup>
                          <h3 className="card-title">Header</h3>
                        </div>
                        {formik.values?.IsHeader && (
                          <>
                            <Grid
                              container
                              spacing={3}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item md={6} xs={12}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Name
                                  </InputLabel>
                                  <TextField
                                    onBlur={formik.handleBlur}
                                    id="HeaderText"
                                    name="HeaderText"
                                    variant="outlined"
                                    value={formik.values?.HeaderText}
                                    onChange={formik.handleChange}
                                  />
                                </FormGroup>
                                {formik.touched?.HeaderText ||
                                formik.errors?.HeaderText ? (
                                  <div
                                    style={{ color: "#D71E28" }}
                                    className="validation-error"
                                  >
                                    {formik.errors?.HeaderText}
                                  </div>
                                ) : null}
                                <br />
                              </Grid>
                              <Grid item md={3} xs={12} className="inputField">
                                <InputLabel className="card-input-label">
                                  Color
                                </InputLabel>
                                <TextField
                                  onBlur={formik.handleBlur}
                                  id="ColourCode"
                                  name="ColourCode"
                                  type="color"
                                  variant="outlined"
                                  className="input-div"
                                  onChange={formik.handleChange}
                                  value={formik.values.ColourCode}
                                  placeholder="Color"
                                />
                                {formik.touched.ColourCode ||
                                formik.errors.ColourCode ? (
                                  <div style={{ color: "#D71E28" }}>
                                    {formik.errors.ColourCode}
                                  </div>
                                ) : null}
                              </Grid>
                              <br />
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={3} xs={12} sm={6}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 1{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo1"
                                      id={1}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo1 ||
                                    formik.errors.HeaderLogo1 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo1}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[1] && !formik.values?.HeaderLogo1
                                  }
                                >
                                  <img
                                    src={
                                      files[1]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo1
                                        : files[1]?.file_url
                                    }
                                    height={150}
                                    width={200}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3} xs={12} sm={6}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 2{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo2"
                                      id={2}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo2 ||
                                    formik.errors.HeaderLogo2 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo2}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[2] && !formik.values?.HeaderLogo2
                                  }
                                >
                                  <img
                                    height={150}
                                    width={200}
                                    src={
                                      files[2]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo2
                                        : files[2]?.file_url
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 3{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo3"
                                      id={3}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo3 ||
                                    formik.errors.HeaderLogo3 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo3}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[3] && !formik.values?.HeaderLogo3
                                  }
                                >
                                  <img
                                    height={150}
                                    width={200}
                                    src={
                                      files[3]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo3
                                        : files[3]?.file_url
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="card-footer">
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>

                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  color="primary"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "blue",
                                  }}
                                />
                              ) : (
                                button
                              )}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {!isEdit && (
                      <p className="note-color">
                        Note :- If you choose manual then you need to set
                        password as per your choice.
                      </p>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Organisation Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Name"
                              name="Name"
                              variant="outlined"
                              value={formik.values?.Name}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.Name && formik.errors.Name ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Name}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Contact Person Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="ContactPersonName"
                              name="ContactPersonName"
                              variant="outlined"
                              value={formik.values.ContactPersonName}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.ContactPersonName &&
                          formik.errors.ContactPersonName ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.ContactPersonName}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Email
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Email"
                              name="Email"
                              variant="outlined"
                              value={formik.values?.Email}
                              onChange={formik.handleChange}
                              disabled={isEdit ? true : false}
                            />
                          </FormGroup>
                          {formik.touched.Email && formik.errors.Email ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Email}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            Phone Number
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="phoneNumber"
                              name="PhoneNumber"
                              variant="outlined"
                              value={formik.values?.PhoneNumber}
                              onChange={formik.handleChange}
                              type="number"
                            />
                          </FormGroup>
                          {formik.touched.PhoneNumber &&
                          formik.errors.PhoneNumber ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.PhoneNumber}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            City
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="City"
                              name="City"
                              variant="outlined"
                              value={formik.values?.City}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.City && formik.errors.City ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.City}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              State
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="State"
                              name="State"
                              variant="outlined"
                              value={formik.values?.State}
                              type="text"
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.State && formik.errors.State ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.State}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      {!isEdit && (
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <InputLabel className="card-input-label">
                              Password
                            </InputLabel>
                            <FormGroup>
                              <TextField
                                onBlur={formik.handleBlur}
                                id="Password"
                                name="Password"
                                variant="outlined"
                                value={formik.values?.Password}
                                onChange={formik.handleChange}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormGroup>
                            {formik.touched.Password &&
                            formik.errors.Password ? (
                              <div
                                style={{ color: "#D71E28" }}
                                className="validation-error"
                              >
                                {formik.errors.Password}
                              </div>
                            ) : null}
                            <br />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <FormGroup>
                              <InputLabel className="card-input-label">
                                Confirm Password
                              </InputLabel>
                              <TextField
                                onBlur={formik.handleBlur}
                                id="Confirm Password"
                                name="ConfirmPassword"
                                // label="Confirm Password"
                                variant="outlined"
                                value={formik.values?.ConfirmPassword}
                                onChange={formik.handleChange}
                                type={showPassword1 ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormGroup>
                            {formik.touched.ConfirmPassword &&
                            formik.errors.ConfirmPassword ? (
                              <div
                                style={{ color: "#D71E28" }}
                                className="validation-error"
                              >
                                {formik.errors.ConfirmPassword}
                              </div>
                            ) : null}
                            <br />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Reference
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Reference"
                              name="Reference"
                              variant="outlined"
                              value={formik.values?.Reference}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched.Reference &&
                          formik.errors.Reference ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Reference}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Status
                            </InputLabel>
                            <FormControl variant="outlined">
                              <Select
                                labelId="demo-select-smalls"
                                id="status"
                                name="Status"
                                value={formik.values?.Status}
                                onChange={formik.handleChange}
                                placeholder="Status"
                              >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>InActive</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                          {formik.touched.Status && formik.errors.Status ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Status}
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <br />
                      <div>
                        <div className="card-header checkbox-fix">
                          <FormGroup style={{ width: "min-content" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "IsHeader",
                                      e.target.checked,
                                    );
                                    ToggleHeader(e.target.checked);
                                  }}
                                  checked={formik.values?.IsHeader}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          </FormGroup>
                          <h3 className="card-title">Header</h3>
                        </div>
                        {formik.values?.IsHeader && (
                          <>
                            <Grid
                              container
                              spacing={3}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item md={6} xs={12}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Name
                                  </InputLabel>
                                  <TextField
                                    onBlur={formik.handleBlur}
                                    id="HeaderText"
                                    name="HeaderText"
                                    variant="outlined"
                                    value={formik.values?.HeaderText}
                                    onChange={formik.handleChange}
                                  />
                                </FormGroup>
                                <br />
                                {formik.touched?.HeaderText ||
                                formik.errors?.HeaderText ? (
                                  <div
                                    style={{ color: "#D71E28" }}
                                    className="validation-error"
                                  >
                                    {formik.errors.HeaderText}
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item md={3} xs={12} className="inputField">
                                <InputLabel className="card-input-label">
                                  Color
                                </InputLabel>
                                <TextField
                                  onBlur={formik.handleBlur}
                                  id="ColourCode"
                                  name="ColourCode"
                                  type="color"
                                  variant="outlined"
                                  className="input-div"
                                  onChange={formik.handleChange}
                                  value={formik.values.ColourCode}
                                  placeholder="Color"
                                />
                                {formik.touched.ColourCode ||
                                formik.errors.ColourCode ? (
                                  <div style={{ color: "#D71E28" }}>
                                    {formik.errors.ColourCode}
                                  </div>
                                ) : null}
                              </Grid>
                              <br />
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={3} xs={12} sm={6}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 1{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo1"
                                      id={1}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                        // formik.setFieldValue(
                                        //   "ProfileImage",
                                        //   event.target.files[0],
                                        // );
                                        // setLogos((prev) => [...prev]);
                                        // setSelectedFile(event.target.files[0]);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo1 ||
                                    formik.errors.HeaderLogo1 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo1}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[1] && !formik.values?.HeaderLogo1
                                  }
                                >
                                  <img
                                    src={
                                      files[1]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo1
                                        : files[1]?.file_url
                                    }
                                    height={150}
                                    width={200}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3} xs={12} sm={6}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 2{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo2"
                                      id={2}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo2 ||
                                    formik.errors.HeaderLogo2 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo2}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[2] && !formik.values?.HeaderLogo2
                                  }
                                >
                                  <img
                                    height={150}
                                    width={200}
                                    src={
                                      files[2]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo2
                                        : files[2]?.file_url
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <FormGroup>
                                  <InputLabel className="card-input-label">
                                    Logo 3{" "}
                                    <span className="helperText ">
                                      *Note: Required logo size is 40px X 40px.
                                    </span>
                                  </InputLabel>
                                  <FormControl style={{ width: "max-content" }}>
                                    <input
                                      type="file"
                                      name="HeaderLogo3"
                                      id={3}
                                      accept="image/*"
                                      className="hide_file"
                                      onChange={(event) => {
                                        onFileUpload(event);
                                      }}
                                    />
                                    {formik.touched.HeaderLogo3 ||
                                    formik.errors.HeaderLogo3 ? (
                                      <div style={{ color: "#D71E28" }}>
                                        {formik.errors.HeaderLogo3}
                                      </div>
                                    ) : null}
                                  </FormControl>
                                </FormGroup>
                                <div
                                  style={{ margin: "10px 0px" }}
                                  hidden={
                                    !files[3] && !formik.values?.HeaderLogo3
                                  }
                                >
                                  <img
                                    height={150}
                                    width={200}
                                    src={
                                      files[3]?.file_url == undefined
                                        ? img_url + formik.values?.HeaderLogo3
                                        : files[3]?.file_url
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="card-footer">
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  color="primary"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "blue",
                                  }}
                                />
                              ) : (
                                button
                              )}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                </Box>
              </Box>
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
