import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AddScreenAPI,
  GetScreenAPI,
  EditScreenAPI,
  DeleteScreenAPI,
  UploadScreenImageAPI,
  GetScreenByOrgLangAPI,
} from "../../utils/Services";

const initialState = {
  getScreenData: [],
  editScreenData: "",
};

export const AddScreenData = createAsyncThunk(
  "AddScreenData",
  async (values, { dispatch }) => {
    try {
      const result = await AddScreenAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      // if (!result?.hasError) {
      //   return result;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      return error;
    }
  },
);

export const GetScreen = createAsyncThunk(
  "GetScreen",
  async (values, { dispatch }) => {
    try {
      const result = await GetScreenAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditScreen = createAsyncThunk(
  "EditScreen",
  async (values, { dispatch }) => {
    try {
      const result = await EditScreenAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        return result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteScreen = createAsyncThunk(
  "DeleteScreen",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteScreenAPI({ id: values });
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      // if (!result?.hasError) {
      //   return result;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      return error;
    }
  },
);

export const UploadScreenImage = createAsyncThunk(
  "UploadScreenImage",
  async (values, { dispatch }) => {
    try {
      const result = await UploadScreenImageAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetScreenByOrgLang = createAsyncThunk(
  "GetScreenByOrgLang",
  async (values, { dispatch }) => {
    try {
      const result = await GetScreenByOrgLangAPI(values);
      // console.log(result);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const ScreenSlice = createSlice({
  name: "ScreenSlice",
  initialState,
  reducers: {
    setEditScreendata(state, action) {
      state.editScreenData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetScreen.fulfilled, (state, action) => {
      state.getScreenData = action?.payload?.data;
    });

    builder.addCase(GetScreenByOrgLang.fulfilled, (state, action) => {
      state.getScreenData = action?.payload?.data;
    });
  },
});

export const { setEditScreendata } = ScreenSlice.actions;

export default ScreenSlice.reducer;
