import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import { AdminDashboardAPI, OrgDashboardAPI } from "../../utils/Services";

const initialState = {
  adminDashboardData: {},
  orgDashboardData: {},
};

export const adminDashboard = createAsyncThunk(
  "adminDashboard",
  async (values, { dispatch }) => {
    try {
      const result = await AdminDashboardAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const OrgDashboard = createAsyncThunk(
  "OrgDashboard",
  async (values, { dispatch }) => {
    try {
      const result = await OrgDashboardAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminDashboard.fulfilled, (state, action) => {
      state.adminDashboardData = action?.payload?.data;
    });

    builder.addCase(OrgDashboard.fulfilled, (state, action) => {
      state.orgDashboardData = action?.payload?.data;
    });
    // builder.addCase(EditCategory.fulfilled, (state, action) => {
    //   state.editCategoryData = action?.payload?.data;
    // });
  },
});
//export const { setEditCategorydata } = DashboardSlice.actions;

export default DashboardSlice.reducer;
