import { configureStore } from "@reduxjs/toolkit";
import CategorySlice from "./Reducers/CategoryReducer";
import OrganizationSlice from "./Reducers/OrganizationReducer";
import LanguageSlice from "./Reducers/LanguageReducer";
import ScreenSlice from "./Reducers/ScreenReducer";
import CmsSlice from "./Reducers/CmsReducer";
import UserSlice from "./Reducers/UserReducer";
import AuthSlice from "./Reducers/AuthSlice";
import DashboardSlice from "./Reducers/DashboardReducer";
import ProfileSlice from "./Reducers/ProfileReducer";

//! make auth slice for your signin, signup, forgot password , reset password
// import AuthSlice from "./Reducers/AuthSlice";
//! make common slice for all common api functions and common data
// import CommonSlice from "./Reducers/CommonReducer";
//! make layout slice for your loading screen , toast message and other layout functionality all over web
import LayoutSlice from "./Reducers/LayoutSlice";
import NotificationSlice from "./Reducers/NotificationReducer";

//! make saprete slice for each component or module in your web

export const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    UserSlice: UserSlice,
    DashboardSlice: DashboardSlice,
    CategorySlice: CategorySlice,
    LanguageSlice: LanguageSlice,
    ScreenSlice: ScreenSlice,
    CmsSlice: CmsSlice,
    LayoutSlice: LayoutSlice,
    OrganizationSlice: OrganizationSlice,
    ProfileSlice: ProfileSlice,
    NotificationSlice: NotificationSlice,
  },
});
