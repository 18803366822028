import * as Yup from "yup";
const FILE_SIZE = 5e8;
const SUPPORTED_FORMATS = "image/jpeg image/png image/webp image/jpg";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CategorySchema = Yup.object({
  categoryName: Yup.string().required("Category Name is required."),
  // categoryLogo: Yup.string().required("Topic Image is required."),
  ColourCode: Yup.string().required("Color is required."),
  languageId: Yup.string().required("Language is required."),
  organisationId: Yup.string().required("Organisation is required."),
  Sequence: Yup.number().min(1).required("Sequence is required."),
  categoryLogo: Yup.mixed()
    .required("Topic Image is required.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
});
export const LanguageSchema = Yup.object({
  languageName: Yup.string().required("Language Name is required."),
  ShortName: Yup.string().required("Short Name is required."),
  Sequence: Yup.number().min(1).required("Sequence is required."),
  organisationId: Yup.string().required("Organisation is required."),
  // categoryId: Yup.array().required("Please Select Topic Name."),
});

export const LanguageExistSchema = Yup.object({
  // languageName: Yup.string().required("Language Name is required."),
  // ShortName: Yup.string().required("Short Name is required."),
  // Sequence: Yup.number().min(1).required("Sequence is required."),
  organisationId: Yup.string().required("Organisation is required."),

  // categoryId: Yup.array().required("Please Select Topic Name."),
});

export const AssignSchema = Yup.object({
  NoOfLicence: Yup.number().min(1).required("No of Licenses is required."),
  Price: Yup.number().min(1).required("Price is required."),
});
export const NotificationSchema = Yup.object({
  notificationTitle: Yup.string()
    .required("Title is required.")
    .max(50, "Must be 50 characters or less"),
  notificationMessage: Yup.string()
    .required("Message is required.")
    .max(160, "Must be 160 characters or less"),
  // organisationId: Yup.string().required("Please Select Organisation"),
});

export const CMSSchema = Yup.object({
  title: Yup.string().required("Title is required."),
  description: Yup.string().required("Description is required"),
  CmsLogo: Yup.mixed()
    .required("Please Select Logo")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  Sequence: Yup.number().min(1).required("Sequence is required."),
});

export const CMSOrgSchema = Yup.object({
  title: Yup.string().required("Title is required."),
  description: Yup.string().required("Description is required"),
  CmsLogo: Yup.mixed()
    .required("Please Select Logo")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  languageId: Yup.string().required("Language is required"),
  organisationId: Yup.string().required("Organisation is required"),
  Sequence: Yup.number().min(1).required("Sequence is required."),
});

export const ScreenSchema = Yup.object({
  // languageId: Yup.string().required("Please Select Language Name."),
  // languageId: Yup.string().required("Language is required."),
  categoryId: Yup.string().required("Topic is required."),
  organisationId: Yup.string().required("Organisation is required."),
  screen: Yup.array().of(
    Yup.object().shape({
      // title: Yup.string().required("Title is required"),
      // title: Yup.string().max(170, "Please use less characters."),
      Description: Yup.string().required("Description is required"),
      Sequence: Yup.number().min(1).required("Sequence is required."),
      image: Yup.string().required("Image is required."),
    }),
  ),
  // Sequence: Yup.string().required("Sequence is required."),
});

export const ScreenSchema1 = Yup.object({
  // languageId: Yup.string().required("Please Select Language Name."),
  // languageId: Yup.string().required("Language is required."),
  categoryId: Yup.string().required("Topic is required."),
  organisationId: Yup.string().required("Organisation is required."),
  maintitle: Yup.string().required("Title is required."),
  mainDescription: Yup.string().required("Description is required."),
  mainImage: Yup.mixed()
    .required("Image is required.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  // video: Yup.string().url().required("Video is required."),
  screen: Yup.array().of(
    Yup.object().shape({
      // title: Yup.string().max(170, "Please use less characters."),
      Description: Yup.string().required("Description is required"),
      Sequence: Yup.number().min(1).required("Sequence is required."),
      image: Yup.string().required("Image is required."),
    }),
  ),
  // Sequence: Yup.string().required("Sequence is required."),
});
export const OrganisationSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Organisation Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
  // .max(10, "please enter 10 Digit"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
  State: Yup.string().required("Please enter State Name."),
  City: Yup.string().required("Please enter City Name."),
  ContactPersonName: Yup.string().required("Please enter Contact Person Name."),
});

export const OrganisationHeaderSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Organisation Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "please enter 10 Digit"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
  State: Yup.string().required("Please enter State Name."),
  City: Yup.string().required("Please enter City Name."),
  ContactPersonName: Yup.string().required("Please enter Contact Person Name."),
  HeaderText: Yup.string().required("Please enter Name."),
  ColourCode: Yup.string().required("Please Choose Color."),
  HeaderLogo1: Yup.mixed()
    .required("Please Select Logo1.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  HeaderLogo2: Yup.mixed()
    .required("Please Select Logo2.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  HeaderLogo3: Yup.mixed()
    .required("Please Select Logo3.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
});

export const ManualSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Organisation Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
  Password: Yup.string()
    .min(6, "Please enter minimum 6 letter")
    .required("Please Enter Password."),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
  //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character",
  // ),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("Password"), null], "Password must match")
    .required("Please Enter Confirm Password"),
  State: Yup.string().required("Please enter State Name."),
  City: Yup.string().required("Please enter City Name."),
  ContactPersonName: Yup.string().required("Please enter Contact Person Name."),
});

export const ManualHeaderSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Organisation Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
  Password: Yup.string()
    .min(6, "Please enter minimum 6 letter")
    .required("Please Enter Password."),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
  //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character",
  // ),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("Password"), null], "Password must match")
    .required("Please Enter Confirm Password"),
  State: Yup.string().required("Please enter State Name."),
  City: Yup.string().required("Please enter City Name."),
  ContactPersonName: Yup.string().required("Please enter Contact Person Name."),
  HeaderText: Yup.string().required("Please enter Name."),
  ColourCode: Yup.string().required("Please Choose Color."),
  HeaderLogo1: Yup.mixed()
    .required("Please Select Logo1.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  HeaderLogo2: Yup.mixed()
    .required("Please Select Logo2.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
  HeaderLogo3: Yup.mixed()
    .required("Please Select Logo3.")
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value == "object") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else {
        return true;
      }
    }),
});

export const EditProfileSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Please enter your Name."),
  PhoneNumber: Yup.string()
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
});
export const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Current Password is mandatory")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character",
    ),
  NewPassword: Yup.string()
    // .min(6, "Please enter minimum 6 letter")
    .required("New Password is mandatory")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character",
    ),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword"), null], "Password must match")
    .required("Please Enter Confirm Password"),
});
export const UserSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("User Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
});
export const UserManualSchema = Yup.object({
  Name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("User Name is required."),
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Please enter your Email"),
  PhoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter a Phone Number")
    .min(10, "Please enter 10 Digit")
    .max(10, "Please enter 10 Digits only"),
  Reference: Yup.string().required("Please enter a Reference"),
  Status: Yup.string().required("Please Select Status."),
  Password: Yup.string()
    // .min(6, "Please enter minimum 6 letter")
    .required("Please Enter Password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character",
    ),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("Password"), null], "Password must match")
    .required("Please Enter Confirm Password"),
});

export const HeaderSchema = Yup.object({
  HeaderText: Yup.string().required("Name is required."),
  ColourCode: Yup.string().required("Color is required."),
  HeaderLogo1: Yup.string().required("Logo1 is required."),
  HeaderLogo2: Yup.string().required("Logo2 is required."),
  HeaderLogo3: Yup.string().required("Logo3 is required."),
});
export const LoginSchema = Yup.object({
  Email: Yup.string()
    .email("Please enter valid Email")
    .required("Email is required."),
  Password: Yup.string()
    .min(6, "Please enter minimum 6 letter")
    .required("Please Enter Password."),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&()_+~`|}{\[\]:;?><,.\/-=])(?=.{6,})/,
  //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number, and One Special Character",
  // ),
});
// --------------------***********************---------------------

export const FeedbackSchema = Yup.object({
  feedback: Yup.string().required("Please enter feedback"),
});
export const CommentSchema = Yup.object({
  comment: Yup.string().required("Please enter comment"),
});
export const PayoutSchema = Yup.object({
  amount: Yup.string()
    .required("Please enter amount")
    .matches(/^[0-9.0-9]+$/, "Please enter only numbers"),
  bank_id: Yup.string().required("Please select bank account"),
});
export const ForgotPaswordSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
});

export const signupStepTwoSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
});
export const SignInSchema = Yup.object({
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Please enter a mobile number")
    .min(10, "Please enter 10 Digit"),
});
export const otpSchema = Yup.object({
  otp: Yup.string()
    .required("please enter the otp")
    .matches(/^[0-9.0-9]+$/, "Please enter only numbers")
    .min(4, "Please enter 4 Digit otp"),
});
export const BuildingAlphaSchema = Yup.object().shape({
  type: Yup.string().required("Please select Building Type"),
  alpha: Yup.string().when("type", {
    is: (type) => type !== "",
    then: Yup.string().required("please select the block"),
  }),
});
export const BuildingTypeSchema = Yup.object().shape({
  type: Yup.string().required("Please select Building Type"),
  // alpha: Yup.string(),
});
export const AddCommentSchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  rating: Yup.string().required("Please select Rating Star"),
  Comment: Yup.string().required("Please enter Comment"),
});

export const SignUpSchema = Yup.object({
  step_one: Yup.object({
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter your email"),
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    // username: Yup.string().required("Please enter your user name"),
    // password: Yup.string()
    //   .min(6, "Please enter minimum 6 letter")
    //   .required("Please enter your password"),
    // month: Yup.string().required("Please select month"),
    // day: Yup.string().required("Please select day"),
    // year: Yup.string().required("Please select year"),
    // country: Yup.string().required("Please select country"),
    // state: Yup.string().required("Please select state"),
    // city: Yup.string().required("Please select city"),
  }),
  step_two: Yup.object().shape({
    image: Yup.mixed()
      .test(
        "fileSize",
        "File is too large or empty",
        (value) => value === null || (value && value.size <= FILE_SIZE),
      )
      .test(
        "fileFormat",
        "Unsupported file type",
        (value) =>
          value === null || (value && SUPPORTED_FORMATS.includes(value.type)),
      ),
  }),
  step_three: Yup.object().shape(
    {
      instagram: Yup.string()
        .url("Please enter a valid URL")
        .when(["linkedIn", "twitter"], {
          is: (linkedIn, twitter) => !linkedIn && !twitter,
          then: Yup.string().required("Please enter Instagram url"),
        }),
      twitter: Yup.string()
        .url("Please enter a valid URL")
        .when(["linkedIn", "instagram"], {
          is: (linkedIn, instagram) => !linkedIn && !instagram,
          then: Yup.string().required("Please enter Twitter url"),
        }),
      linkedIn: Yup.string()
        .url("Please enter a valid URL")
        .when(["instagram", "twitter"], {
          is: (instagram, twitter) => !instagram && !twitter,
          then: Yup.string().required("Please enter LinkedIn url"),
        }),
    },
    [
      ["linkedIn", "instagram"],
      ["linkedIn", "twitter"],
      ["instagram", "twitter"],
    ],
  ),
  step_four: Yup.object({
    current_job: Yup.object({
      title: Yup.string().required("Please enter title"),
      company_name: Yup.string().required("Please enter company name"),
    }),
    past_jobs: Yup.array().of(
      Yup.object({
        title: Yup.string().required("Please enter title"),
        company_name: Yup.string().required("Please enter company name"),
      }),
    ),
  }),
  step_five: Yup.array().of(
    Yup.object({
      school_university: Yup.string().required("Please enter school name"),
      start_year: Yup.string().required("Please select start year"),
      end_year: Yup.string().required("Please select end year"),
    }),
  ),
  step_six: Yup.array().of(
    Yup.object({
      answer: Yup.string().required("Please enter your answer"),
    }),
  ),
  step_seven: Yup.object({
    answer: Yup.string().required("Please enter your answer"),
  }),
});

export const PastJobsSchema = Yup.object({
  past_jobs: Yup.array().of(
    Yup.object({
      title: Yup.string().required("Please enter title"),
      company_name: Yup.string().required("Please enter company name"),
    }),
  ),
});
export const EducationSchema = Yup.object({
  educations: Yup.array().of(
    Yup.object({
      school_university: Yup.string().required(
        "Please enter school/university name",
      ),
      start_year: Yup.string().required("Please select start year"),
      end_year: Yup.string().required("Please select end year"),
    }),
  ),
});
export const CurrentJobSchema = Yup.object({
  current_job: Yup.object({
    title: Yup.string().required("Please enter title"),
    company_name: Yup.string().required("Please enter company name"),
  }),
  website: Yup.string().url("Please enter a valid URL"),
});
export const UrlSchema = Yup.object().shape(
  {
    instagram: Yup.string()
      .url("Please enter a valid URL")
      .when(["linkedIn", "twitter"], {
        is: (linkedIn, twitter) => !linkedIn && !twitter,
        then: Yup.string().required("Please enter Instagram url").nullable(),
      }),
    twitter: Yup.string()
      .url("Please enter a valid URL")
      .when(["linkedIn", "instagram"], {
        is: (linkedIn, instagram) => !linkedIn && !instagram,
        then: Yup.string().required("Please enter Twitter url").nullable(),
      }),
    linkedIn: Yup.string()
      .url("Please enter a valid URL")
      .when(["instagram", "twitter"], {
        is: (instagram, twitter) => !instagram && !twitter,
        then: Yup.string().required("Please enter LinkedIn url").nullable(),
      }),
  },
  [
    ["linkedIn", "instagram"],
    ["linkedIn", "twitter"],
    ["instagram", "twitter"],
  ],
);
export const PostImageSchema = Yup.object().shape({
  caption: Yup.string().required("Please enter caption."),
});
export const VideoPostSchema = Yup.object().shape({
  embeded_code: Yup.string().required("Please enter embeded code."),
  title: Yup.string().required("Please enter title."),
  description: Yup.string().required("Please enter description."),
  topic: Yup.string().required("Please select topic."),
  language: Yup.string().required("Please select language."),
  tags: Yup.array().of(Yup.string()).min(1, "Please enter atleast one tag."),
  speakers: Yup.array()
    .of(Yup.string())
    .min(1, "Please select atleast one speaker."),
});
export const EventPostSchema = Yup.object().shape({
  poster: Yup.mixed()
    .test(
      "fileSize",
      "File is too large or empty",
      (value) => value === null || (value && value.size <= FILE_SIZE),
    )
    .test(
      "fileFormat",
      "Unsupported file type",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
  title: Yup.string().required("Please enter event title"),
  start_date: Yup.string().required("Please enter event start date"),
  start_time: Yup.string().required("Please enter event start time"),
  venue: Yup.string().required("Please enter event venue"),
  address: Yup.string().required("Please select event address"),
  details: Yup.string().required("Please enter event details"),
  country_id: Yup.string().required("Please select country"),
  state_id: Yup.string().required("Please select state"),
  city_id: Yup.string().required("Please select city"),
  speakers: Yup.array()
    .of(Yup.string().required())
    .min(1, "Please choose any one speaker"),
  hosts: Yup.array()
    .of(Yup.string().required())
    .min(1, "Please choose any one host"),
  admission_type: Yup.string().required("Please select admission type"),
  admission_data: Yup.array().when(
    "admission_type",
    (admission_type, schema) => {
      return admission_type === "ticket_price"
        ? schema.of(
            Yup.object({
              price: Yup.string().required("Please enter price"),

              category: Yup.string().required("Please select Topic"),
              benifits: Yup.array()
                .of(Yup.string().required("Please enter benefit"))
                .min(1, "Please provide atleast one benefit"),
            }),
          )
        : admission_type === "invite_only"
        ? schema.of(
            Yup.object({
              invitees: Yup.array()
                .of(Yup.string().required())
                .min(1, "Please choose any one invitees"),
              benifits: Yup.array()
                .of(Yup.string().required("Please enter benefit"))
                .min(1, "Please provide atleast one benefit"),
            }),
          )
        : schema.of(
            Yup.object({
              benifits: Yup.array()
                .of(Yup.string().required("Please enter benefit"))
                .min(1, "Please provide atleast one benefit"),
            }),
          );
    },
  ),
  end_date: Yup.string().when("isEndTime", {
    is: true,
    then: Yup.string().required("Please enter event end date"),
  }),
  end_time: Yup.string().when("isEndTime", {
    is: true,
    then: Yup.string().required("Please enter event end time"),
  }),
});
