import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AddCategoryAPI,
  GetCategoryAPI,
  EditCategoryAPI,
  DeleteCategoryAPI,
  GetCategoriesByIdAPI,
  GetUniqueCategoryAPI,
  GetCategoryByLangIdAPI,
  GetCatByOrgLangAPI,
} from "../../utils/Services";

const initialState = {
  getCategoryData: [],
  editCategoryData: "",
  screenCategories: [],
  uniqueCategories: [],
};

export const AddCategoryData = createAsyncThunk(
  "AddCategoryData",
  async (values, { dispatch }) => {
    try {
      const result = await AddCategoryAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetCategory = createAsyncThunk(
  "GetCategory",
  async (values, { dispatch }) => {
    try {
      const result = await GetCategoryAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetUniqueCategory = createAsyncThunk(
  "GetUniqueCategory",
  async (values, { dispatch }) => {
    try {
      const result = await GetUniqueCategoryAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditCategory = createAsyncThunk(
  "EditCategory",
  async (values, { dispatch }) => {
    try {
      const result = await EditCategoryAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteCategory = createAsyncThunk(
  "DeleteCategory",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteCategoryAPI({ id: values });
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      // if (!result?.hasError) {
      //   return result;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      return error;
    }
  },
);

export const GetCategoriesById = createAsyncThunk(
  "GetCategoriesById",
  async (values, { dispatch }) => {
    try {
      const result = await GetCategoriesByIdAPI(values);
      // console.log(result);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetCategoryByLangId = createAsyncThunk(
  "GetCategoryByLangId",
  async (values, { dispatch }) => {
    try {
      const result = await GetCategoryByLangIdAPI(values);
      // console.log(result);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetCatByOrgLang = createAsyncThunk(
  "GetCatByOrgLang",
  async (values, { dispatch }) => {
    try {
      const result = await GetCatByOrgLangAPI(values);
      // console.log(result);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const CategorySlice = createSlice({
  name: "CategorySlice",
  initialState,
  reducers: {
    setEditCategorydata(state, action) {
      state.editCategoryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCategory.fulfilled, (state, action) => {
      // if (action.payload?.data) {
      state.getCategoryData = action?.payload?.data;
      // } else {
      //   state.getCategoryData = [];
      // }
    });

    builder.addCase(GetCatByOrgLang.fulfilled, (state, action) => {
      state.getCategoryData = action?.payload?.data;
    });

    builder.addCase(GetCategoriesById.fulfilled, (state, action) => {
      state.screenCategories = action?.payload?.data;
    });
    builder.addCase(GetUniqueCategory.fulfilled, (state, action) => {
      state.uniqueCategories = action?.payload?.data;
    });
    builder.addCase(GetCategoryByLangId.fulfilled, (state, action) => {
      // if (action.payload?.data) {
      state.getCategoryData = action?.payload?.data;
      // } else {
      //   state.getCategoryData = [];
      // }
    });
    // builder.addCase(EditCategory.fulfilled, (state, action) => {
    //   state.editCategoryData = action?.payload?.data;
    // });
  },
});
export const { setEditCategorydata } = CategorySlice.actions;

export default CategorySlice.reducer;
