import React, { useEffect } from "react";
import { Button, Grid, Tooltip, createMuiTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory, useLocation } from "react-router-dom";

import { ArrowBack, Visibility } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { GetUser } from "../../store/Reducers/UserReducer";
import { GetAllOrgDetails } from "../../store/Reducers/OrganizationReducer";
import moment from "moment";
import { img_url } from "../../utils/constant";
import defaultImage from "../../images/defaultTopic.png";
// import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

export default function OrganisationDetail(props) {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const { editOrganizationData, allOrgDetail } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  // console.log(allOrgDetail);

  const { getUserData } = useSelector(({ UserSlice }) => UserSlice);

  let orgData = [location?.state?.orgData];

  // const newData = [
  //   {
  //     Name: orgData?.Name,
  //     Email: orgData?.Email,
  //     Price: 50,
  //     createdAt: "25-4-2023",
  //     Status: true,
  //   },
  // ];

  // const getMuiTheme = () =>
  //   createTheme({
  //     overrides: {
  //       MUIDataTableToolbar: {
  //         titleText: {
  //           fontWeight: "600",
  //         },
  //       },
  //       MUIDataTableHeadCell: {
  //         fixedHeader: {
  //           fontWeight: "600",
  //           fontSize: "15px",
  //           minWidth: "200px",
  //         },
  //         data: {
  //           fontWeight: "600",
  //           fontSize: "15px",
  //           minWidth: "200px",
  //         },
  //       },
  //     },
  //   });

  const orgUsers = [
    {
      Name: "Tejas",
      PhoneNumber: 9874563210,
      Email: "tejas@sevensquaretech.com",
      Status: true,
    },
  ];

  useEffect(() => {
    dispatch(
      GetUser({
        organisationId: orgData[0]?._id,
      }),
    );

    dispatch(
      GetAllOrgDetails({
        organisationId: orgData[0]._id,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title="Organisation"
        button={
          // <Link to="organisation" className="addlink">
          // </Link>
          <Button
            variant="contained"
            size="medium"
            className="btn-danger"
            onClick={() => history.goBack()}
          >
            <ArrowBack />
            Back
          </Button>
        }
      />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card detail-card">
            <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">Organisation Detail</h3>
            </div>
            <div className="card-body">
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <th>Organisation Name</th>
                    <td>{orgData[0]?.Name ?? "-"}</td>
                  </tr>

                  <tr>
                    <th>E-mail</th>
                    <td>{orgData[0]?.Email ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{orgData[0]?.PhoneNumber ?? "-"}</td>
                  </tr>

                  <tr>
                    <th>Status</th>
                    <td>
                      <div
                        style={{ color: orgData[0]?.Status ? " green" : "red" }}
                      >
                        {orgData[0]?.Status ? "Active" : "In Active"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card detail-card">
            <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">Organisation Header Detail</h3>
            </div>
            <div className="card-body">
              <Grid container spacing={3} style={{ marginTop: "20px" }}>
                <Grid item md={6} xs={12}>
                  <div className="header-details">
                    <span className="header-text">Name:</span>{" "}
                    <span>{allOrgDetail?.orgDetail?.HeaderText ?? "-"}</span>
                  </div>
                </Grid>
                <Grid item md={3} xs={12} className="inputField">
                  <div className="header-details">
                    <span className="header-text">Color: </span>
                    <Tooltip title={allOrgDetail?.orgDetail?.ColourCode ?? "-"}>
                      <div
                        style={{
                          backgroundColor:
                            allOrgDetail?.orgDetail?.ColourCode ?? "white",
                        }}
                        className="color-bg"
                      ></div>
                    </Tooltip>
                  </div>
                </Grid>
                <br />
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "20px" }}>
                <Grid item md={3} xs={12} sm={6}>
                  <span className="header-text">Logo1: </span>
                  <div style={{ margin: "10px 0px" }}>
                    <img
                      src={
                        img_url + allOrgDetail?.orgDetail?.HeaderLogo1 ??
                        defaultImage
                      }
                      height={150}
                      width={200}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <span className="header-text">Logo2: </span>
                  <div style={{ margin: "10px 0px" }}>
                    <img
                      src={
                        img_url + allOrgDetail?.orgDetail?.HeaderLogo2 ??
                        defaultImage
                      }
                      height={150}
                      width={200}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <span className="header-text">Logo3: </span>
                  <div style={{ margin: "10px 0px" }}>
                    <img
                      src={
                        img_url + allOrgDetail?.orgDetail?.HeaderLogo3 ??
                        defaultImage
                      }
                      height={150}
                      width={200}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card">
            <div className="table-container">
              <MUIDataTable
                title="Organisation Detail"
                data={orgData}
                columns={[
                  {
                    name: "Name",
                    label: "Organisation Name",
                  },
                  {
                    name: "Email",
                    label: "E-mail",
                  },
                  {
                    name: "PhoneNumber",
                    label: "Phone Number",
                  },
                  {
                    name: "Status",
                    label: "Status",
                    options: {
                      customBodyRender: (value, tableMeta, update) => {
                        return (
                          <span
                            style={{
                              color: value ? " green" : "red",
                            }}
                          >
                            {value ? "Active" : "In Active"}
                          </span>
                        );
                      },
                    },
                  },
                ]}
                options={{
                  filterType: "",
                   selectableRows: "none",
                  searchOpen: false,
                  search: false,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,

                  // sort: true,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid> */}
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card">
            {/* <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">Assigned Licenses Informations</h3>
            </div> */}
            <div className="table-container">
              {/* <table
                id="user_list_table"
                style={{ width: "100%" }}
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Organisation Name</th>
                    <th>Total Licenses</th>
                    <th>Price</th>
                    <th>Assigned Date</th>
                    <th>Expired Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="10">No User Found.</td>
                  </tr>
                </tbody>
              </table> */}
              {/* <MuiThemeProvider theme={getMuiTheme()}> */}
              <MUIDataTable
                title="Assigned Licenses Informations"
                // button={<CustomToolbar />}
                data={allOrgDetail?.assignData}
                columns={[
                  {
                    name: "SNo",
                    label: "Sr. No.",
                    options: {
                      customBodyRender: (value, tableMeta) => (
                        <span>{tableMeta.rowIndex + 1}</span>
                      ),
                    },
                  },
                  {
                    name: "Name",
                    label: "Organisation Name",
                  },
                  {
                    name: "NoOfLicence",
                    label: "Total Licences",
                  },
                  {
                    name: "Price",
                    label: "Price",
                  },
                  {
                    name: "createdAt",
                    label: "Assigned Date",
                    options: {
                      customBodyRender: (value, tableMeta) => (
                        <span>{moment(value).format("DD-MM-YYYY")}</span>
                      ),
                    },
                  },
                  // {
                  //   name: "createdAt",
                  //   label: "Expired Date",
                  // },
                  // {
                  //   name: "Status",
                  //   label: "Status",
                  //   options: {
                  //     customBodyRender: (value, tableMeta, update) => {
                  //       return (
                  //         <span
                  //           style={{
                  //             color: value ? " green" : "red",
                  //           }}
                  //         >
                  //           {value ? "Active" : "In Active"}
                  //         </span>
                  //       );
                  //     },
                  //   },
                  // },
                ]}
                options={{
                  filterType: "",
                  selectableRows: "none",
                  searchOpen: false,
                  search: false,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  // sort: true,
                }}
              />
              {/* </MuiThemeProvider> */}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card">
            {/* <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">Assigned Licenses Informations</h3>
            </div> */}
            <div className="table-container">
              {/* <table
                id="user_list_table"
                style={{ width: "100%" }}
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Organisation Name</th>
                    <th>Total Licenses</th>
                    <th>Price</th>
                    <th>Assigned Date</th>
                    <th>Expired Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="10">No User Found.</td>
                  </tr>
                </tbody>
              </table> */}
              {/* <MuiThemeProvider theme={getMuiTheme()}> */}
              <MUIDataTable
                title="Organisation Registered Users"
                // button={<CustomToolbar />}
                data={allOrgDetail?.regiUser}
                columns={[
                  {
                    name: "SNo",
                    label: "Sr. No.",
                    options: {
                      download: false,
                      customBodyRender: (value, tableMeta) => (
                        <span>{tableMeta.rowIndex + 1}</span>
                      ),
                    },
                  },
                  {
                    name: "Name",
                    label: "Name",
                    options: {
                      customBodyRender: (value, tableMeta) => {
                        return value ?? <>-</>;
                      },
                    },
                  },
                  {
                    name: "PhoneNumber",
                    label: "Phone Number",
                    options: {
                      customBodyRender: (value, tableMeta) => {
                        return value ?? <>-</>;
                      },
                    },
                  },
                  {
                    name: "Email",
                    label: "Email",
                    options: {
                      customBodyRender: (value, tableMeta) => {
                        return (
                          (
                            <span style={{ wordBreak: "break-word" }}>
                              {value}
                            </span>
                          ) ?? <>-</>
                        );
                      },
                    },
                  },
                  {
                    name: "uniqueId",
                    label: "Unique Id",
                    options: {
                      customBodyRender: (value, tableMeta) => {
                        return value ?? <>-</>;
                      },
                    },
                  },
                  {
                    name: "Status",
                    label: "Status",
                    options: {
                      customBodyRender: (value, tableMeta, update) => {
                        return (
                          <span
                            style={{
                              color: value ? " green" : "red",
                            }}
                          >
                            {value ? "Active" : "In Active"}
                          </span>
                        );
                      },
                    },
                  },
                  {
                    name: "createdAt",
                    label: "Date.",

                    options: {
                      sort: false,
                      display: false,
                      download: true,
                    },
                  },
                ]}
                options={{
                  filterType: "",
                  selectableRows: "none",
                  searchOpen: false,
                  search: false,
                  filter: false,
                  print: false,
                  download: true,
                  viewColumns: false,
                  // sort: true,
                }}
              />
              {/* </MuiThemeProvider> */}
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card">
            <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">Organisation Registered Users</h3>
            </div>
            <div className="card-body">
              <table
                id="user_list_table"
                style={{ width: "100%" }}
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>E-mail</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#1</td>
                    <td>Ganpat</td>
                    <td>9825486956</td>
                    <td>jneszpopokpmbrjgja@tmmbt.com</td>
                    <td>
                      <div style={{ color: "green" }}>Active</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </Grid> */}
      <br />
      <br />
    </>
  );
}
