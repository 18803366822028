import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import style from "../category/styles.css";
// components
import PageTitle from "../../components/PageTitle";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCategory,
  DeleteCategory,
  setEditCategorydata,
  GetCategoryByLangId,
  GetCatByOrgLang,
} from "../../store/Reducers/CategoryReducer";
import { toast } from "react-toastify";
import { img_url } from "../../utils/constant";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import defaultImage from "../../images/defaultTopic.png";
import {
  GetLangByOrg,
  GetLanguage,
  setSelectedLanguage,
} from "../../store/Reducers/LanguageReducer";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";
import {
  GetOrganization,
  setSelectedOrg,
} from "../../store/Reducers/OrganizationReducer";

const Maincategory = () => {
  const dispatch = useDispatch();
  const { getCategoryData } = useSelector(({ CategorySlice }) => CategorySlice);

  const { selectedLanguage, getLanguageData } = useSelector(
    ({ LanguageSlice }) => LanguageSlice,
  );

  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  const history = useHistory();
  const location = useLocation();

  const getEditData = (item) => {
    history.push({
      pathname: "topic/edittopic",
      state: {
        data: item,
      },
    });
    dispatch(setEditCategorydata(item));
  };

  useEffect(() => {
    dispatch(GetLanguage());
    // if (!location.state) {
    //   dispatch(setSelectedLanguage("All"));
    //   dispatch(setSelectedOrg("All"));
    // }
    dispatch(GetOrganization());

    return () => {
      dispatch(setSelectedLanguage("All"));
      dispatch(setSelectedOrg("All"));
    };
  }, []);

  const TableAction = ({ tableMeta }) => {
    const item = getCategoryData.find((x) => x._id === tableMeta.rowData[0]);
    const itemid = tableMeta.rowData[0];

    const [dialog, toggleDialog] = useState(false);

    const openDialog = () => {
      toggleDialog(true);
    };

    const closeDialog = () => {
      toggleDialog(false);
    };

    const handleDelete = (id) => {
      dispatch(DeleteCategory(id)).then((res) => {
        if (res.payload.code === 200) {
          toggleLoading(true);
          if (selectedLanguage == "All") {
            dispatch(GetCategory()).then((res) => toggleLoading(false));
          } else {
            dispatch(
              GetCategoryByLangId({
                languageId: selectedLanguage,
              }),
            ).then((res) => toggleLoading(false));
          }
          toast.error(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
        closeDialog();
      });
    };

    return (
      <>
        <div className="edit-delete-btn">
          <EditIcon
            className="action-icons editBtn"
            style={{ marginRight: "10px" }}
            onClick={() => getEditData(item)}
          />
          <DeleteIcon className="action-icons deleteBtn" onClick={openDialog} />

          <DeleteDialog
            open={dialog}
            handleClose={closeDialog}
            yesClick={() => handleDelete(itemid)}
            text={"Are you sure you want to delete this topic?"}
          />
        </div>
      </>
    );
  };

  const changeLanguage = (e) => {
    let { value } = e.target;
    dispatch(setSelectedLanguage(value));
  };

  const [filterData, setFilterData] = useState({
    org: selectedOrg,
    lang: selectedLanguage,
  });

  useEffect(() => {
    fetchTopic();
  }, [selectedOrg, selectedLanguage]);

  const changeOrg = (e) => {
    let { value } = e.target;
    dispatch(setSelectedOrg(value));
    dispatch(setSelectedLanguage("All"));

    if (value == "All") {
      dispatch(GetLanguage());
    } else {
      // dispatch(setSelectedLanguage("All"));
    }

    dispatch(
      GetLangByOrg({
        organisationId: value,
      }),
    );
  };

  const [loading, toggleLoading] = useState(true);

  // useEffect(() => {
  //   toggleLoading(true);
  //   if (selectedLanguage == "All") {
  //     dispatch(GetCategory()).then((res) => toggleLoading(false));
  //   } else {
  //     dispatch(
  //       GetCategoryByLangId({
  //         languageId: selectedLanguage,
  //       }),
  //     ).then((res) => toggleLoading(false));
  //   }
  // }, [selectedLanguage]);

  const fetchTopic = () => {
    toggleLoading(true);
    let finalObj = {};
    if (selectedOrg !== "All") {
      finalObj.organisationId = selectedOrg;
    }
    if (selectedLanguage !== "All") {
      finalObj.languageId = selectedLanguage;
    }
    dispatch(GetCatByOrgLang(finalObj)).then((res) => toggleLoading(false));
  };

  return (
    <>
      <PageTitle
        title="Topic"
        button={
          <div className="manage-dropdown">
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="organisationId"
                    value={selectedOrg}
                    onChange={changeOrg}
                    placeholder="Status"
                  >
                    <MenuItem value={"All"}>Select Organisation</MenuItem>
                    {getorganization.map((data) => (
                      <MenuItem value={data._id}>{data?.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="language"
                    value={selectedLanguage}
                    onChange={changeLanguage}
                    placeholder="Status"
                  >
                    <MenuItem value={"All"}>Select Language</MenuItem>
                    {getLanguageData.map((data) => (
                      <MenuItem value={data._id}>{data?.languageName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <Link to="topic/addtopic" className="addlink">
              <Button variant="contained" size="medium" className="newBtn">
                <AddIcon />
                New
              </Button>
            </Link>
          </div>
        }
      />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="language-dropdown formobile">
            <FormGroup className="main-dropdown">
              <InputLabel className="card-input-label">Language</InputLabel>
              <FormControl variant="outlined">
                <Select
                  // onBlur={formik.handleBlur}
                  labelId="demo-select-smalls"
                  id="language"
                  name="language"
                  value={selectedLanguage}
                  onChange={changeLanguage}
                  placeholder="Status"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {getLanguageData.map((data) => (
                    <MenuItem value={data._id}>{data?.languageName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </div>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Topic List"
              data={getCategoryData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    download: false,
                    sort: true,
                    display: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },
                {
                  name: "categoryName",
                  label: "Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      // const tname = getCategoryData[tableMeta.rowIndex];
                      // const name = tname?.categoryName;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "languageName",
                  label: "Language Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      // const tname = getCategoryData[tableMeta.rowIndex];
                      // const name = tname?.languageName;

                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "categoryLogo",
                  label: "Topic Image",
                  options: {
                    filter: true,
                    sort: false,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <span>
                          <img
                            height={50}
                            width={50}
                            src={value ? `${img_url}${value}` : defaultImage}
                            alt="Logo"
                          />
                        </span>
                      );
                    },
                  },
                },
                {
                  name: "ColourCode",
                  label: "Color",
                  options: {
                    filter: true,
                    sort: false,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <div
                            style={{
                              height: "40px",
                              width: "40px",
                              backgroundColor: value,
                              borderRadius: "50%",
                            }}
                          ></div>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Sequence",
                  label: "Sequence",
                },
                {
                  name: "Action",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                      <TableAction tableMeta={tableMeta} />
                    ),
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Maincategory;
