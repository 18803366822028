import { POST } from "../utils/HTTP";
import { base_url } from "../utils/constant";

const role = JSON.parse(localStorage.getItem("ROLE"));
const isAdmin1 = window.location.pathname.includes("/admin") ? true : false;

const isAdmin = () => {
  const role = JSON.parse(localStorage.getItem("ROLE"));
  return role == "Admin" ? true : false;
};

export const ImageURL = `${base_url}`;

/*SuperAdmin Login */
export const LoginAPI = (data) =>
  POST(`${base_url}admin/${isAdmin1 ? "adminSignIn" : "orgLogIn"}`, data);

export const Logout = (data) =>
  POST(`${base_url}${isAdmin() ? "admin/logOut" : "admin/logOut"}`, data);

export const AdminEditProfileAPI = (data) =>
  POST(
    `${base_url}${isAdmin() ? "admin/editProfile" : "user/editProfile"}`,
    data,
  );

/*Organisation Login */
export const OrgLoginAPI = (data) => POST(`${base_url}admin/orgLogIn`, data);

/* Category module*/
export const AddCategoryAPI = (data) =>
  POST(`${base_url}category/createCategory`, data);
export const GetCategoryAPI = (data) =>
  POST(`${base_url}category/getAllCategory`, data);

export const GetUniqueCategoryAPI = (data) =>
  POST(`${base_url}category/getTopic`, data);

export const EditCategoryAPI = (data) =>
  POST(`${base_url}category/updateCategory`, data);
export const DeleteCategoryAPI = (data) =>
  POST(`${base_url}category/deleteCategory`, data);
export const GetCategoriesByIdAPI = (data) =>
  POST(`${base_url}language/getCatByLanguage`, data);
export const GetCategoryByLangIdAPI = (data) =>
  POST(`${base_url}language/getTopicByLang`, data);

/*Language Module */

// export const AddLanguageAPI = (data) =>
//   POST(`${base_url}language/createLanguage`, data);
export const AddLanguageAPI = (data) =>
  POST(`${base_url}language/getLanguage`, data);
export const GetLanguageAPI = (data) =>
  POST(`${base_url}language/getAllLanguage`, data);
export const EditLanguageAPI = (data) =>
  POST(`${base_url}language/updateLanguage`, data);
export const DeleteLanguageAPI = (data) =>
  POST(`${base_url}language/deleteLanguage`, data);
export const GetLanguageCountsAPI = (data) =>
  POST(`${base_url}language/getLanguageCard`, data);

/*Screen Module */
export const AddScreenAPI = (data) =>
  POST(`${base_url}screen/createScreen`, data);
export const GetScreenAPI = (data) =>
  POST(`${base_url}screen/getAllScreen`, data);
export const EditScreenAPI = (data) =>
  POST(`${base_url}screen/updateScreen`, data);
export const DeleteScreenAPI = (data) =>
  POST(`${base_url}screen/deleteScreen`, data);

export const UploadScreenImageAPI = (data) =>
  POST(`${base_url}screen/screenImage`, data);

/*CMS Module */
export const AddCmsAPI = (data) => POST(`${base_url}cms/createCms`, data);
export const GetCmsAPI = (data) => POST(`${base_url}cms/getAllCms`, data);
export const EditCmsAPI = (data) => POST(`${base_url}cms/updateCms`, data);
export const DeleteCmsAPI = (data) => POST(`${base_url}cms/deleteCms`, data);

/*Organisation Module*/
export const AddOrganizationAPI = (data) =>
  POST(`${base_url}organization/createOrganization`, data);
export const ManualMailAPI = (data) =>
  POST(`${base_url}organization/manualMail`, data);
export const GetOrganizationAPI = (data) =>
  POST(`${base_url}organization/getAllOrganization`, data);
export const EditOrganizationAPI = (data) =>
  POST(`${base_url}organization/updateOrganization`, data);
export const GetAllAssignAPI = (data) =>
  POST(`${base_url}assign/getAllAssign`, data);

export const GetAllOrgDetailAPI = (data) =>
  POST(`${base_url}organization/getView`, data);

export const EditManualMailAPI = (data) =>
  POST(`${base_url}organization/updateManualMail`, data);

export const DeleteOrganizationAPI = (data) =>
  POST(`${base_url}organization/deleteOrganization`, data);

export const AssignAPI = (data) => POST(`${base_url}assign/createAssign`, data);

export const AdminBulkUploadAPI = (data) =>
  POST(
    `${base_url}${
      isAdmin() ? "organization/adminBulkUpload" : "user/OrgBulkUpload"
    }`,
    data,
  );
export const AdminExportAPI = (data) =>
  POST(`${base_url}organization/adminexport`, data);

/*User Module */
export const AddUserAPI = (data) => POST(`${base_url}user/createUser`, data);
export const ManualUserAPI = (data) => POST(`${base_url}user/manualMail`, data);

export const EditUserAPI = (data) =>
  POST(
    `${base_url}${isAdmin() ? "admin/updateUser" : "user/updateUser"}`,
    data,
  );
export const EditUserManualAPI = (data) =>
  POST(`${base_url}user/updateManualMail`, data);

export const GetUserAPI = (data) => POST(`${base_url}user/OrgIdByUser`, data);
export const GetAllUserAPI = (data) => POST(`${base_url}user/getAllUser`, data);

export const GetTotalUserAPI = (data) =>
  POST(`${base_url}admin/getAllUser`, data);

export const GetActiveUsersAPI = (data) =>
  POST(
    `${base_url}${
      isAdmin() ? "dashboard/getActiveUser" : "dashboard/getOrgActive"
    }`,
    data,
  );

export const AdminDashboardAPI = (data) =>
  POST(`${base_url}dashboard/adminDashBoard`, data);

export const OrgDashboardAPI = (data) =>
  POST(`${base_url}dashboard/userDashboard`, data);

/* UserProfile */
export const AdminProfileDataAPI = (data) =>
  POST(
    `${base_url}${isAdmin() ? "organization/getAdmin" : "user/getUserById "}`,
    data,
  );

export const ChangePasswordAPI = (data) =>
  POST(
    `${base_url}${isAdmin() ? "admin/AdminChangePass" : "admin/OrgChangePass"}`,
    data,
  );

export const GetAllHistoryAPI = (data) =>
  POST(`${base_url}organization/getHistory`, data);

/* Notification */
export const AddNotificationAPI = (data) =>
  POST(`${base_url}admin/createNotification`, data);
export const GetNotificationAPI = (data) =>
  POST(`${base_url}admin/getNotification`, data);

/* NEW API */
export const GetLangByOrgAPI = (data) =>
  POST(`${base_url}language/getLangByOrg`, data);

export const GetCatByOrgLangAPI = (data) =>
  POST(`${base_url}category/getCatByOrg`, data);

export const GetScreenByOrgLangAPI = (data) =>
  POST(`${base_url}screen/getScreenByOrg`, data);

export const GetCMSbyOrgAPI = (data) =>
  POST(`${base_url}cms/getCmsByOrg`, data);

export const GetNotificationByOrgAPI = (data) =>
  POST(`${base_url}admin/getNotificationByOrg`, data);

export const DeleteNotificationAPI = (data) =>
  POST(`${base_url}admin/deleteNotification`, data);
