import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, Typography } from "@material-ui/core";

// import { useSelector } from "react-redux";

//icons

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Maincategory from "../../pages/category/Category";
import Addcategory from "../../pages/category/AddCategory";
import SubCategory from "../../pages/subcategory/SubCategory";
import Language from "../../pages/language/Language";
import Screen from "../../pages/screen/Screen";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Addlanguage from "../../pages/language/AddLanguage";
import AddScreen from "../../pages/screen/AddScreen";
import Organisation from "../../pages/organisation/Organisation";
import AddOrganisation from "../../pages/organisation/AddOrganisation";
import HistoryData from "../../pages/history/History";
import UserLogs from "../../pages/userlogs/UserLogs";
import EditProfile from "../../pages/settings/editProfile";
import ChangePassword from "../../pages/settings/changePassword";
import Cms from "../../pages/cms/Cms";
import AddCms from "../../pages/cms/AddCms";
import User from "../../pages/user/User";
import AddUser from "../../pages/user/AddUser";
import OrganisationDetail from "../../pages/organisation/OrganisationDetail";
import UserDetail from "../../pages/user/UserDetail";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminProfileData } from "../../store/Reducers/ProfileReducer";
import TotalUsers from "../../pages/user/TotalUsers";
import ActiveUsers from "../../pages/user/ActiveUsers";

import Notification from "../../pages/notification/Notification";
import AddNotification from "../../pages/notification/AddNotification";
import LanguageCards from "../../pages/languagecards/LangageCards";

function Layout(props) {
  var classes = useStyles();
  // const userRole = useSelector(({ AuthSlice }) => AuthSlice.role);
  const { userData } = useSelector(({ AuthSlice }) => AuthSlice);

  const userRole = userData?.Role;

  const dispatch = useDispatch();
  // console.log(userData);
  // global
  var layoutState = useLayoutState();

  // let loginPath = !!pathname.includes("/admin") ? "/admin" : "/organisation";
  // let role = !!pathname.includes("/admin");
  let loginPath = userRole == "Admin" ? "/admin" : "/organisation";
  let role = userRole == "Admin" ? true : false;

  useEffect(() => {
    dispatch(
      adminProfileData({
        _id: userData?._id,
      }),
    );
  }, []);

  // const [isDashboard, toggleDashboard] = useState(false);

  // useEffect(() => {
  //   if (profileData?.IsDashboard) {
  //     toggleDashboard(true);
  //   }
  // }, [profileData]);
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          id="main-content"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{
            paddingBottom: "70px",
          }}
        >
          <div className={classes.fakeToolbar} />

          <Switch>
            <Route
              // component={EditProfile}
              path={`${loginPath}/app/settings`}
              render={() => (
                <Redirect
                  to={{ pathname: `${loginPath}/app/settings/profile` }}
                />
              )}
            >
              <Route
                path={`${loginPath}/app/settings/profile`}
                component={EditProfile}
              />
              <Route
                path={`${loginPath}/app/settings/change-password`}
                component={ChangePassword}
              />
              {/* <Redirect to={`${loginPath}/app/settings/profile`} /> */}
            </Route>

            {role ? (
              <>
                <Route
                  path={`${loginPath}/app/dashboard`}
                  component={Dashboard}
                />

                <Route
                  exact
                  path={`${loginPath}/app/languages`}
                  component={LanguageCards}
                />

                <Route
                  path={`${loginPath}/app/master`}
                  render={() => (
                    <Redirect to={`${loginPath}/app/master/topic`} />
                  )}
                >
                  {/* ======Topic====== */}
                  <Route
                    exact
                    path={`${loginPath}/app/master/topic`}
                    component={Maincategory}
                  />
                  <Route
                    path={`${loginPath}/app/master/topic/addtopic`}
                    component={Addcategory}
                  />
                  <Route
                    path={`${loginPath}/app/master/topic/edittopic`}
                    component={Addcategory}
                  />
                  {/* ======language====== */}
                  <Route
                    exact
                    path={`${loginPath}/app/master/language`}
                    component={Language}
                  />
                  <Route
                    path={`${loginPath}/app/master/language/addlanguage`}
                    component={Addlanguage}
                  />
                  <Route
                    path={`${loginPath}/app/master/language/editlanguage`}
                    component={Addlanguage}
                  />
                  {/* ======Screen====== */}
                  <Route
                    exact
                    path={`${loginPath}/app/master/screen`}
                    component={Screen}
                  />
                  <Route
                    path={`${loginPath}/app/master/screen/addscreen`}
                    component={AddScreen}
                  />
                  <Route
                    path={`${loginPath}/app/master/screen/editscreen`}
                    component={AddScreen}
                  />
                  {/* ======CMS====== */}
                  <Route
                    exact
                    path={`${loginPath}/app/master/cms`}
                    component={Cms}
                  />
                  <Route
                    path={`${loginPath}/app/master/cms/addcms`}
                    component={AddCms}
                  />
                  <Route
                    path={`${loginPath}/app/master/cms/editcms`}
                    component={AddCms}
                  />
                </Route>

                <Route
                  path={`${loginPath}/app/subcategory`}
                  component={SubCategory}
                />
                {/* ========Organisation======= */}
                <Route
                  exact
                  path={`${loginPath}/app/organisation`}
                  component={Organisation}
                />
                <Route
                  path={`${loginPath}/app/organisation/addorganisation`}
                  component={AddOrganisation}
                />
                <Route
                  path={`${loginPath}/app/organisation/editorganisation`}
                  component={AddOrganisation}
                />
                <Route
                  path={`${loginPath}/app/organisation/organisationview`}
                  component={OrganisationDetail}
                />

                <Route
                  path={`${loginPath}/app/payment-history`}
                  component={HistoryData}
                />
                <Route path={`${loginPath}/app/logs`} component={UserLogs} />

                {/* ========Users======= */}
                <Route path={`${loginPath}/app/users`} component={TotalUsers} />
                <Route
                  path={`${loginPath}/app/userview`}
                  component={UserDetail}
                />
                <Route path={`${loginPath}/app/edituser`} component={AddUser} />
                <Route
                  path={`${loginPath}/app/activeusers`}
                  component={ActiveUsers}
                />

                <Route
                  exact
                  path={`${loginPath}/app/notifications`}
                  component={Notification}
                />
                <Route
                  path={`${loginPath}/app/notifications/addnotification`}
                  component={AddNotification}
                />
              </>
            ) : (
              <div>
                <Route
                  path={`${loginPath}/app/dashboard`}
                  component={Dashboard}
                />
                {/* ========Users======= */}
                <Route exact path={`${loginPath}/app/users`} component={User} />
                <Route
                  path={`${loginPath}/app/users/adduser`}
                  component={AddUser}
                />
                <Route
                  path={`${loginPath}/app/users/edituser`}
                  component={AddUser}
                />
                <Route
                  path={`${loginPath}/app/users/userview`}
                  component={UserDetail}
                />
                <Route
                  path={`${loginPath}/app/users/activeusers`}
                  component={ActiveUsers}
                />
              </div>
            )}
          </Switch>
          <Box
            mt={5}
            position="fixed"
            bottom={0}
            width={"80%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="space-between"
            className="footer"
          >
            <div>
              <Typography color="primary" className={classes.copyright}>
                © 2023{" "}
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Cyber Aware
                </a>
                . All rights reserved.
              </Typography>
            </div>
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
