import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  dialogContainer: {
    height: 200,
    width: 400,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  buttonGroup: {
    padding: "16px 24px",
  },
  yesBtn: {
    color: "white",
    background: "#008000c4",
    cursor: "pointer",
    "&:hover": {
      background: "#008000c4 !important",
    },
  },
  noBtn: {
    color: "white",
    background: "#d12626c4",
    cursor: "pointer",
    "&:hover": {
      background: "#d12626c4 !important",
    },
  },
}));
