import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import {
  DeleteNotification,
  GetNotificationByOrg,
  GetNotifications,
} from "../../store/Reducers/NotificationReducer";
import moment from "moment";
import "./styles.css";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";
import { GetOrganization } from "../../store/Reducers/OrganizationReducer";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";

export default function Notification() {
  const dispatch = useDispatch();
  const { notificationData } = useSelector(
    ({ NotificationSlice }) => NotificationSlice,
  );
  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    dispatch(GetOrganization());
  }, []);

  const [org, setOrg] = useState("All");

  const changeOrganisation = (e) => {
    setOrg(e.target.value);
  };

  useEffect(() => {
    toggleLoading(true);
    if (org == "All") {
      dispatch(GetNotifications()).then((res) => toggleLoading(false));
    } else {
      dispatch(
        GetNotificationByOrg({
          organisationId: org,
        }),
      ).then((res) => toggleLoading(false));
    }
  }, [org]);

  const TableAction = ({ tableMeta }) => {
    const item = notificationData.find((x) => x._id === tableMeta.rowData[0]);
    const itemid = tableMeta.rowData[0];

    const [dialog, toggleDialog] = useState(false);

    const openDialog = () => {
      toggleDialog(true);
    };

    const closeDialog = () => {
      toggleDialog(false);
    };

    const handleDelete = (id) => {
      dispatch(DeleteNotification(id)).then((res) => {
        if (res.payload.code === 200) {
          dispatch(GetNotifications());
          toast.error(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
        closeDialog();
      });
    };

    return (
      <>
        <div className="edit-delete-btn">
          <DeleteIcon className="action-icons deleteBtn" onClick={openDialog} />

          <DeleteDialog
            open={dialog}
            handleClose={closeDialog}
            yesClick={() => handleDelete(itemid)}
            text={"Are you sure you want to delete this notification?"}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <PageTitle
        title="Notification"
        button={
          <div className="manage-dropdown">
            <div className="language-dropdown forweb">
              <FormGroup className="main-dropdown">
                <FormControl variant="outlined">
                  <Select
                    // onBlur={formik.handleBlur}
                    labelId="demo-select-smalls"
                    id="language"
                    name="organisationId"
                    value={org}
                    onChange={changeOrganisation}
                    placeholder="Status"
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {getorganization.map((data) => (
                      <MenuItem value={data._id}>{data?.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
            <Link to="notifications/addnotification" className="addlink">
              <Button variant="contained" size="medium" className="newBtn">
                <AddIcon />
                New
              </Button>
            </Link>
          </div>
        }
      />
      <Grid container spacing={4} className="content-grid notification-table">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Notification List"
              data={notificationData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    sort: false,
                    display: false,
                    download: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },
                {
                  name: "notificationTitle",
                  label: "Title",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "notificationMessage",
                  label: "Message",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Name",
                  label: "Organisation",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "createdAt",
                  label: "Date",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      return (
                        <>
                          <span>
                            {value
                              ? moment(value).format("DD/MM/YYYY - HH:mm")
                              : "-"}
                          </span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                      <TableAction tableMeta={tableMeta} />
                    ),
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
