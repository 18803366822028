import React, { useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory, useLocation } from "react-router-dom";

import { ArrowBack, Visibility } from "@material-ui/icons";
import moment from "moment";

export default function UserDetail(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log(location.state);
  const userData = location?.state?.userData;

  const history = useHistory();

  return (
    <>
      <PageTitle
        title="User"
        button={
          // <Link to="users" className="addlink">
          <Button
            variant="contained"
            size="medium"
            className="btn-danger"
            onClick={() => history.goBack()}
          >
            <ArrowBack />
            Back
          </Button>
          // </Link>
        }
      />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="card detail-card">
            <div className="card-header" style={{ padding: ".75rem 1.25rem" }}>
              <h3 className="card-title">User Detail</h3>
            </div>
            <div className="card-body">
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{userData?.Name ?? "-"}</td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td>{userData?.Email ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Contact</th>
                    <td>{userData?.PhoneNumber ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Assigned Date</th>
                    <td>
                      {moment(userData?.createdAt).format("DD-MM-YYYY") ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <div
                        style={{ color: userData?.Status ? " green" : "red" }}
                      >
                        {userData?.Status ? "Active" : "In Active"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </Grid>

      <br />
      <br />
    </>
  );
}
