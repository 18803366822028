import React from "react";
import { Grid } from "@material-ui/core";

// components

import PageTitle from "../../components/PageTitle";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { GetAllHistory } from "../../store/Reducers/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";

const datatableData = [
  ["#1", "Super Admin", "21-02-2022 08:43:47", "login", "-"],
  ["#2", "Super Admin", "17-03-2022 07:39:16", "login", "-"],
];

export default function UserLogs(props) {
  const dispatch = useDispatch();
  const { historyData } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const [loading, toggleLoading] = useState(true);
  useEffect(() => {
    toggleLoading(true);
    dispatch(GetAllHistory()).then((res) => toggleLoading(false));
  }, [dispatch]);

  let newData = [];
  historyData?.map((item, index) => {
    newData?.push({
      ...item,
      date: item.date && moment(item.date).format("DD-MM-YYYY HH:MM:SS"),
    });
  });

  return (
    <>
      <PageTitle title="Histories / Logs" />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Histories / Logs List"
              data={newData}
              columns={[
                {
                  name: "Sr.No.",
                  label: "Sr. No.",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },
                { name: "Name" },
                {
                  name: "date",
                  label: "Date",
                  options: {
                    customBodyRender: (value, tableMeta) => (
                      <span>{value}</span>
                    ),
                  },
                },
                { name: "Type" },
                {
                  name: "IP",
                  label: "Ip",
                  // options: {
                  //   customBodyRender: (value, tableMeta) => {
                  //     const data = historyData[tableMeta.rowIndex];
                  //     const dataString = JSON.stringify({
                  //       ip: data.IP,
                  //       user_agent: data.User_Agent,
                  //     });

                  //     const logData = "";
                  //     return <span>{dataString}</span>;
                  //   },
                  // },
                },
                {
                  name: "User_Agent",
                  label: "User Agent",
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                // sort: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
