import React, { useState } from "react";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../utils/Schema";
// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChangeUserPassword } from "../../store/Reducers/ProfileReducer";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const initialValue = {
  oldPassword: "",
  NewPassword: "",
  ConfirmPassword: "",
};

export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // let heading = "Add Sub Category";
  let button = "Submit";
  const [data, setData] = useState(initialValue);
  const { userData, role } = useSelector(({ AuthSlice }) => AuthSlice);

  const submitForm = (value) => {
    // console.log("allData", value);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <PageTitle title="Change Password" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Formik
            initialValues={data}
            enableReinitialize={true}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values) => {
              let data = {
                ...values,
                [role == "Admin" ? "_id" : "organisationId"]: userData._id,
              };
              dispatch(ChangeUserPassword(data)).then((response) => {
                if (response?.payload?.code === 200) {
                  history.push(
                    role == "Admin"
                      ? "/admin/app/dashboard"
                      : "/organisation/app/dashboard",
                  );
                  toast.success(response?.payload?.message);
                } else {
                  toast.error(response?.payload?.message);
                }
              });
            }}
          >
            {(props) => {
              return (
                <>
                  {/* <PageTitle title={heading} /> */}
                  <Grid container spacing={4} className="content-grid">
                    <Grid item xs={12} md={6}>
                      <Box className="Card_style">
                        <div className="card-header">
                          <h3 className="card-title">Change Password</h3>
                        </div>
                        <br />
                        <form onSubmit={props.handleSubmit} noValidate>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Current Password
                            </InputLabel>
                            <TextField
                              onBlur={props.handleBlur}
                              id="oldPassword"
                              name="oldPassword"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              // label="Current Password"
                              variant="outlined"
                              value={props.values.oldPassword}
                              onChange={props.handleChange}
                            />
                          </FormGroup>
                          {props.touched.oldPassword &&
                          props.errors.oldPassword ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {props.errors.oldPassword}
                            </div>
                          ) : null}
                          <br />

                          <FormGroup>
                            <InputLabel className="card-input-label">
                              New Password
                            </InputLabel>
                            <TextField
                              onBlur={props.handleBlur}
                              id="NewPassword"
                              name="NewPassword"
                              type={showPassword1 ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword1}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword1 ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              // label="New Password"
                              variant="outlined"
                              value={props.values.NewPassword}
                              onChange={props.handleChange}
                            />
                          </FormGroup>
                          {props.touched.NewPassword &&
                          props.errors.NewPassword ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {props.errors.NewPassword}
                            </div>
                          ) : null}
                          <br />

                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Confirm Password
                            </InputLabel>
                            <TextField
                              onBlur={props.handleBlur}
                              id="ConfirmPassword"
                              type={showPassword2 ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword2}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword2 ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              name="ConfirmPassword"
                              // label="Confirm Password"
                              variant="outlined"
                              value={props.values.ConfirmPassword}
                              onChange={props.handleChange}
                            />
                          </FormGroup>
                          {props.touched.ConfirmPassword &&
                          props.errors.ConfirmPassword ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {props.errors.ConfirmPassword}
                            </div>
                          ) : null}
                          <br />

                          <br />
                          <div className="row">
                            <div className="card-footer">
                              <Box className="submit-cancel-box">
                                <Link
                                  to={
                                    role == "Admin"
                                      ? "/admin/app/dashboard"
                                      : "/organisation/app/dashboard"
                                  }
                                >
                                  <Button
                                    size="large"
                                    variant="contained"
                                    className="cat-subCancel-btn"
                                    type="button"
                                  >
                                    Cancel
                                  </Button>
                                </Link>
                                <Button
                                  size="large"
                                  variant="contained"
                                  className="cat-sub-btn"
                                  type="submit"
                                  value={button}
                                >
                                  {button}
                                </Button>
                              </Box>
                            </div>
                          </div>
                        </form>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}
