import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { CMSOrgSchema, CMSSchema } from "../../utils/Schema";
// components
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import { AddCmsData, EditCms } from "../../store/Reducers/CmsReducer";
import "./styles.css";
import { GetOrganization } from "../../store/Reducers/OrganizationReducer";
import {
  GetLangByOrg,
  GetLanguage,
} from "../../store/Reducers/LanguageReducer";
import { img_url } from "../../utils/constant";
import { isEmpty } from "../../utils/Functions";
import Editors from "../../utils/Editor";
import defaultImage from "../../images/defaultTopic.png";

export default function AddCms() {
  let heading = "CMS - Content Management System";
  let heading1 = "CMS";
  let button = "Submit";
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let isEdit = history.location.pathname.includes("editcms") ? true : false;

  var { editCmsData } = useSelector(({ CmsSlice }) => CmsSlice);

  if (!editCmsData) {
    editCmsData = location?.state?.data;
  }

  const { getorganization } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );
  const { getLanguageData } = useSelector(({ LanguageSlice }) => LanguageSlice);

  const [isOrg, setIsOrg] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      IsAcknowledgement: false,
      CmsLogo: "",
      languageId: "",
      organisationId: "",
      Sequence: "",
    },
    enableReinitialize: true,
    // validationSchema: isOrg ? CMSOrgSchema : CMSSchema,
    validationSchema: CMSSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let data = new FormData();

      data.append("title", values?.title);
      data.append("description", values?.description);
      data.append("CmsLogo", values?.CmsLogo);
      data.append("IsAcknowledgement", values?.IsAcknowledgement);
      data.append("languageId", values?.languageId);
      data.append(
        "organisationId",
        isEmpty(values?.organisationId) ? "" : values?.organisationId,
      );
      data.append(
        "Sequence",
        isEmpty(values?.Sequence) ? "" : values?.Sequence,
      );

      // console.log(values);
      if (isEdit) {
        data.append("_id", values?._id);
        dispatch(EditCms(data)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();
            resetForm();

            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
        });
      } else {
        dispatch(AddCmsData(data)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();
            resetForm();
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.organisationId) {
      setIsOrg(true);
    } else {
      setIsOrg(false);
    }
  }, [formik.values.organisationId]);

  useEffect(() => {
    if (isEdit) {
      // formik.setValues({
      //   _id: editCmsData._id,
      //   title: editCmsData.title,
      //   description: editCmsData.description,
      //   Sequence: editCmsData.Sequence,
      //   languageId: editCmsData.languageId,
      //   CmsLogo: editCmsData.CmsLogo,
      //   organisationId: editCmsData.organisationId,
      //   IsAcknowledgement: editCmsData.IsAcknowledgement ?? false,
      // });

      formik.setValues(editCmsData);
    }

    if (!editCmsData && isEdit) {
      history.goBack();
    }
  }, [dispatch, editCmsData]);

  useEffect(() => {
    dispatch(GetOrganization());
    // dispatch(GetLanguage());
  }, [dispatch]);

  const [file, setFile] = useState([]);
  function onFileUpload(event) {
    event.preventDefault();
    let id = event.target.id;
    let file_reader = new FileReader();
    let file = event.target.files[0];

    file_reader.onload = () => {
      setFile((prev) => ({
        [0]: {
          file_url: file_reader.result,
          data: file,
        },
      }));

      formik.setFieldValue("CmsLogo", file);
    };
    file_reader.readAsDataURL(file);
  }

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    if (formik.values.organisationId === "") {
      dispatch(GetLanguage());
    } else {
      setTimeout(() => {
        dispatch(
          GetLangByOrg({
            organisationId: formik.values.organisationId,
          }),
        );
      }, 500);
    }
  }, [formik.values.organisationId, isEdit]);

  return (
    <>
      <div className="window-class">
        <PageTitle title={heading} />
      </div>
      <div className="mobile-class">
        <PageTitle title={heading1} />
      </div>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={6}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">
                    {isEdit ? "Edit" : "Create"} CMS
                  </h3>
                </div>
                <br />
                <form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <InputLabel className="card-input-label">Name</InputLabel>

                    <TextField
                      id="title"
                      name="title"
                      variant="outlined"
                      value={formik.values?.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </FormGroup>
                  {formik.touched.title && formik.errors.title ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.title}
                    </div>
                  ) : null}
                  <br />
                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Description
                    </InputLabel>
                    <div style={{ width: "100%" }}>
                      <Editors
                        data={formik.values?.description}
                        changeHandler={(data) => {
                          formik.setFieldValue("description", data);
                        }}
                      />
                    </div>
                  </FormGroup>
                  {formik.touched.description && formik.errors.description ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.description}
                    </div>
                  ) : null}
                  <br />

                  <div>
                    <FormGroup>
                      <InputLabel className="card-input-label">
                        Logo{" "}
                        <span className="helperText ">
                          *Note: Required logo size is 400px X 220px.
                        </span>
                      </InputLabel>
                      <FormControl style={{ width: "max-content" }}>
                        <input
                          type="file"
                          name="CmsLogo"
                          id={3}
                          accept="image/*"
                          className="hide_file"
                          onChange={(event) => {
                            onFileUpload(event);
                          }}
                        />
                      </FormControl>
                    </FormGroup>

                    <div
                      style={{ margin: "10px 0px" }}
                      hidden={!file[0] && !formik.values?.CmsLogo}
                    >
                      <img
                        height={150}
                        width={200}
                        src={
                          file[0]?.file_url == undefined
                            ? img_url + formik.values?.CmsLogo
                            : file[0]?.file_url
                            ? file[0]?.file_url
                            : defaultImage
                        }
                        alt="Logo"
                      />
                    </div>
                  </div>
                  {formik.touched.CmsLogo && formik.errors.CmsLogo ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.CmsLogo}
                    </div>
                  ) : null}
                  <br />

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Organisation
                    </InputLabel>
                    <FormControl variant="outlined">
                      <Select
                        id="organisationId"
                        name="organisationId"
                        placeholder="Category"
                        value={formik.values.organisationId}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("languageId", "");
                          setLoading1(true);
                          dispatch(
                            GetLangByOrg({
                              organisationId: e.target.value,
                            }),
                          ).then((res) => setLoading1(false));
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getorganization &&
                          getorganization?.map((value, i) => {
                            return (
                              <MenuItem value={value._id} key={i}>
                                {value.Name.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {formik.touched.organisationId &&
                  formik.errors.organisationId ? (
                    <div
                      style={{ color: "#D71E28" }}
                      className="validation-error"
                    >
                      {formik.errors.organisationId}
                    </div>
                  ) : null}

                  <br />

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Language
                    </InputLabel>
                    <FormControl variant="outlined">
                      <Select
                        labelId="demo-select-smalls"
                        id="languageId"
                        name="languageId"
                        value={formik.values.languageId}
                        onChange={formik.handleChange}
                        displayEmpty
                        onBlur={formik.handleBlur}
                        disabled={!formik.values.organisationId || loading1}
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getLanguageData &&
                          getLanguageData?.map((value, i) => {
                            return (
                              <MenuItem value={value._id} key={i}>
                                {value.languageName
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {formik.touched.languageId && formik.errors.languageId ? (
                    <div
                      style={{ color: "#D71E28" }}
                      className="validation-error"
                    >
                      {formik.errors.languageId}
                    </div>
                  ) : null}

                  <br />

                  <div className="inputField">
                    <InputLabel className="card-input-label">
                      Sequence
                    </InputLabel>
                    <TextField
                      id="Sequence"
                      name="Sequence"
                      type="number"
                      variant="outlined"
                      className="input-div"
                      onChange={formik.handleChange}
                      value={formik.values.Sequence}
                    />
                  </div>
                  {formik.touched.Sequence && formik.errors.Sequence ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.Sequence}
                    </div>
                  ) : null}
                  <br />

                  <div className="row">
                    <div className="col">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              label="IsAcknowledgement"
                              checked={formik.values?.IsAcknowledgement}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "IsAcknowledgement",
                                  e.target.checked,
                                );
                              }}
                            />
                          }
                          label="Acknowledgement"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <br />
                  {Object.keys(formik.errors).length > 0 && (
                    <div
                      style={{ color: "#D71E28" }}
                      className="validation-error"
                    >
                      * Please Fill All Required Fields.
                    </div>
                  )}

                  <div className="row">
                    <div className="card-footer">
                      <Box className="submit-cancel-box">
                        <Button
                          size="large"
                          variant="contained"
                          className="cat-subCancel-btn"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>

                        <Button
                          size="large"
                          variant="contained"
                          className="cat-sub-btn"
                          type="submit"
                          value={button}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "blue",
                              }}
                            />
                          ) : (
                            button
                          )}
                        </Button>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
