import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { CategorySchema } from "../../utils/Schema";

// components
import PageTitle from "../../components/PageTitle";
import { useLocation, useHistory } from "react-router-dom";
import {
  AddCategoryData,
  EditCategory,
} from "../../store/Reducers/CategoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { img_url } from "../../utils/constant";
import { isEmpty } from "../../utils/Functions";
import {
  GetLangByOrg,
  GetLanguage,
} from "../../store/Reducers/LanguageReducer";
import {
  GetOrganization,
  setSelectedOrg,
} from "../../store/Reducers/OrganizationReducer";

import defaultImage from "../../images/defaultTopic.png";

function Addcategory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let isEdit = history.location.pathname.includes("edittopic") ? true : false;
  let heading = isEdit ? "Edit Topic" : "Create Topic";
  let button = "Submit";

  var { editCategoryData } = useSelector(({ CategorySlice }) => CategorySlice);
  if (!editCategoryData) {
    editCategoryData = location.state?.data;
  }

  const { getorganization, selectedOrg } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  const { getLanguageData } = useSelector(({ LanguageSlice }) => LanguageSlice);

  const formik = useFormik({
    initialValues: {
      categoryLogo: "",
      categoryName: "",
      ColourCode: "#000000",
      languageId: "",
      Sequence: "",
      organisationId: "",
    },
    validationSchema: CategorySchema,
    onSubmit: async (value, { resetForm }) => {
      setLoading(true);
      let data = new FormData();

      data.append("categoryLogo", value?.categoryLogo);

      data.append("categoryName", value?.categoryName);
      data.append("ColourCode", value?.ColourCode);
      data.append("_id", value?._id);
      data.append("Sequence", value?.Sequence);
      data.append("languageId", value?.languageId);
      data.append("organisationId", value?.organisationId);

      if (isEdit) {
        dispatch(EditCategory(data)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();
            // history.push("topic");
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
        });
      } else {
        dispatch(AddCategoryData(data)).then((response) => {
          // console.log(response, "response");
          if (response?.payload?.code === 200) {
            setLoading(false);

            history.goBack();
            // history.push("topic");
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
          // resetForm({
          //   categoryLogo: "",
          //   categoryName: "",
          //   Sequence: "",
          // });
        });
      }
    },
  });

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    // dispatch(GetLanguage());
    dispatch(GetOrganization());

    if (!location.state) {
      dispatch(setSelectedOrg("All"));
    }
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formik.values.organisationId === "") {
      dispatch(GetLanguage());
    } else {
      setTimeout(() => {
        dispatch(
          GetLangByOrg({
            organisationId: formik.values.organisationId,
          }),
        );
      }, 500);
      // .then((res) => {
      //   if (isEdit) {
      //     formik.setFieldValue("languageId", editCategoryData?.languageId);
      //   }
      // });
    }
  }, [formik.values.organisationId, isEdit]);

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        _id: editCategoryData?._id,
        categoryName: editCategoryData?.categoryName,
        categoryLogo: editCategoryData?.categoryLogo,
        ColourCode: editCategoryData?.ColourCode,
        Sequence: editCategoryData?.Sequence,
        languageId: editCategoryData?.languageId,
        organisationId: editCategoryData?.organisationId,
      });
    }
  }, [dispatch, editCategoryData]);

  return (
    <>
      <PageTitle title={"Topic"} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={6}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">{heading}</h3>
                </div>
                <br />

                <form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Organisation
                    </InputLabel>
                    <FormControl variant="outlined">
                      <Select
                        id="organisationId"
                        name="organisationId"
                        placeholder="Organisation"
                        value={formik.values.organisationId}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("languageId", "");
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getorganization &&
                          getorganization?.map((value, i) => {
                            return (
                              <MenuItem value={value._id} key={i}>
                                {value.Name.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {formik.touched.organisationId &&
                  formik.errors.organisationId ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.organisationId}
                    </div>
                  ) : null}
                  <br />

                  <div className="inputField">
                    <InputLabel className="card-input-label">Name</InputLabel>
                    <TextField
                      onBlur={formik.handleBlur}
                      id="categoryName"
                      name="categoryName"
                      type="text"
                      variant="outlined"
                      className="input-div"
                      onChange={formik.handleChange}
                      value={formik.values.categoryName}
                      placeholder="Topic Name"
                    />
                  </div>
                  {formik.touched.categoryName && formik.errors.categoryName ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.categoryName}
                    </div>
                  ) : null}
                  <br />

                  <div className="inputField">
                    <InputLabel className="card-input-label">
                      Topic Image{" "}
                      <span className="helperText ">
                        *Note: Required image size is 80px X 80px.
                      </span>
                    </InputLabel>
                    <input
                      type="file"
                      name="categoryLogo"
                      id="categoryLogo"
                      // accept=".jpg, .jpeg, .png .webp"
                      className="hide_file"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "categoryLogo",
                          event.target.files[0],
                        );
                        setSelectedFile(event.target.files[0]);
                      }}
                    />
                  </div>
                  {formik.touched.categoryLogo && formik.errors.categoryLogo ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.categoryLogo}
                    </div>
                  ) : null}
                  <br />
                  <div
                    style={{ margin: "10px 0px" }}
                    hidden={
                      isEmpty(formik.values.categoryLogo)
                        ? true
                        : typeof formik.values.categoryLogo == "string" && false
                    }
                  >
                    <img
                      src={
                        typeof formik.values.categoryLogo == "string"
                          ? img_url + formik.values.categoryLogo
                          : preview
                          ? preview
                          : defaultImage
                      }
                      alt="Logo"
                      height={150}
                      width={200}
                    />
                  </div>
                  <div className="inputField">
                    <InputLabel className="card-input-label">Color</InputLabel>
                    <TextField
                      onBlur={formik.handleBlur}
                      id="ColourCode"
                      name="ColourCode"
                      type="color"
                      variant="outlined"
                      className="input-div"
                      onChange={formik.handleChange}
                      value={formik.values.ColourCode}
                      placeholder="Color"
                    />
                  </div>
                  {formik.touched.ColourCode && formik.errors.ColourCode ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.ColourCode}
                    </div>
                  ) : null}
                  <br />

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Language
                    </InputLabel>
                    <FormControl
                      variant="outlined"
                      style={{ maxWidth: "100%" }}
                    >
                      <Select
                        onBlur={formik.handleBlur}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="languageId"
                        value={formik.values.languageId}
                        onChange={formik.handleChange}
                        disabled={!formik.values.organisationId}
                        displayEmpty
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getLanguageData &&
                          getLanguageData?.map((value, i) => {
                            return (
                              <MenuItem value={value._id} key={i}>
                                {value.languageName
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {formik.touched.languageId && formik.errors.languageId ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.languageId}
                    </div>
                  ) : null}
                  <br />

                  <div className="inputField">
                    <InputLabel className="card-input-label">
                      Sequence
                    </InputLabel>
                    <TextField
                      onBlur={formik.handleBlur}
                      id="Sequence"
                      name="Sequence"
                      type="number"
                      variant="outlined"
                      className="input-div"
                      onChange={formik.handleChange}
                      value={formik.values.Sequence}
                    />
                  </div>
                  {formik.touched.Sequence && formik.errors.Sequence ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.Sequence}
                    </div>
                  ) : null}
                  <br />

                  {Object.keys(formik.errors).length > 0 && (
                    <div
                      style={{ color: "#D71E28" }}
                      className="validation-error"
                    >
                      * Please Fill All Required Fields.
                    </div>
                  )}
                  <div className="row">
                    <div className="card-footer">
                      <Box className="submit-cancel-box">
                        <Button
                          size="large"
                          variant="contained"
                          className="cat-subCancel-btn"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          className="cat-sub-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "blue",
                              }}
                            />
                          ) : (
                            button
                          )}
                        </Button>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Addcategory;
