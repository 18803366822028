import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";

import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OrgDashboard,
  adminDashboard,
} from "../../store/Reducers/DashboardReducer";

// const mainChartData = getMainChartData();
// const PieChartData = [
//   { name: "Group A", value: 400, color: "primary" },
//   { name: "Group B", value: 300, color: "secondary" },
//   { name: "Group C", value: 300, color: "warning" },
//   { name: "Group D", value: 200, color: "success" },
// ];

export default function Dashboard(props) {
  var classes = useStyles();
  const location = useLocation();
  var theme = useTheme();
  const dispatch = useDispatch();
  let role = useSelector(({ AuthSlice }) => AuthSlice.role);

  let userRole = location.pathname.includes("/admin")
    ? "Admin"
    : "Organisation";

  // let orgArray = [
  //   {
  //     name: "Total Organisation",
  //     total: adminDashboardData.TotalOrganization,
  //   },
  //   {
  //     name: "Total Issued Licenses",
  //     total: adminDashboardData.TotalOrganization,
  //   },
  //   {
  //     name: "Total Users",
  //     total: adminDashboardData.TotalOrganization,
  //   },
  //   {
  //     name: "Active Licenses",
  //     total: adminDashboardData.TotalOrganization,
  //   },
  // ];

  const { adminDashboardData, orgDashboardData } = useSelector(
    ({ DashboardSlice }) => DashboardSlice,
  );

  // let DashboardData =
  //   userRole == "Admin"
  //     ? {
  //         TotalOrganization: adminDashboardData.TotalOrganization,
  //         TotalIsscueLicenses: adminDashboardData.TotalIsscueLicenses,
  //         TotalUsers: adminDashboardData.TotalIsscueLicenses,
  //         ActiveLicenses: adminDashboardData.TotalIsscueLicenses,
  //       }
  //     : {
  //         TotalOrganization: orgDashboardData.TotalUsers,
  //         TotalIsscueLicenses: orgDashboardData.TotalLicenses,
  //         TotalUsers: orgDashboardData.ReamingLicenses,
  //         ActiveLicenses: orgDashboardData.ActiveUser,
  //       };

  // "TotalUsers": 0,
  //   "TotalLicenses": 2,
  //   "ActiveUser": 0,
  //   "ReamingLicenses": 2

  //   "TotalOrganization": 3,
  //   "TotalIsscueLicenses": 2,
  //   "TotalUsers": 3,
  //   "ActiveLicenses": 3

  // var [mainChartState, setMainChartState] = useState("monthly");
  const { userData } = useSelector(({ AuthSlice }) => AuthSlice);
  useEffect(() => {
    if (userRole == "Admin") {
      dispatch(adminDashboard());
    } else {
      dispatch(
        OrgDashboard({
          organisationId: userData._id,
        }),
      );
    }
  }, [dispatch]);

  return (
    <div className="cards-page">
      <PageTitle title="Dashboard" />
      <Grid container spacing={4} className="content-grid">
        <Grid item lg={3} md={4} sm={6} xs={12} className="small-box bg-info">
          <Widget
            title={
              userRole !== "Admin" ? "Total Licenses" : "Total Organisation"
            }
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <Box className={classes.visitsNumberContainer}>
              <Typography
                className={classes.paymenttext}
                style={{ fontWeight: "500", fontSize: "calc(28px)" }}
                size="xl"
                weight="medium"
              >
                {userRole == "Admin"
                  ? adminDashboardData?.TotalOrganization
                  : orgDashboardData.TotalLicenses}
              </Typography>
            </Box>
            {userRole == "Admin" && (
              <Link to={userRole == "Admin" ? "organisation" : "users"}>
                <div class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </div>
              </Link>
            )}
          </Widget>
        </Grid>
        <Grid
          item
          lg={3}
          md={8}
          sm={6}
          xs={12}
          className="small-box bg-success"
        >
          <Widget
            title={
              userRole !== "Admin"
                ? "Remaining Licenses"
                : "Total Issued Licenses"
            }
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography
                size="xl"
                weight="medium"
                style={{ fontWeight: "500", fontSize: "calc(28px)" }}
              >
                {userRole == "Admin"
                  ? adminDashboardData?.TotalIsscueLicenses
                  : orgDashboardData.ReamingLicenses}
              </Typography>
            </div>
            {/* <Link to="dashboard">
              <div class="small-box-footer">
                {" "}
                More info <i class="fas fa-arrow-circle-right"></i>
              </div>
            </Link> */}
          </Widget>
        </Grid>
        <Grid
          item
          lg={3}
          md={8}
          sm={6}
          xs={12}
          className="small-box bg-warning"
        >
          <Widget
            title={userRole !== "Admin" ? "Total Users" : "Total Users"}
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography
                size="xl"
                weight="medium"
                style={{ fontWeight: "500", fontSize: "calc(28px)" }}
              >
                {userRole == "Admin"
                  ? adminDashboardData?.TotalUsers
                  : orgDashboardData.TotalUsers}
              </Typography>
            </div>
            <Link to={userRole == "Admin" ? "users" : "users"}>
              <div class="small-box-footer">
                More info <i class="fas fa-arrow-circle-right"></i>
              </div>
            </Link>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className="small-box bg-danger">
          <Widget
            title={userRole !== "Admin" ? "Active User" : "Active Licenses"}
            upperTitle
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography
                size="xl"
                weight="medium"
                style={{ fontWeight: "500", fontSize: "calc(28px)" }}
              >
                {userRole == "Admin"
                  ? adminDashboardData?.ActiveLicenses
                  : orgDashboardData.ActiveUser}
              </Typography>
            </div>
            <Link
              to={userRole == "Admin" ? "activeusers" : "users/activeusers"}
            >
              <div class="small-box-footer">
                More info <i class="fas fa-arrow-circle-right"></i>
              </div>
            </Link>
          </Widget>
        </Grid>
        {role == "Admin" && (
          <Grid item lg={3} md={4} sm={6} xs={12} className="small-box bg-teal">
            <Widget title={"Topics"} upperTitle className={classes.card}>
              <div className={classes.visitsNumberContainer}>
                <Typography
                  size="xl"
                  weight="medium"
                  style={{ fontWeight: "500", fontSize: "calc(28px)" }}
                >
                  {adminDashboardData?.TotalTopic}
                </Typography>
              </div>
              <Link to={"languages"}>
                <div class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </div>
              </Link>
            </Widget>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Line Chart
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="warning" />
                    <Typography className={classes.mainChartLegentElement}>
                      Users
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Revenue
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="secondary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Listening Time
                    </Typography>
                  </div>
                </div>
                <Select
                  value={mainChartState}
                  onChange={(e) => setMainChartState(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <ComposedChart
                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                data={mainChartData}
              >
                <YAxis
                  ticks={[0, 2500, 5000, 7500]}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <XAxis
                  // dataKey="user"
                  tickFormatter={(i) => i + 1}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <Area
                  type="natural"
                  dataKey="user"
                  fill={theme.palette.background.light}
                  strokeWidth={0}
                  activeDot={false}
                />
                <Line
                  type="natural"
                  dataKey="revenue"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="natural"
                  dataKey="time"
                  stroke={theme.palette.secondary.main}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />

                <Line
                  type="linear"
                  dataKey="user"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid> */}
      </Grid>
    </div>
  );
}

function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();

  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  // var resultArray = [];
  // var user = getRandomData(31, 50, 20, 200, 300);
  // // console.log(tablet);
  // var revenue = getRandomData(31, 15, 75, 50, 10);
  // var time = getRandomData(31, 150, 200, 300, 400);
  var resultArray = [];
  var user = getRandomData(31, 150, 7500, 7500, 1500);
  // var name = ["monday", "Tuesday" , "Wednesday" ,"thursday" , "Friday" , "Saturday" , "Sunday"]

  // console.log(tablet);
  var revenue = getRandomData(31, 150, 7500, 7500, 1500);
  var time = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < user.length; i++) {
    // console.log("user",user);
    resultArray.push({
      user: user[i].value,
      // name: name[i].value,
      revenue: revenue[i].value,
      time: time[i].value,
    });
  }
  // console.log(resultArray);

  return resultArray;
}
