import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddNotificationAPI,
  DeleteNotificationAPI,
  GetNotificationAPI,
  GetNotificationByOrgAPI,
} from "../../utils/Services";

const initialState = {
  notificationData: [],
};

export const AddNotificationData = createAsyncThunk(
  "AddNotification",
  async (values, { dispatch }) => {
    try {
      const result = await AddNotificationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetNotifications = createAsyncThunk(
  "GetNotifications",
  async (values, { dispatch }) => {
    try {
      const result = await GetNotificationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetNotificationByOrg = createAsyncThunk(
  "GetNotificationByOrg",
  async (values, { dispatch }) => {
    try {
      const result = await GetNotificationByOrgAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteNotification = createAsyncThunk(
  "DeleteNotification",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteNotificationAPI({ id: values });
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(GetNotifications.fulfilled, (state, action) => {
      state.notificationData = action?.payload?.data;
    });

    builder.addCase(GetNotificationByOrg.fulfilled, (state, action) => {
      state.notificationData = action?.payload?.data;
    });
  },
});

export default NotificationSlice.reducer;
