export const SESSION = "USER";
export const TOKEN = "token";
export const ROLE = "ROLE";
export const MOODBOARD = "MYMOODBOARD";
export const COMMUNITYLINK = process.env.REACT_APP_CURRENT_COMMUNITY_ADDRESS;

export const AlertEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Info: "INFO",
  Warning: "WARNING",
};
