import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  OrganisationSchema,
  UserManualSchema,
  UserSchema,
} from "../../utils/Schema";
import styles from "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import {
  AddUserData,
  EditManualUser,
  EditUser,
  ManualUser,
} from "../../store/Reducers/UserReducer";
// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { OrgDashboard } from "../../store/Reducers/DashboardReducer";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddUser(props) {
  let heading = "User";
  let button = "Submit";

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let anotherData = location?.state?.userData;
  let isEdit = history.location.pathname.includes("edituser") ? true : false;

  const token = useSelector(({ AuthSlice }) => AuthSlice.token);
  const [value, setValue] = React.useState(
    isEdit ? (location?.state?.userData?.IsSendMail ? 0 : 1) : 0,
  );
  const [isManual, setIsManual] = useState(false);
  const { userData } = useSelector(({ AuthSlice }) => AuthSlice);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let manualData = {
    Name: "",
    Email: "",
    PhoneNumber: "",
    Reference: "",
    Status: true,
  };

  let editData = isEdit && {
    Name: anotherData.Name,
    Email: anotherData.Email,
    PhoneNumber: anotherData.PhoneNumber,
    Reference: anotherData.Reference,
    Status: anotherData.Status,
    _id: anotherData._id,
    IsSendMail: anotherData.IsSendMail,
  };

  let intialData = !isManual
    ? manualData
    : Object.assign(manualData, { Password: "", ConfirmPassword: "" });

  const formik = useFormik({
    initialValues: isEdit ? editData : intialData,
    validationSchema: isManual && !isEdit ? UserManualSchema : UserSchema,
    enableReinitialize: true,
    onSubmit: async (value, { resetForm }) => {
      setLoading(true);

      const header = {
        headers: {
          "x-access-token": token,
        },
      };

      let data = isEdit
        ? {
            _id: value._id,
            Name: value.Name,
            PhoneNumber: value.PhoneNumber,
            Reference: value.Reference,
            NoOfLicence: value.NoOfLicence,
            Status: value.Status,
            IsSendMail: value.IsSendMail,
            organisationId: value.organisationId,
          }
        : {
            ...value,
            IsSendMail: isManual ? false : true,
            organisationId: userData._id,
          };

      if (isManual) {
        if (isEdit) {
          dispatch(EditManualUser(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        } else {
          dispatch(ManualUser(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        }
      } else {
        if (isEdit) {
          dispatch(EditUser(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        } else {
          dispatch(AddUserData(data, header)).then((response) => {
            if (response?.payload?.code === 200) {
              history.goBack();
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        }
      }
      dispatch(
        OrgDashboard({
          organisationId: value._id,
        }),
      );

      // }
    },
  });

  useEffect(() => {
    // if (isEdit) {
    //   formik.setValues({
    //     Name: anotherData.Name,
    //     Email: anotherData.Email,
    //     PhoneNumber: anotherData.PhoneNumber,
    //     Status: anotherData.Status,
    //     Reference: anotherData.Reference,
    //     _id: anotherData._id,
    //     IsSendMail: anotherData.IsSendMail,
    //   });
    // }
    dispatch(
      OrgDashboard({
        organisationId: userData._id,
      }),
    );
  }, []);

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <PageTitle title={heading} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <PageTitle title={heading} /> */}
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">
                    {isEdit ? "Edit" : "Create"} User
                  </h3>
                </div>

                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="organization-tab"
                    >
                      <Tab
                        label="Send Email"
                        disabled={isEdit && !formik.values.IsSendMail}
                        onClick={() => {
                          setIsManual(false);
                        }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Manual"
                        disabled={isEdit && formik.values.IsSendMail}
                        onClick={() => {
                          setIsManual(true);
                        }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    {!isEdit && (
                      <p className="note-color">
                        Note :- On Email Tab Will Get Password On Your Email
                        Address.
                      </p>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="name"
                              name="Name"
                              // label="Name"
                              variant="outlined"
                              value={formik.values.Name}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched?.Name && formik.errors?.Name ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Name}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Email
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Email"
                              name="Email"
                              // label="Email"
                              variant="outlined"
                              value={formik.values?.Email}
                              onChange={formik.handleChange}
                              disabled={isEdit ? true : false}
                            />
                          </FormGroup>
                          {formik.touched?.Email && formik.errors?.Email ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Email}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            Phone Number
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="phoneNumber"
                              name="PhoneNumber"
                              // label="Phone Number"
                              variant="outlined"
                              type="number"
                              value={formik.values?.PhoneNumber}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched?.PhoneNumber &&
                          formik.errors?.PhoneNumber ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.PhoneNumber}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Reference
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Reference"
                              name="Reference"
                              // label="Reference"
                              variant="outlined"
                              value={formik.values?.Reference}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched?.Reference &&
                          formik.errors?.Reference ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Reference}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Status
                        </InputLabel>
                        <FormControl variant="outlined">
                          <Select
                            onBlur={formik.handleBlur}
                            // className="input-bottom-space"
                            labelId="demo-select-smalls"
                            id="status"
                            name="Status"
                            value={formik.values?.Status}
                            // label="Select Status"
                            onChange={formik.handleChange}
                            placeholder="Status"
                          >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>InActive</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                      {formik.touched?.Status && formik.errors?.Status ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors.Status}
                        </div>
                      ) : null}
                      <br />

                      <div className="row">
                        <div className="card-footer">
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                              onClick={() => {
                                history.goBack();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  color="primary"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "blue",
                                  }}
                                />
                              ) : (
                                button
                              )}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {!isEdit && (
                      <p className="note-color">
                        Note :- On Manual Tab You Need Set Passord Your Self.
                      </p>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Name
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="name"
                              name="Name"
                              // label="Name"
                              variant="outlined"
                              value={formik.values.Name}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched?.Name && formik.errors?.Name ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Name}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Email
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Email"
                              name="Email"
                              // label="Email"
                              variant="outlined"
                              value={formik.values?.Email}
                              onChange={formik.handleChange}
                              disabled={isEdit ? true : false}
                            />
                          </FormGroup>
                          {formik.touched?.Email && formik.errors?.Email ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Email}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <InputLabel className="card-input-label">
                            Phone Number
                          </InputLabel>
                          <FormGroup>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="phoneNumber"
                              name="PhoneNumber"
                              // label="Phone Number"
                              variant="outlined"
                              value={formik.values?.PhoneNumber}
                              onChange={formik.handleChange}
                              type="number"
                            />
                          </FormGroup>
                          {formik.touched?.PhoneNumber &&
                          formik.errors?.PhoneNumber ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.PhoneNumber}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormGroup>
                            <InputLabel className="card-input-label">
                              Reference
                            </InputLabel>
                            <TextField
                              onBlur={formik.handleBlur}
                              id="Reference"
                              name="Reference"
                              // label="Reference"
                              variant="outlined"
                              value={formik.values?.Reference}
                              onChange={formik.handleChange}
                            />
                          </FormGroup>
                          {formik.touched?.Reference &&
                          formik.errors?.Reference ? (
                            <div
                              style={{ color: "#D71E28" }}
                              className="validation-error"
                            >
                              {formik.errors.Reference}
                            </div>
                          ) : null}
                          <br />
                        </Grid>
                      </Grid>
                      {!isEdit && (
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <InputLabel className="card-input-label">
                              Password
                            </InputLabel>
                            <FormGroup>
                              <TextField
                                onBlur={formik.handleBlur}
                                id="password"
                                name="Password"
                                // label="Password"
                                variant="outlined"
                                value={formik.values?.Password}
                                onChange={formik.handleChange}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormGroup>
                            {formik.touched?.Password &&
                            formik.errors?.Password ? (
                              <div
                                style={{ color: "#D71E28" }}
                                className="validation-error"
                              >
                                {formik.errors.Password}
                              </div>
                            ) : null}
                            <br />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <FormGroup>
                              <InputLabel className="card-input-label">
                                Confirm Password
                              </InputLabel>
                              <TextField
                                onBlur={formik.handleBlur}
                                id="Confirm Password"
                                name="ConfirmPassword"
                                // label="Confirm Password"
                                variant="outlined"
                                value={formik.values?.ConfirmPassword}
                                onChange={formik.handleChange}
                                type={showPassword1 ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword1 ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormGroup>
                            {formik.touched?.ConfirmPassword &&
                            formik.errors?.ConfirmPassword ? (
                              <div
                                style={{ color: "#D71E28" }}
                                className="validation-error"
                              >
                                {formik.errors.ConfirmPassword}
                              </div>
                            ) : null}
                            <br />
                          </Grid>
                        </Grid>
                      )}
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Status
                        </InputLabel>
                        <FormControl variant="outlined">
                          <Select
                            onBlur={formik.handleBlur}
                            // className="input-bottom-space"
                            labelId="demo-select-smalls"
                            id="status"
                            name="Status"
                            value={formik.values?.Status}
                            // label="Select Status"
                            onChange={formik.handleChange}
                            placeholder="Status"
                          >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>InActive</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                      {formik.touched?.Status && formik.errors?.Status ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors.Status}
                        </div>
                      ) : null}
                      <br />

                      <div className="row">
                        <div className="card-footer">
                          <Box className="submit-cancel-box">
                            <Button
                              size="large"
                              variant="contained"
                              className="cat-subCancel-btn"
                              type="button"
                              onClick={() => {
                                history.goBack();
                              }}
                            >
                              Cancel
                            </Button>

                            <Button
                              size="large"
                              variant="contained"
                              className="cat-sub-btn"
                              type="submit"
                              value={button}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  color="primary"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "blue",
                                  }}
                                />
                              ) : (
                                button
                              )}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                </Box>
              </Box>

              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
