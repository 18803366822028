import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LayoutSlice";
import {
  AddLanguageAPI,
  GetLanguageAPI,
  EditLanguageAPI,
  DeleteLanguageAPI,
  GetLanguageCountsAPI,
  GetLangByOrgAPI,
} from "../../utils/Services";

const initialState = {
  getLanguageData: [],
  editLanguageData: "",
  languageCount: [],
  selectedLanguage: "All",
};

export const AddLanguageData = createAsyncThunk(
  "AddLanguageData",
  async (values, { dispatch }) => {
    try {
      const result = await AddLanguageAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetLanguage = createAsyncThunk(
  "GetLanguage",
  async (values, { dispatch }) => {
    try {
      const result = await GetLanguageAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditLanguage = createAsyncThunk(
  "EditLanguage",
  async (values, { dispatch }) => {
    try {
      const result = await EditLanguageAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteLanguage = createAsyncThunk(
  "DeleteLanguage",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteLanguageAPI({ id: values });
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetLanguageCounts = createAsyncThunk(
  "GetLanguageCounts",
  async (values, { dispatch }) => {
    try {
      const result = await GetLanguageCountsAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetLangByOrg = createAsyncThunk(
  "GetLangByOrg",
  async (values, { dispatch }) => {
    try {
      const result = await GetLangByOrgAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const LanguageSlice = createSlice({
  name: "LanguageSlice",
  initialState,
  reducers: {
    setEditLanguagedata(state, action) {
      state.editLanguageData = action.payload;
    },
    setSelectedLanguage(state, action) {
      state.selectedLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetLanguage.fulfilled, (state, action) => {
      state.getLanguageData = action?.payload?.data;
    });
    builder.addCase(GetLanguageCounts.fulfilled, (state, action) => {
      state.languageCount = action?.payload?.data;
    });
    builder.addCase(GetLangByOrg.fulfilled, (state, action) => {
      state.getLanguageData = action?.payload?.data;
    });
  },
});

export const {
  setEditLanguagedata,
  setSelectedLanguage,
} = LanguageSlice.actions;

export default LanguageSlice.reducer;
