import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLanguageCounts,
  setSelectedLanguage,
} from "../../store/Reducers/LanguageReducer";
import { GetCategoryByLangId } from "../../store/Reducers/CategoryReducer";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";

export default function LanguageCards() {
  var classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { languageCount } = useSelector(({ LanguageSlice }) => LanguageSlice);

  useEffect(() => {
    toggleLoading(true);
    dispatch(GetLanguageCounts()).then((res) => toggleLoading(false));
  }, [dispatch]);

  const [loading, toggleLoading] = useState(false);
  return (
    <div className="cards-page">
      <PageTitle title="Languages" />
      {loading ? (
        <div className="loader-table">
          <Lottie
            options={{
              autoplay: true,
              loop: true,
              animationData: LoaderJson,
            }}
            height={150}
            width={150}
          />
        </div>
      ) : (
        <Grid container spacing={4} className="content-grid">
          {languageCount.map((data, i) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="small-box bg-secondary"
              key={i}
            >
              <Widget
                title={data.languageName}
                upperTitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
              >
                <Box className={classes.visitsNumberContainer}>
                  <Typography
                    className={classes.paymenttext}
                    style={{ fontWeight: "500", fontSize: "calc(28px)" }}
                    size="xl"
                    weight="medium"
                  >
                    {data.topicCount}
                  </Typography>
                </Box>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setSelectedLanguage(data.languageId));
                    history.push({
                      pathname: "master/topic",
                      state: {
                        isAll: false,
                      },
                    });
                  }}
                  // to={"master/topic"}
                >
                  <div class="small-box-footer ">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </div>
                </span>
              </Widget>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
