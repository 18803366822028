import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { setLoading } from "./LayoutSlice";
import {
  AddOrganizationAPI,
  GetOrganizationAPI,
  EditOrganizationAPI,
  DeleteOrganizationAPI,
  ManualMailAPI,
  AssignAPI,
  EditManualMailAPI,
  AdminBulkUploadAPI,
  AdminExportAPI,
  GetAllAssignAPI,
  GetAllOrgDetailAPI,
} from "../../utils/Services";

const initialState = {
  getorganization: [],
  langOrg: [],
  editOrganizationData: "",
  getAllAssign: [],
  allOrgDetail: {},
  selectedOrg: "All",
};

export const AddOrganizationData = createAsyncThunk(
  "AddOrganizationData",
  async (values, { dispatch }) => {
    try {
      const result = await AddOrganizationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);
export const ManualMailData = createAsyncThunk(
  "ManualMailData",
  async (values, { dispatch }) => {
    try {
      const result = await ManualMailAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const AssignData = createAsyncThunk(
  "AssignData",
  async (values, { dispatch }) => {
    try {
      const result = await AssignAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetAllAssign = createAsyncThunk(
  "GetAllAssign",
  async (values, { dispatch }) => {
    try {
      const result = await GetAllAssignAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);
export const GetOrganization = createAsyncThunk(
  "GetOrganization",
  async (values, { dispatch }) => {
    try {
      const result = await GetOrganizationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditOrganization = createAsyncThunk(
  "EditOrganization",
  async (values, { dispatch }) => {
    try {
      const result = await EditOrganizationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        dispatch(GetOrganization());
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const DeleteOrganization = createAsyncThunk(
  "DeleteOrganization",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteOrganizationAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const EditManualMail = createAsyncThunk(
  "EditManualMail",
  async (values, { dispatch }) => {
    try {
      const result = await EditManualMailAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        dispatch(GetOrganization());
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const AdminBulkUpload = createAsyncThunk(
  "AdminBulkUpload",
  async (values, { dispatch }) => {
    try {
      const result = await AdminBulkUploadAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const AdminExport = createAsyncThunk(
  "AdminExport",
  async (values, { dispatch }) => {
    try {
      const result = await AdminExportAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const GetAllOrgDetails = createAsyncThunk(
  "GetAllOrgDetails",
  async (values, { dispatch }) => {
    try {
      const result = await GetAllOrgDetailAPI(values);
      if (result.status == 400) {
        return result.data;
      } else {
        return result;
      }
      if (!result?.hasError) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      return error;
    }
  },
);

export const OrganizationSlice = createSlice({
  name: "OrganizationSlice",
  initialState,
  reducers: {
    setEditOrganizationdata(state, action) {
      state.editOrganizationData = action.payload;
    },
    setSelectedOrg(state, action) {
      state.selectedOrg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetOrganization.fulfilled, (state, action) => {
      state.getorganization = action?.payload?.data;
    });
    builder.addCase(GetAllAssign.fulfilled, (state, action) => {
      state.getAllAssign = action?.payload?.data;
    });
    builder.addCase(GetAllOrgDetails.fulfilled, (state, action) => {
      state.allOrgDetail = action?.payload?.data;
    });
  },
});
export const {
  setEditOrganizationdata,
  setSelectedOrg,
} = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
