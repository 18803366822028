import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { LanguageExistSchema, LanguageSchema } from "../../utils/Schema";
import { toast } from "react-toastify";
import {
  AddLanguageData,
  EditLanguage,
  GetLangByOrg,
  GetLanguage,
  setSelectedLanguage,
} from "../../store/Reducers/LanguageReducer";
import { useDispatch, useSelector } from "react-redux";
// components
import PageTitle from "../../components/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import {
  GetCategory,
  GetCategoryByLangId,
} from "../../store/Reducers/CategoryReducer";
import "./styles.css";
import { useState } from "react";
import Maincategory from "../category/Category";
import { img_url } from "../../utils/constant";
import defaultImage from "../../images/defaultTopic.png";
import MUIDataTable from "mui-datatables";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";
import OrganisationSelect from "../../components/Common/OrganisationSelect";
import {
  GetOrganization,
  setSelectedOrg,
} from "../../store/Reducers/OrganizationReducer";

export default function Addlanguage() {
  let heading = "Language";

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  let isEdit = history.location.pathname.includes("editlanguage")
    ? true
    : false;

  var { editLanguageData } = useSelector(({ LanguageSlice }) => LanguageSlice);
  if (!editLanguageData) {
    editLanguageData = location?.state?.data;
  }

  const [org, setOrg] = useState("All");

  const { getorganization } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  useEffect(() => {
    // dispatch(GetCategory());
    dispatch(GetLanguage());
    dispatch(GetOrganization());
    dispatch(setSelectedOrg("All"));
  }, []);

  const formik = useFormik({
    initialValues: {
      languageName: "",
      ShortName: "",
      Sequence: "",
      organisationId: "",
      IsExisting: false,
      Languages: [],
    },
    validationSchema: !checked ? LanguageSchema : LanguageExistSchema,

    onSubmit: async (value) => {
      setLoading(true);

      if (isEdit) {
        delete value.IsExisting;
        dispatch(EditLanguage(value)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
        });
      } else {
        value.Languages = checkedArray;
        dispatch(AddLanguageData(value)).then((response) => {
          if (response?.payload?.code === 200) {
            history.goBack();

            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
          setLoading(false);
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        _id: editLanguageData?._id,
        languageName: editLanguageData?.languageName,
        ShortName: editLanguageData?.ShortName,
        Sequence: editLanguageData?.Sequence,
        organisationId: editLanguageData?.organisationId,
      });
    }
  }, [dispatch, editLanguageData]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  // const { getCategoryData } = useSelector(({ CategorySlice }) => CategorySlice);
  const { selectedLanguage, getLanguageData } = useSelector(
    ({ LanguageSlice }) => LanguageSlice,
  );

  // useEffect(() => {
  //   setLoading1(true);
  //   if (selectedLanguage == "All") {
  //     dispatch(GetCategory()).then((res) => setLoading1(false));
  //   } else {
  //     dispatch(
  //       GetCategoryByLangId({
  //         languageId: selectedLanguage,
  //       }),
  //     ).then((res) => setLoading1(false));
  //     setCheckedArray([]);
  //   }
  // }, [selectedLanguage]);

  const changeCategory = (e) => {
    setOrg(e.target.value);
    // dispatch(setSelectedOrg(e.target.value));
  };

  const [checkedArray, setCheckedArray] = useState([]);

  const CustomCheckbox = ({ value, tableMeta }) => {
    const rowData = getLanguageData.find((x) => x._id === tableMeta.rowData[0]);

    const handleChange = (e) => {
      let { checked } = e.target;
      if (checked) {
        setCheckedArray((prev) => [...prev, rowData._id]);
      } else {
        setCheckedArray((prev) => prev.filter((data) => data !== rowData._id));
      }
    };

    return (
      <div className="custom-select">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={handleChange}
                checked={
                  checkedArray.find((data) => data == rowData._id)
                    ? true
                    : false
                }
              />
            }
          />
        </FormGroup>
      </div>
    );
  };

  useEffect(() => {
    if (checkedArray.length > 0) {
      toggleSelectedAll(true);
      toggleIndeterminate(true);
    } else {
      toggleSelectedAll(false);
      toggleIndeterminate(false);
    }

    if (checkedArray.length == getLanguageData.length) {
      toggleIndeterminate(false);
    }
  }, [checkedArray]);

  const selectAllData = () => {
    if (!isSelectedAll) {
      let tempArray = getLanguageData.map((data) => data._id);
      setCheckedArray(tempArray);
    } else {
      setCheckedArray([]);
    }
  };

  const [isSelectedAll, toggleSelectedAll] = useState(false);
  const [isIndeterminate, toggleIndeterminate] = useState(false);

  // const MemoizedComponent = memo(CustomCheckbox);

  useEffect(() => {
    setChecked(formik.values.IsExisting);
  }, [formik.values.IsExisting]);

  useEffect(() => {
    setLoading1(true);
    setCheckedArray([]);

    if (org == "All") {
      dispatch(GetLanguage()).then((res) => setLoading1(false));
    } else {
      setTimeout(() => {
        dispatch(
          GetLangByOrg({
            organisationId: org,
          }),
        ).then((res) => setLoading1(false));
      }, 200);
    }
  }, [org]);

  return (
    <>
      <PageTitle title={heading} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={12}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">
                    {isEdit ? "Edit" : "Create"} Language
                  </h3>
                </div>
                <br />
                <form onSubmit={formik.handleSubmit}>
                  {/* <OrganisationSelect
                    getorganization={getorganization}
                    name="organisationId"
                    value={formik.values.organisationId}
                    handleChange={formik.handleChange}
                    formik={formik}
                  /> */}

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Organisation
                    </InputLabel>
                    <FormControl variant="outlined">
                      <Select
                        id="organisationId"
                        name="organisationId"
                        placeholder="Organisation"
                        value={formik.values.organisationId}
                        onChange={formik.handleChange}
                        displayEmpty
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getorganization &&
                          getorganization?.map((value) => {
                            return (
                              <MenuItem value={value._id}>
                                {value.Name.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {formik.touched.organisationId &&
                  formik.errors.organisationId ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.organisationId}
                    </div>
                  ) : null}
                  <br />

                  {!checked && (
                    <>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Language Name
                        </InputLabel>
                        <TextField
                          id="languageName"
                          name="languageName"
                          variant="outlined"
                          value={formik.values.languageName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormGroup>
                      {formik.touched.languageName &&
                      formik.errors.languageName ? (
                        <div style={{ color: "#D71E28" }}>
                          {formik.errors.languageName}
                        </div>
                      ) : null}
                      <br />

                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Short Language Name
                        </InputLabel>
                        <TextField
                          id="ShortName"
                          name="ShortName"
                          variant="outlined"
                          value={formik.values.ShortName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormGroup>
                      {formik.touched.ShortName && formik.errors.ShortName ? (
                        <div style={{ color: "#D71E28" }}>
                          {formik.errors.ShortName}
                        </div>
                      ) : null}
                      <br />

                      <div className="inputField">
                        <InputLabel className="card-input-label">
                          Sequence
                        </InputLabel>
                        <TextField
                          id="Sequence"
                          name="Sequence"
                          type="number"
                          variant="outlined"
                          className="input-div"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Sequence}
                        />
                      </div>
                      {formik.touched.Sequence && formik.errors.Sequence ? (
                        <div style={{ color: "#D71E28" }}>
                          {formik.errors.Sequence}
                        </div>
                      ) : null}
                      <br />
                    </>
                  )}

                  {!isEdit && (
                    <>
                      <div>
                        <FormGroup>
                          <FormControlLabel
                            style={{ width: "max-content" }}
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "IsExisting",
                                    e.target.checked,
                                  );
                                  // setChecked(e.target.checked);
                                }}
                                name="IsExisting"
                                checked={formik.values.IsExisting}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Choose From Existing"
                          />
                        </FormGroup>
                        <br />
                        {checked && (
                          <div className="inputField topic-table">
                            {/* <InputLabel className="card-input-label">
                            Topics
                          </InputLabel> */}
                            {loading1 ? (
                              <div className="loader-table">
                                <Lottie
                                  options={{
                                    autoplay: true,
                                    loop: true,
                                    animationData: LoaderJson,
                                  }}
                                  height={150}
                                  width={150}
                                />
                              </div>
                            ) : (
                              <MUIDataTable
                                title="Language List"
                                data={getLanguageData}
                                columns={[
                                  {
                                    name: "_id",
                                    label: "Id.",

                                    options: {
                                      sort: true,
                                      display: false,
                                      download: false,
                                    },
                                  },
                                  {
                                    name: "select",
                                    label: "Select",
                                    options: {
                                      filter: false,
                                      sort: false,
                                      customHeadLabelRender: () => {
                                        return (
                                          <div>
                                            {/* <Button
                                            className="toolbar-btn"
                                            variant="contained"
                                            color="primary"
                                            onClick={selectAllData}
                                            style={{ alignSelf: "center" }}
                                            // onClick={() => handleImportClickOpen()}
                                          >
                                            <span>
                                              {!isSelectedAll
                                                ? "Select All"
                                                : "Deselect All"}
                                            </span>
                                          </Button> */}
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={isSelectedAll}
                                                  indeterminate={
                                                    isIndeterminate
                                                  }
                                                  color="primary"
                                                  sx={{
                                                    "& .MuiSvgIcon-root": {
                                                      fontSize: 28,
                                                    },
                                                  }}
                                                  onChange={selectAllData}
                                                />
                                              }
                                            />
                                          </div>
                                        );
                                      },
                                      customBodyRender: (value, tableMeta) => (
                                        <CustomCheckbox
                                          value={value}
                                          tableMeta={tableMeta}
                                        />
                                      ),
                                    },
                                  },
                                  {
                                    name: "languageName",
                                    label: "Name",
                                    options: {
                                      filter: true,
                                      sort: true,
                                      sortFilterList: true,
                                      customBodyRender: (
                                        value,
                                        tableMeta,
                                        update,
                                      ) => {
                                        return (
                                          <>
                                            <span>{value}</span>
                                          </>
                                        );
                                      },
                                    },
                                  },
                                  {
                                    name: "ShortName",
                                    label: "Short Name",
                                    options: {
                                      filter: true,
                                      sort: true,
                                      sortFilterList: true,

                                      customBodyRender: (
                                        value,
                                        tableMeta,
                                        update,
                                      ) => {
                                        return (
                                          <>
                                            <span>{value}</span>
                                          </>
                                        );
                                      },
                                    },
                                  },

                                  {
                                    name: "Sequence",
                                    label: "Sequence",
                                  },

                                  {
                                    name: "topicCount",
                                    label: "Topic Count",
                                  },
                                ]}
                                options={{
                                  filterType: "",
                                  searchOpen: false,
                                  search: false,
                                  filter: false,
                                  print: false,
                                  download: false,
                                  viewColumns: false,
                                  selectableRows: "none",
                                  responsive: "standard",
                                  customToolbar: () => (
                                    <div className="custom-toolbar-dropdown">
                                      <FormGroup>
                                        <FormControl variant="outlined">
                                          <Select
                                            // onBlur={formik.handleBlur}
                                            labelId="demo-select-smalls"
                                            id="organisationId"
                                            name="organisationId"
                                            value={org}
                                            onChange={changeCategory}
                                            placeholder="Organisation"
                                          >
                                            <MenuItem value={"All"}>
                                              All
                                            </MenuItem>
                                            {getorganization.map((data) => (
                                              <MenuItem value={data._id}>
                                                {data?.Name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </FormGroup>
                                      {/* <Button
                                      className="toolbar-btn"
                                      variant="contained"
                                      color="primary"
                                      onClick={selectAllData}
                                      // onClick={() => handleImportClickOpen()}
                                    >
                                      <span>
                                        {!isSelectedAll
                                          ? "Select All"
                                          : "Deselect All"}
                                      </span>
                                    </Button> */}
                                    </div>
                                  ),
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <br />
                    </>
                  )}
                  {Object.keys(formik.errors).length > 0 && (
                    <div
                      style={{ color: "#D71E28" }}
                      className="validation-error"
                    >
                      * Please Fill All Required Fields.
                    </div>
                  )}
                  <div className="row">
                    <div className="card-footer">
                      <Box className="submit-cancel-box">
                        <Button
                          size="large"
                          variant="contained"
                          className="cat-subCancel-btn"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          className="cat-sub-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "blue",
                              }}
                            />
                          ) : (
                            <>Submit</>
                          )}
                        </Button>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
