import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import { EditProfileSchema } from "../../utils/Schema";
// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import {
  adminEditProfile,
  adminProfileData,
} from "../../store/Reducers/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { img_url } from "../../utils/constant";
import { isEmpty } from "../../utils/Functions";
import defaultImage from "../../images/defaultTopic.png";

export default function EditProfile() {
  let button = "Submit";
  const dispatch = useDispatch();
  const history = useHistory();

  const { editProfileData, profileData } = useSelector(
    ({ ProfileSlice }) => ProfileSlice,
  );
  const { userData, role } = useSelector(({ AuthSlice }) => AuthSlice);
  const isAdmin = role == "Admin" ? true : false;

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      PhoneNumber: "",
      ProfileImage: "",
    },
    validationSchema: EditProfileSchema,
    onSubmit: async (value) => {
      setLoading(true);
      // console.log("value", value);
      let data = new FormData();

      data.append("_id", value?._id);
      data.append("ProfileImage", value?.ProfileImage);
      data.append("Name", value?.Name);
      data.append("PhoneNumber", value?.PhoneNumber);

      data.append("HeaderText", value?.HeaderText);
      data.append("HeaderLogo1", files[1]?.data ?? value?.HeaderLogo1);
      data.append("HeaderLogo2", files[2]?.data ?? value?.HeaderLogo2);
      data.append("HeaderLogo3", files[3]?.data ?? value?.HeaderLogo3);
      data.append("ColourCode", value?.ColourCode);

      dispatch(adminEditProfile(data)).then((response) => {
        if (response?.payload?.code === 200) {
          // history.push("dashboard");
          dispatch(
            adminProfileData({
              _id: userData?._id,
            }),
          );
          setLoading(false);
          toast.success(response?.payload?.message);
        } else {
          setLoading(false);
          toast.error(response?.payload?.message);
        }
      });
      // }
    },
  });
  useEffect(() => {
    formik.setValues(profileData);
    // if (profileData) {
    //   formik.setFieldValue("_id", profileData?._id);
    //   formik.setFieldValue("FirstName", profileData?.FirstName);
    //   formik.setFieldValue("LastName", profileData?.LastName);
    //   formik.setFieldValue("PhoneNumber", profileData?.PhoneNumber);
    //   formik.setFieldValue("ProfileImage", profileData?.ProfileImage);
    // }
  }, [dispatch, profileData]);

  const [selectedFile, setSelectedFile] = useState();

  const [logos, setLogos] = useState([]);

  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    // console.log(selectedFile);
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const [isDashboard, toggleDashboard] = useState(
    role == "Admin" ? true : false,
  );

  useEffect(() => {
    if (profileData?.IsLogo) {
      toggleDashboard(true);
    }
  }, [profileData]);

  const [files, setFiles] = useState({});

  function onFileUpload(event) {
    event.preventDefault();
    let id = event.target.id;
    let file_reader = new FileReader();
    let file = event.target.files[0];

    file_reader.onload = () => {
      setFiles((prev) => ({
        ...prev,
        [id]: {
          file_url: file_reader.result,
          data: file,
        },
      }));
    };
    file_reader.readAsDataURL(file);
  }

  // useEffect(() => {
  //   console.log(files);
  // }, [files]);

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <PageTitle title="Profile" />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={12}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">Edit Profile</h3>
                </div>
                <br />
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Name
                        </InputLabel>
                        <TextField
                          onBlur={formik.handleBlur}
                          id="Name"
                          name="Name"
                          // label="First Name"
                          variant="outlined"
                          value={formik.values?.Name}
                          onChange={formik.handleChange}
                          disabled={!isAdmin}
                        />
                      </FormGroup>
                      {formik.touched?.Name && formik.errors?.Name ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors?.Name}
                        </div>
                      ) : null}
                      <br />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Email
                        </InputLabel>
                        <TextField
                          onBlur={formik.handleBlur}
                          id="email"
                          name="Email"
                          // label="Email"
                          variant="outlined"
                          value={formik.values?.Email}
                          onChange={formik.handleChange}
                          disabled
                        />
                      </FormGroup>
                      {formik.touched?.Email && formik.errors?.Email ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors?.Email}
                        </div>
                      ) : null}
                      <br />
                    </Grid>
                    {/* <div className="col">
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Last Name
                        </InputLabel>
                        <TextField
                        onBlur={formik.handleBlur}
                          id="lastName"
                          name="LastName"
                          label="Last Name"
                          variant="outlined"
                          value={formik.values?.LastName}
                          onChange={formik.handleChange}
                        />
                      </FormGroup>
                      {formik.touched.LastName && formik.errors.LastName ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors.LastName}
                        </div>
                      ) : null}
                      <br />
                    </div> */}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          Phone Number
                        </InputLabel>
                        <TextField
                          onBlur={formik.handleBlur}
                          id="phoneNumber"
                          name="PhoneNumber"
                          // label="Phone Number"
                          variant="outlined"
                          value={formik.values?.PhoneNumber}
                          onChange={formik.handleChange}
                          type="number"
                          disabled={!isAdmin}
                        />
                      </FormGroup>
                      {formik.touched.PhoneNumber &&
                      formik.errors.PhoneNumber ? (
                        <div
                          style={{ color: "#D71E28" }}
                          className="validation-error"
                        >
                          {formik.errors.PhoneNumber}
                        </div>
                      ) : null}
                      <br />
                    </Grid>
                  </Grid>

                  {isAdmin && (
                    <>
                      <FormGroup>
                        <InputLabel className="card-input-label">
                          {role == "Admin" ? "Login Logo" : "Profile Picture"}
                          <span className="helperText ml-2">
                            {role == "Admin"
                              ? " *Note: Required logo size is 200px X 200px."
                              : " *Note: Required image size is 30px X 30px."}
                          </span>
                        </InputLabel>
                        <FormControl style={{ width: "max-content" }}>
                          <input
                            type="file"
                            name="ProfileImage"
                            id="ProfileImage"
                            accept="image/*"
                            className="hide_file"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ProfileImage",
                                event.target.files[0],
                              );
                              setSelectedFile(event.target.files[0]);
                            }}
                          />
                        </FormControl>
                      </FormGroup>

                      <div
                        style={{ margin: "10px 0px" }}
                        hidden={
                          isEmpty(formik.values?.ProfileImage)
                            ? true
                            : typeof formik.values?.ProfileImage == "string" &&
                              false
                        }
                      >
                        <img
                          src={
                            typeof formik.values?.ProfileImage == "string"
                              ? img_url + formik.values?.ProfileImage
                              : preview
                              ? preview
                              : defaultImage
                          }
                          height={150}
                          width={200}
                        />
                      </div>
                    </>
                  )}

                  {/* <img src="http://67.205.148.222/isea-backend-phase2/public/files/category-1682487844566.jpg" /> */}
                  <br />

                  <div>
                    <div className="card-header">
                      <h3 className="card-title">Header</h3>
                    </div>
                    <Grid container spacing={3} style={{ marginTop: "20px" }}>
                      <Grid item md={6} xs={12}>
                        <FormGroup>
                          <InputLabel className="card-input-label">
                            Name
                          </InputLabel>
                          <TextField
                            onBlur={formik.handleBlur}
                            id="HeaderText"
                            name="HeaderText"
                            // label="Phone Number"
                            variant="outlined"
                            value={formik.values?.HeaderText}
                            onChange={formik.handleChange}
                            disabled={!isAdmin}
                          />
                        </FormGroup>
                        {formik.touched?.HeaderText &&
                        formik.errors?.HeaderText ? (
                          <div
                            style={{ color: "#D71E28" }}
                            className="validation-error"
                          >
                            {formik.errors?.HeaderText}
                          </div>
                        ) : null}
                        <br />
                      </Grid>
                      <Grid item md={3} xs={12} className="inputField">
                        <InputLabel className="card-input-label">
                          Color
                        </InputLabel>
                        <TextField
                          onBlur={formik.handleBlur}
                          // style={{ padding: "2px", width: "50%" }}
                          id="ColourCode"
                          name="ColourCode"
                          // label="Category Name"
                          type="color"
                          variant="outlined"
                          className="input-div"
                          onChange={formik.handleChange}
                          value={formik.values?.ColourCode}
                          placeholder="Color"
                          disabled={!isAdmin}
                        />
                      </Grid>
                      {formik.touched.ColourCode && formik.errors.ColourCode ? (
                        <div style={{ color: "#D71E28" }}>
                          {formik.errors.ColourCode}
                        </div>
                      ) : null}
                      <br />
                    </Grid>

                    <Grid container spacing={3} style={{ margin: "20px 0px" }}>
                      <Grid item md={4} xs={12} sm={6}>
                        <FormGroup>
                          <InputLabel className="card-input-label">
                            Logo 1{" "}
                            <span className="helperText ">
                              *Note: Required logo size is 40px X 40px.
                            </span>
                          </InputLabel>
                          <FormControl style={{ width: "max-content" }}>
                            <input
                              type="file"
                              name="HeaderLogo1"
                              id={1}
                              accept="image/png, image/jpeg, image/jpg, image/webp"
                              className="hide_file"
                              disabled={!isAdmin}
                              onChange={(event) => {
                                onFileUpload(event);
                                // formik.setFieldValue(
                                //   "ProfileImage",
                                //   event.target.files[0],
                                // );
                                // setLogos((prev) => [...prev]);
                                // setSelectedFile(event.target.files[0]);
                              }}
                            />
                          </FormControl>
                        </FormGroup>
                        <div
                          style={{ margin: "10px 0px" }}
                          hidden={!files[1] && !formik.values?.HeaderLogo1}
                        >
                          <img
                            src={
                              files[1]?.file_url == undefined
                                ? img_url + formik.values?.HeaderLogo1
                                : files[1]?.file_url
                            }
                            height={150}
                            width={200}
                          />
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12} sm={6}>
                        <FormGroup>
                          <InputLabel className="card-input-label">
                            Logo 2{" "}
                            <span className="helperText ">
                              *Note: Required logo size is 40px X 40px.
                            </span>
                          </InputLabel>
                          <FormControl style={{ width: "max-content" }}>
                            <input
                              type="file"
                              name="HeaderLogo2"
                              id={2}
                              accept="image/png, image/jpeg, image/jpg, image/webp"
                              className="hide_file"
                              disabled={!isAdmin}
                              onChange={(event) => {
                                onFileUpload(event);
                                // formik.setFieldValue(
                                //   "ProfileImage",
                                //   event.target.files[0],
                                // );
                                // setSelectedFile(event.target.files[0]);
                              }}
                            />
                          </FormControl>
                        </FormGroup>
                        <div
                          style={{ margin: "10px 0px" }}
                          hidden={!files[2] && !formik.values?.HeaderLogo2}
                        >
                          <img
                            // src={files[2]?.file_url}
                            height={150}
                            width={200}
                            src={
                              files[2]?.file_url == undefined
                                ? img_url + formik.values?.HeaderLogo2
                                : files[2]?.file_url
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormGroup>
                          <InputLabel className="card-input-label">
                            Logo 3{" "}
                            <span className="helperText ">
                              *Note: Required logo size is 40px X 40px.
                            </span>
                          </InputLabel>
                          <FormControl style={{ width: "max-content" }}>
                            <input
                              type="file"
                              name="HeaderLogo3"
                              id={3}
                              accept="image/png, image/jpeg, image/jpg, image/webp"
                              className="hide_file"
                              disabled={!isAdmin}
                              onChange={(event) => {
                                onFileUpload(event);
                                // formik.setFieldValue(
                                //   "ProfileImage",
                                //   event.target.files[0],
                                // );
                                // setSelectedFile(event.target.files[0]);
                              }}
                            />
                          </FormControl>
                        </FormGroup>
                        <div
                          style={{ margin: "10px 0px" }}
                          hidden={!files[3] && !formik.values?.HeaderLogo3}
                        >
                          <img
                            // src={files[3]?.file_url}
                            height={150}
                            width={200}
                            src={
                              files[3]?.file_url == undefined
                                ? img_url + formik.values?.HeaderLogo3
                                : files[3]?.file_url
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="row" style={{ marginTop: "20px" }}>
                    <div className="card-footer">
                      <Box className="submit-cancel-box">
                        <Link
                          to={
                            role == "Admin"
                              ? "/admin/app/dashboard"
                              : "/organisation/app/dashboard"
                          }
                        >
                          <Button
                            size="large"
                            variant="contained"
                            className="cat-subCancel-btn"
                            type="button"
                          >
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          size="large"
                          variant="contained"
                          className="cat-sub-btn"
                          type="submit"
                          value={button}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "blue",
                              }}
                            />
                          ) : (
                            button
                          )}
                        </Button>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
