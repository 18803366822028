import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { NotificationSchema } from "../../utils/Schema";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// components
import PageTitle from "../../components/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.css";
import { AddNotificationData } from "../../store/Reducers/NotificationReducer";
import { GetOrganization } from "../../store/Reducers/OrganizationReducer";

export default function AddNotification() {
  let heading = "Notification";
  let button = "Submit";
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { getorganization } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );

  const formik = useFormik({
    initialValues: {
      notificationTitle: "",
      notificationMessage: "",
      organisationId: "",
    },
    validationSchema: NotificationSchema,
    onSubmit: async (value) => {
      setLoading(true);
      dispatch(AddNotificationData(value)).then((response) => {
        if (response?.payload?.code === 200) {
          history.goBack();

          toast.success(response?.payload?.message);
        } else {
          toast.error(response?.payload?.message);
        }
        setLoading(false);
      });
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(GetOrganization());
  }, [dispatch]);

  return (
    <>
      <PageTitle title={heading} />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4} className="content-grid">
            <Grid item xs={12} md={6}>
              <Box className="Card_style">
                <div className="card-header">
                  <h3 className="card-title">Add Notification</h3>
                </div>
                <br />
                <form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <InputLabel className="card-input-label">Title</InputLabel>
                    <TextField
                      onBlur={formik.handleBlur}
                      id="notificationTitle"
                      name="notificationTitle"
                      variant="outlined"
                      value={formik.values.notificationTitle}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                  {formik.touched.notificationTitle &&
                  formik.errors.notificationTitle ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.notificationTitle}
                    </div>
                  ) : null}
                  <br />

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Message
                    </InputLabel>
                    <TextField
                      onBlur={formik.handleBlur}
                      id="notificationMessage"
                      name="notificationMessage"
                      variant="outlined"
                      value={formik.values.notificationMessage}
                      onChange={formik.handleChange}
                      type="textarea"
                      multiline
                      minRows={4}
                    />
                  </FormGroup>
                  {formik.touched.notificationMessage &&
                  formik.errors.notificationMessage ? (
                    <div style={{ color: "#D71E28" }}>
                      {formik.errors.notificationMessage}
                    </div>
                  ) : null}
                  <br />

                  <FormGroup>
                    <InputLabel className="card-input-label">
                      Organisation
                    </InputLabel>
                    <FormControl variant="outlined">
                      <Select
                        id="organisationId"
                        name="organisationId"
                        placeholder="Category"
                        value={formik.values.organisationId}
                        onChange={formik.handleChange}
                        displayEmpty
                      >
                        <MenuItem value="">Select From Below</MenuItem>
                        {getorganization &&
                          getorganization?.map((value) => {
                            return (
                              <MenuItem value={value._id}>
                                {value.Name.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() +
                                      s.substring(1),
                                  )
                                  .join(" ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <br />

                  <div className="row ">
                    <div className="card-footer">
                      <Box className="submit-cancel-box">
                        <Button
                          size="large"
                          variant="contained"
                          className="cat-subCancel-btn"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          className="cat-sub-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "blue",
                              }}
                            />
                          ) : (
                            <>Submit</>
                          )}
                        </Button>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
