import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    alignItems: "center",
    padding: "0px 24px",
    flexWrap: "wrap",
  },
  typo: {
    // color: theme.palette.text.hint,
    color: "white",
    background: "#000078",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  emptyDiv: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
