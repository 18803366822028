import React, { useEffect, useState } from "react";
import {
  Button,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  FormGroup,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
  Box,
  RadioGroup,
  Radio,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import { CloudDownload, Visibility } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
  GetOrganization,
  setEditOrganizationdata,
  EditOrganization,
  EditManualMail,
  DeleteOrganization,
} from "../../store/Reducers/OrganizationReducer";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";
import { styled } from "@material-ui/styles";
import { useFormik } from "formik";
import { AssignData } from "../../store/Reducers/OrganizationReducer";
import { AssignSchema, OrganisationSchema } from "../../utils/Schema";
import { JSONToFormData, isEmpty } from "../../utils/Functions";
import { AdminBulkUpload } from "../../store/Reducers/OrganizationReducer";

import fileToDownload from "../../assets/csv/sample.csv";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import LoaderJson from "../../assets/loading.json";
import Lottie from "react-lottie";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Organisation(props) {
  const dispatch = useDispatch();
  const [assignDetail, setAssignDetails] = useState("");
  const { getorganization } = useSelector(
    ({ OrganizationSlice }) => OrganizationSlice,
  );
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState();

  const formik = useFormik({
    initialValues: {
      NoOfLicence: "",
      Price: "",
      isUnique: "false",
    },
    validationSchema: AssignSchema,
    onSubmit: async (value) => {
      setLoading(true);
      let UpdatedData = {
        organisationId: assignDetail?._id,
        NoOfLicence: value.NoOfLicence,
        Price: value.Price,
        isUnique: value.isUnique == "true" ? true : false,
      };

      dispatch(AssignData(UpdatedData)).then((response) => {
        if (response?.payload?.code === 200) {
          let data = new FormData();
          data.append("assignId", response.payload.data._id);
          data.append("_id", assignDetail._id);
          data.append("NoOfLicence", assignDetail.NoOfLicence);

          // let newData = {
          //   ...assignDetail,
          //   assignId: response.payload.data._id,
          // };

          dispatch(EditOrganization(data)).then((response) => {
            if (response?.payload?.code === 200) {
              history.push("organisation");
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
            setLoading(false);
          });
        } else {
          toast.error(response?.payload?.message);
        }
      });

      // }
    },
  });

  const getEditData = (item) => {
    history.push({
      pathname: "organisation/editorganisation",
      state: {
        data: item,
      },
    });
    dispatch(setEditOrganizationdata(item));
  };

  const openViewScreen = (item) => {
    // history.push("organisationview");
    dispatch(setEditOrganizationdata(item));
    history.push({
      pathname: "organisation/organisationview",
      state: {
        orgData: item,
      },
    });
  };

  useEffect(() => {
    setLoading1(true);
    dispatch(GetOrganization()).then((res) => setLoading1(false));
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);
  const [openImport, setOpenImport] = React.useState(false);
  const [openExport, setOpenExport] = React.useState(false);

  const handleClickOpen = (item) => {
    setOpen(true);
    setAssignDetails(item);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleImportClickOpen = (item) => {
    setOpenImport(true);
  };
  const handleImportClose = () => {
    setOpenImport(false);
  };

  const handleExportOpen = (item) => {
    setOpenExport(true);
  };
  const handleExportClose = () => {
    setOpenExport(false);
  };

  let newData = getorganization;

  // let newData = getorganization.filter((data) => {
  //   return data.Role != "Admin";
  // });

  useEffect(() => {
    if (assignDetail) {
      formik.setFieldValue("Name", assignDetail?.Name);
    }
  }, [assignDetail]);

  const CustomToggle = ({ tableMeta, value }) => {
    const rowData = newData.find((x) => x._id === tableMeta.rowData[0]);
    // const rowData = newData[tableMeta.rowIndex];
    const isSendMail = rowData.IsSendMail ?? false;
    // console.log(isSendMail);

    const handleChange = (event) => {
      // let data = {
      //   ...rowData,
      //   Status: event.target.checked,
      // };
      let data = new FormData();
      data.append("_id", rowData._id);
      data.append("Status", event.target.checked);
      data.append("isStatusChanged", true);

      if (isSendMail) {
        dispatch(EditOrganization(data)).then((response) => {
          if (response?.payload?.code === 200) {
            dispatch(GetOrganization());
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
        });
      } else {
        dispatch(EditManualMail(data)).then((response) => {
          if (response?.payload?.code === 200) {
            toast.success(response?.payload?.message);
          } else {
            toast.error(response?.payload?.message);
          }
        });
      }
    };

    const IOSSwitch = styled((props) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        onChange={handleChange}
        {...props}
      />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    }));

    return (
      <>
        <FormControlLabel
          control={<IOSSwitch defaultChecked={value} sx={{ m: 1 }} />}
        />
      </>
    );
  };

  // const CustomToolbar = () => {
  //   return (
  //     <Toolbar>
  //       <Button
  //         variant="contained"
  //         color="primary"
  //         onClick={() => console.log("Clicked!")}
  //       >
  //         Custom Button
  //       </Button>
  //     </Toolbar>
  //   );
  // };

  const [importData, setImportData] = useState();

  const uploadData = () => {
    // console.log(importData);
    let data = new FormData();
    data.append("csv", importData);

    dispatch(AdminBulkUpload(data)).then((response) => {
      if (response?.payload?.code === 200) {
        toast.success(response?.payload?.message);
      } else {
        toast.error(response?.payload?.message);
      }
    });
    handleImportClose();
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = fileToDownload;
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const TableAction = ({ tableMeta }) => {
    const item = getorganization.find((x) => x._id === tableMeta.rowData[0]);
    const itemid = tableMeta.rowData[0];

    const [dialog, toggleDialog] = useState(false);

    const openDialog = () => {
      toggleDialog(true);
    };

    const closeDialog = () => {
      toggleDialog(false);
    };

    const handleDelete = (data) => {
      dispatch(DeleteOrganization(data)).then((res) => {
        if (res.payload.code === 200) {
          dispatch(GetOrganization());
          toast.error(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
        closeDialog();
      });
    };

    return (
      <>
        <div className="edit-delete-btn">
          <EditIcon
            className="action-icons editBtn"
            style={{ marginRight: "10px" }}
            onClick={() => getEditData(item)}
          />
          <Visibility
            style={{ marginRight: "10px" }}
            className="action-icons viewBtn"
            onClick={() => openViewScreen(item)}
          />
          <DeleteIcon className="action-icons deleteBtn" onClick={openDialog} />
          <DeleteDialog
            open={dialog}
            handleClose={closeDialog}
            yesClick={() => handleDelete({ id: item._id })}
            text={"Are you sure you want to delete this organisation?"}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <PageTitle title="Organisation" />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div className="header-button-group">
            <Button
              className="toolbar-btn"
              variant="contained"
              color="primary"
              onClick={downloadFile}
            >
              <div style={{ height: "22px" }}>
                <CloudDownload />
              </div>
              <span style={{ margin: "0px 0px 0px 5px" }}>Sample File</span>
            </Button>
            <Link to="organisation/addorganisation" className="addlink">
              <Button variant="contained" size="medium" className="newBtn">
                <AddIcon />
                New
              </Button>
            </Link>
          </div>
          {loading1 ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="Organisation List"
              // button={<CustomToolbar />}
              data={newData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    sort: false,
                    display: false,
                    download: false,
                  },
                },

                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => (
                      <span>{tableMeta.rowIndex + 1}</span>
                    ),
                  },
                },
                {
                  name: "Name",
                  label: "Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = newData[tableMeta.rowIndex];
                      const name = tname?.Name;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "PhoneNumber",
                  label: "Phone Number",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = newData[tableMeta.rowIndex];
                      const name = tname?.PhoneNumber;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Email",
                  label: "Email",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = newData[tableMeta.rowIndex];
                      const name = tname?.Email;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          {/* <Tooltip title={value}> */}
                          <span style={{ wordBreak: "break-word" }}>
                            {/* {value.length > 20
                              ? value.substring(0, 20) + "..."
                              : value} */}
                            {value}
                          </span>
                          {/* </Tooltip> */}
                        </>
                      );
                    },
                  },
                },
                {
                  name: "NoOfLicence",
                  label: "No.of Licenses",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      // const tname = getorganization[tableMeta.rowIndex];
                      // const name = tname?.NoOfLicence;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>
                            {isEmpty(value)
                              ? "Still Not Assigned License"
                              : value}
                          </span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Status",
                  label: "Status",
                  options: {
                    download: false,
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => (
                      <CustomToggle value={value} tableMeta={tableMeta} />
                    ),
                  },
                },
                {
                  name: "Licenses",
                  options: {
                    sort: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const item = newData.find(
                        (x) => x._id === tableMeta.rowData[0],
                      );
                      // const item = newData[tableMeta.rowIndex];
                      // const itemid = getLanguageData[tableMeta.rowIndex]._id;
                      return (
                        <>
                          <div>
                            <Button
                              className="assignBtn"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleClickOpen(item)}
                            >
                              Assign
                            </Button>
                          </div>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "City",
                  label: "Location",
                  options: {
                    download: false,
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const item = newData.find(
                        (x) => x._id === tableMeta.rowData[0],
                      );
                      // const item = newData[tableMeta.rowIndex];
                      let name;
                      if (item.State && item.City) {
                        name = item?.City + "," + item?.State;
                      }
                      return <span>{value}</span>;
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    sort: false,
                    download: false,
                    // customBodyRender: (value, tableMeta, updateValue) => {
                    //   const item = newData.find(
                    //     (x) => x._id === tableMeta.rowData[0],
                    //   );
                    //   // const item = newData[tableMeta.rowIndex];
                    //   // const itemid = getLanguageData[tableMeta.rowIndex]._id;
                    //   return (
                    //     <>
                    //       <div className="edit-delete-btn">
                    //         <EditIcon
                    //           className="action-icons editBtn"
                    //           style={{ marginRight: "10px" }}
                    //           onClick={() => getEditData(item)}
                    //         />
                    //         <Visibility
                    //           style={{ marginRight: "10px" }}
                    //           className="action-icons viewBtn"
                    //           onClick={() => openViewScreen(item)}
                    //         />
                    //         <DeleteIcon
                    //           className="action-icons deleteBtn"
                    //           onClick={openDialog}
                    //         />
                    //         <DeleteDialog
                    //           open={dialog}
                    //           handleClose={closeDialog}
                    //           yesClick={() => handleDelete({ id: item.id })}
                    //           text={
                    //             "Are you sure you want to delete this organisation?"
                    //           }
                    //         />
                    //       </div>
                    //     </>
                    //   );
                    // },
                    customBodyRender: (value, tableMeta, updateValue) => (
                      <TableAction tableMeta={tableMeta} />
                    ),
                  },
                },
                {
                  name: "ContactPersonName",
                  label: "ContactPersonName.",
                  options: {
                    sort: false,
                    display: false,
                  },
                },
                {
                  name: "City",
                  label: "City.",
                  options: {
                    sort: false,
                    display: false,
                  },
                },
                {
                  name: "State",
                  label: "State.",
                  options: {
                    sort: false,
                    display: false,
                  },
                },
                {
                  name: "createdAt",
                  label: "Date.",

                  options: {
                    sort: false,
                    display: false,
                    download: true,
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: true,
                viewColumns: false,
                customToolbar: () => (
                  <>
                    <Button
                      className="toolbar-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => handleImportClickOpen()}
                    >
                      <span>Import Data</span>
                    </Button>
                  </>
                ),
              }}
            />
          )}
        </Grid>

        <BootstrapDialog
          className="bulkupload-dialog"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          style={{ height: 300, width: 300 }}
        >
          <BootstrapDialogTitle id="customized-dialog-title">
            Please fill up the form for licenses assigned.
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <br />
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <InputLabel className="card-input-label">Name</InputLabel>
                <TextField
                  id="Name"
                  name="Name"
                  variant="outlined"
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
              <br />
              <FormGroup>
                <InputLabel className="card-input-label">
                  No of Licenses
                </InputLabel>
                <TextField
                  onBlur={formik.handleBlur}
                  id="licenceNo"
                  name="NoOfLicence"
                  variant="outlined"
                  type="number"
                  value={formik.values.NoOfLicence}
                  onChange={formik.handleChange}
                />
                {formik.touched.NoOfLicence && formik.errors.NoOfLicence ? (
                  <div style={{ color: "#D71E28" }}>
                    {formik.errors.NoOfLicence}
                  </div>
                ) : null}
              </FormGroup>
              <br />
              <FormGroup>
                <InputLabel className="card-input-label">Price</InputLabel>
                <TextField
                  onBlur={formik.handleBlur}
                  id="Price"
                  name="Price"
                  type="number"
                  variant="outlined"
                  value={formik.values.Price}
                  onChange={formik.handleChange}
                />
                {formik.touched.Price && formik.errors.Price ? (
                  <div style={{ color: "#D71E28" }}>{formik.errors.Price}</div>
                ) : null}
              </FormGroup>
              <br />

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="isUnique"
                value={formik.values.isUnique}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                <FormControlLabel
                  value={"false"}
                  control={<Radio color="primary" />}
                  label="Regular"
                />
                <FormControlLabel
                  value={"true"}
                  control={<Radio color="primary" />}
                  label="Unique Id"
                />
              </RadioGroup>

              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {/* <div className="card-footer"> */}
                <Box className="submit-cancel-box" style={{ margin: 0 }}>
                  <Button
                    size="large"
                    variant="contained"
                    className="cat-subCancel-btn"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    className="cat-sub-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        color="primary"
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "blue",
                        }}
                      />
                    ) : (
                      <>Submit</>
                    )}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleImportClose}
          aria-labelledby="customized-dialog-title"
          open={openImport}
        >
          <BootstrapDialogTitle id="customized-dialog-title">
            Please browse file for import
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <br />
            <form className="import-modal">
              <FormGroup>
                <InputLabel className="card-input-label">
                  Choose Browser file
                </InputLabel>
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="file/*"
                  className="hide_file"
                  onChange={(event) => {
                    // console.log(event.target.files[0]);
                    setImportData(event.target.files[0]);
                  }}
                />
              </FormGroup>
              <br />

              <DialogActions>
                <Button
                  className="btn-info"
                  onClick={() => uploadData()}
                  style={{ padding: "8px 25px" }}
                >
                  Submit
                </Button>
                <Button
                  className="btn-secondary"
                  autoFocus
                  style={{ padding: "8px 25px" }}
                  onClick={handleImportClose}
                >
                  Close
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleExportClose}
          aria-labelledby="customized-dialog-title"
          open={openExport}
        >
          <BootstrapDialogTitle id="customized-dialog-title">
            Filter the date for export
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div className="export-container">
              <DateRangePicker />
              <DialogActions>
                <Button className="btn-info" style={{ padding: "8px 25px" }}>
                  Submit
                </Button>
                <Button
                  className="btn-secondary"
                  autoFocus
                  style={{ padding: "8px 25px" }}
                  onClick={handleExportClose}
                >
                  Close
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
      <br />
      <br />
    </>
  );
}
