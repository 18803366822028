import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";

import useStyles from "./styles";

const DeleteDialog = ({ open, handleClose, yesClick, text, isDisabled }) => {
  var classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialogContainer}>
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>

        <DialogActions className={classes.buttonGroup}>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.noBtn}
          >
            No
          </Button>
          <Button
            onClick={yesClick}
            variant="contained"
            className={classes.yesBtn}
            disabled={isDisabled}
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
