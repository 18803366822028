import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import PageTitle from "../../components/PageTitle";
import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import { Visibility } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { GetUser, setEditUserdata } from "../../store/Reducers/UserReducer";
import { ToastContainer, toast } from "react-toastify";
import { adminProfileData } from "../../store/Reducers/ProfileReducer";
import { useState } from "react";
import { OrgDashboard } from "../../store/Reducers/DashboardReducer";
import { styled } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

import {
  Button,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  FormGroup,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
  Toolbar,
} from "@material-ui/core";
import { AdminBulkUpload } from "../../store/Reducers/OrganizationReducer";
import Lottie from "react-lottie";
import LoaderJson from "../../assets/loading.json";

// const datatableData = [
//   ["#1", "	10", "ASHIL", "9825486956", "ashilshah19@gnu.in", "Active", "Assign"],
//   ["#2", "	20", "Test123", "9638601537", "test19@gmail.com", "Active", "Assign"],
// ];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function User(props) {
  const dispatch = useDispatch();
  const { getUserData } = useSelector(({ UserSlice }) => UserSlice);
  const { userData } = useSelector(({ AuthSlice }) => AuthSlice);

  const { orgDashboardData } = useSelector(
    ({ DashboardSlice }) => DashboardSlice,
  );

  const history = useHistory();

  const getEditData = (item) => {
    dispatch(setEditUserdata(item));
    history.push({
      pathname: "users/edituser",
      state: {
        userData: item,
      },
    });
  };

  const viewUser = (item) => {
    dispatch(setEditUserdata(item));
    history.push({
      pathname: "users/userview",
      state: {
        userData: item,
      },
    });
  };

  const [isdisable, setDisable] = useState(true);

  const [openImport, setOpenImport] = React.useState(false);
  const handleImportClickOpen = (item) => {
    setOpenImport(true);
  };
  const handleImportClose = () => {
    setOpenImport(false);
  };
  const [loading, toggleLoading] = useState(true);
  useEffect(() => {
    toggleLoading(true);
    dispatch(
      GetUser({
        organisationId: userData._id,
      }),
    ).then((res) => toggleLoading(false));
    dispatch(
      OrgDashboard({
        organisationId: userData._id,
      }),
    ).then((res) => toggleLoading(false));
  }, [dispatch]);

  const [importData, setImportData] = useState();

  const uploadData = () => {
    let data = new FormData();
    data.append("csv", importData);
    data.append("organizationId", userData._id);

    dispatch(AdminBulkUpload(data)).then((response) => {
      if (response?.payload?.code === 200) {
        toast.success(response?.payload?.message);
      } else {
        toast.error(response?.payload?.message);
      }
    });
    handleImportClose();
  };

  useEffect(() => {
    if (orgDashboardData.ReamingLicenses > 0) {
      setDisable((prev) => false);
    } else {
      setDisable((prev) => true);
    }
  }, [orgDashboardData]);

  return (
    <>
      <PageTitle title="Users" />
      <Grid container spacing={4} className="content-grid">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <Button
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => handleImportClickOpen()}
            >
              Import Data
            </Button> */}
            {/* <Link to="adduser" className="addlink"> */}

            <Button
              className={isdisable ? "" : "newBtn"}
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              variant="contained"
              size="medium"
              disabled={isdisable}
              onClick={() => {
                history.push("users/adduser");
              }}
            >
              <AddIcon />
              Assign License
            </Button>
            {/* </Link> */}
            {/* <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              color="primary"
            >
              Export Data
            </Button> */}
          </div>
          {loading ? (
            <div className="loader-table">
              <Lottie
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: LoaderJson,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <MUIDataTable
              title="User List"
              data={getUserData}
              columns={[
                {
                  name: "_id",
                  label: "Id.",

                  options: {
                    download: false,
                    sort: true,
                    display: false,
                  },
                },
                {
                  name: "SNo",
                  label: "Sr. No.",
                  options: {
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                      return <div>{tableMeta?.rowIndex + 1}</div>;
                    },
                  },
                },
                {
                  name: "Name",
                  label: "Name",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getUserData[tableMeta.rowIndex];
                      const name = tname?.Name;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "PhoneNumber",
                  label: "Phone Number",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getUserData[tableMeta.rowIndex];
                      const name = tname?.PhoneNumber;
                      // ?.toLowerCase()
                      // .split(" ")
                      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // .join(" ");
                      return (
                        <>
                          <span>{value ?? "-"}</span>
                        </>
                      );
                    },
                  },
                },
                // {
                //   name: "Email",
                //   label: "Email",
                //   options: {
                //     filter: true,
                //     sort: true,
                //     sortFilterList: true,
                //     customBodyRender: (value, tableMeta, update) => {
                //       const tname = getUserData[tableMeta.rowIndex];
                //       const name = tname?.Email;
                //       // ?.toLowerCase()
                //       // .split(" ")
                //       // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                //       // .join(" ");
                //       return (
                //         <>
                //           <span>{value}</span>
                //         </>
                //       );
                //     },
                //   },
                // },
                {
                  name: "Email",
                  label: "Email",
                  options: {
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      const tname = getUserData[tableMeta.rowIndex];
                      const name = tname?.Email;
                      return (
                        <>
                          <span style={{ wordBreak: "break-word" }}>
                            {value ?? "-"}
                          </span>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "Status",
                  label: "Status",
                  options: {
                    download: false,
                    filter: true,
                    sort: true,
                    sortFilterList: true,
                    customBodyRender: (value, tableMeta, update) => {
                      // const tname = getorganization[tableMeta.rowIndex];
                      // const name = tname?.Status;
                      // // ?.toLowerCase()
                      // // .split(" ")
                      // // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      // // .join(" ");

                      return (
                        <>
                          <span style={{ color: value ? "green" : "red" }}>
                            {value === true
                              ? "Active"
                              : value === false
                              ? "InActive"
                              : "-"}
                          </span>
                          {/* <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={
                                (console.log("checked:", status === "visible"),
                                status === "visible")
                              }
                              sx={{ m: 1 }}
                            />
                          }
                        /> */}
                        </>
                      );
                    },
                  },
                },
                {
                  name: "uniqueId",
                  label: "Unique Id",
                  options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                      return value ?? <>-</>;
                    },
                  },
                },
                {
                  name: "Action",

                  options: {
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const item = getUserData.find(
                        (x) => x._id === tableMeta.rowData[0],
                      );
                      // const item = getUserData[tableMeta.rowIndex];
                      // const itemid = getLanguageData[tableMeta.rowIndex]._id;
                      return (
                        <>
                          <div className="edit-delete-btn">
                            <EditIcon
                              className="action-icons editBtn"
                              style={{ marginRight: "10px" }}
                              onClick={() => getEditData(item)}
                            />
                            <Visibility
                              style={{ marginRight: "10px" }}
                              className="action-icons viewBtn"
                              onClick={() => viewUser(item)}
                            />
                          </div>
                        </>
                      );
                    },
                  },
                },
                {
                  name: "createdAt",
                  label: "Date.",

                  options: {
                    sort: false,
                    display: false,
                    download: true,
                  },
                },
              ]}
              options={{
                filterType: "",
                selectableRows: "none",
                searchOpen: false,
                search: true,
                filter: false,
                print: false,
                download: true,
                viewColumns: false,
                // sort: true,
                customToolbar: () => (
                  <>
                    <Button
                      className="toolbar-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => handleImportClickOpen()}
                    >
                      {/* <div style={{ height: "22px" }}>
                      <CloudUpload />
                    </div> */}
                      <span>Import Data</span>
                    </Button>
                  </>
                ),
              }}
            />
          )}
        </Grid>
      </Grid>

      {/* <BootstrapDialog
        onClose={handleImportClose}
        aria-labelledby="customized-dialog-title"
        open={openImport}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // onClose={handleClose}
        >
          Please browse file for import
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <br />
          <form>
            <FormGroup>
              <InputLabel className="card-input-label">
                Choose Browser file
              </InputLabel>
              <input
                type="file"
                name="file"
                id="file"
                accept="file/*"
                className="hide_file"
                // value={formik.values.categoryLogo}
                onChange={(event) => {
                  // formik.setFieldValue("file", event.target.files[0]);
                  (event.target.files[0]);
                  setImportData(event.target.files[0]);
                }}
              />
            </FormGroup>
            <br />

            <DialogActions style={{ padding: "20px" }}>
              <Button
                className="btn-info"
                // type="submit"
                onClick={() => uploadData()}
              >
                Submit
              </Button>
              <Button
                className="btn-secondary"
                autoFocus
                onClick={handleImportClose}
              >
                Close
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BootstrapDialog> */}

      <BootstrapDialog
        onClose={handleImportClose}
        aria-labelledby="customized-dialog-title"
        open={openImport}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // onClose={handleClose}
        >
          Please browse file for import
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <br />
          <form className="import-modal">
            <FormGroup
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "center",
            // }}
            >
              <InputLabel className="card-input-label">
                Choose Browser file
              </InputLabel>
              <input
                type="file"
                name="file"
                id="file"
                accept="file/*"
                className="hide_file"
                // value={formik.values.categoryLogo}
                onChange={(event) => {
                  // formik.setFieldValue("file", event.target.files[0]);
                  setImportData(event.target.files[0]);
                }}
              />
            </FormGroup>
            <br />

            <DialogActions>
              <Button
                className="btn-info"
                // type="submit"
                onClick={() => uploadData()}
                style={{ padding: "8px 25px" }}
              >
                Submit
              </Button>
              <Button
                className="btn-secondary"
                autoFocus
                style={{ padding: "8px 25px" }}
                onClick={handleImportClose}
              >
                Close
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
